import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./cartProduct.css";

import IncrementalButton from "../CustomInputs/IncrementalButton";
import AddToCartButton from "../CustomInputs/AddToCartButton";
import useDidMountEffect from "../../utils/useDidMountEffect";
import Currency from "../Currency";

export default function CartProduct(props) {
  const { t } = useTranslation();

  const quantityRef = useRef();
  const addToCartRef = useRef();

  useDidMountEffect(() => {
    console.log("quantity", quantityRef.current.value);
  }, [quantityRef]);

  return (
    <div className="cart-product">
      <div className="cart-product-delivery">
        <span className="cart-product-deliveryItem cart-product-deliveryDate">
          {t("cart.section.products.deliveryDate")}:{" "}
          <b>{props.data.deliveryDate}</b>
        </span>
        <span className="cart-product-deliveryItem cart-product-seller">
          {t("cart.section.products.seller")}:{" "}
          <Link to="/">{props.data.seller.title}</Link>
        </span>
      </div>

      <div className="cart-product-innerWrapper">
        <div className="cart-product-image">
          <img src={props.data.img} width={100} />
        </div>
        <div className="cart-product-meta">
          <h3 className="cart-product-metaTitle">{props.data.title}</h3>
          <div className="cart-product-metaActions">
            <span className="cart-product-metaPrice">
              <Currency value={props.data.price} />
            </span>
            {props.data.quantity > 1 && (
              <small className="ms-2">
                {props.data.price} * {props.data.quantity} ={" "}
                <Currency value={props.data.price * props.data.quantity} />
              </small>
            )}
            <IncrementalButton
              productId={props.data.id}
              ref={quantityRef}
              defaultValue={props.data.quantity}
              inCart={props.inCart}
              className="cart-product-metaQuantity ms-4"
            />
            <AddToCartButton
              ref={addToCartRef}
              inCart={props.inCart}
              setData={() => props.data}
              className="ms-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
