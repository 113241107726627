import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./asyncDataList.css";
import useDidMountEffect from "../../utils/useDidMountEffect";

export const AsyncRadioListItem = function (props) {
  return (
    <div className="d-flex cart-section-listItem mb-2">
      <input
        type="radio"
        id={props.id}
        name={props.name}
        value={props.id}
        onClick={props.onClick}
        checked={props.checked}
      />
      <label htmlFor={props.id} className="d-block ms-2">
        {props.label}
      </label>
    </div>
  );
};

export default forwardRef(function AsyncRadioList(props, ref) {
  console.log("AsyncRadioList", props);
  const { t } = useTranslation();

  const [isChanging, setChanging] = useState(false);
  const [checkedItem, setCheckedItem] = useState({});

  const onClickHandler = (id) => {
    let checkedObject =
      props.data.length > 0 && id
        ? props.data.find((e) => e.id === id)
        : props.data[0];
    if (checkedObject) {
      setCheckedItem(checkedObject);
    }
  };

  useDidMountEffect(() => {
    onClickHandler(props.checkedId);
  }, [props.data]);

  return (
    <div className="cart-sectionWrapper mb-4">
      {props.data.length > 0 && (
        <h2 className="cart-section-titleSub">
          {props.titleIcon} {props.title}
        </h2>
      )}
      <div className="cart-section-item cart-section-itemAddress d-flex flex-row align-items-center">
        <div className="cart-section-itemLeft art-section-AsyncRadioListDetails w-100 p-3">
          {props.data.length > 0 && (
            <div className="cart-section-itemLeft-item">
              <h3>{checkedItem?.title}</h3>
              <div>{props.itemLabel(checkedItem)}</div>
            </div>
          )}

          {isChanging && props.data.length > 0 && (
            <div className="mt-3">
              {props.data.map((e) => (
                <AsyncRadioListItem
                  id={"radio_" + props.name + "_" + e.id}
                  name={"radio_" + props.name}
                  label={props.itemLabel(e)}
                  checked={e.id === checkedItem.id}
                  onClick={() => onClickHandler(e.id)}
                />
              ))}
            </div>
          )}

          {(isChanging || props.data.length === 0) && props.children}

          {props.data.length > 0 && (
            <input
              ref={ref}
              name={props.name}
              type="hidden"
              value={checkedItem.id}
            />
          )}
        </div>
        <div className="cart-section-itemRight cart-section-AsyncRadioListAction p-3">
          {!isChanging && props.data.length > 0 && (
            <button
              className="btn btn-primary rounded-pill px-4 py-0 d-flex align-items-center"
              onClick={() => setChanging(true)}
            >
              <i class="fa-solid fa-pen-to-square me-2"></i>
              {t("cart.section.button.change")}
            </button>
          )}
          {isChanging && props.data.length > 0 && (
            <button
              className="btn btn-primary rounded-pill px-4 py-0 d-flex align-items-center"
              onClick={() => setChanging(false)}
            >
              <i class="fa-solid fa-check me-2"></i>
              {t("cart.section.button.ok")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
});
