import React, { useCallback, useState } from "react";
import API from "../../utils/api";

import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";

import "./supplierStore.css";
import ProductItem from "../ProductItem";

import dummyProducts from "./dummyProducts.json";
import SeoLayer from "../SeoLayer/SeoLayer";

export default function SupplierStore(props) {
  const { t } = useTranslation();

  const [product, setProduct] = useState(false);

  const handlePurchaseOptionsButtonClick = useCallback((product) => {
    setProduct(product);
  }, []);

  function purchaseOptionsBody() {
    if (product === false) return;

    console.log("purchaseOptionsBody", product.wholesale);

    const wholesaleOptions = product.wholesale.title.map((d, i) => {
      return (
        <div className="border p-3">
          <h5>
            <input id={"wholesale" + i} type="radio" />
            <label htmlFor={"wholesale" + i}>{d}</label>
          </h5>
          <div>
            Satın alınacak bir ürün içeriği{" "}
            <b>{product.wholesale.quantity[i]} adet</b>, Toplam{" "}
            <b>
              {product.wholesale.price.wholesale.value[i] +
                product.wholesale.price.wholesale.currency[i]}
            </b>
            , bu seçenek ile en fazla <b>{product.wholesale.quantity[i]}</b>{" "}
            adet satın alınabilir, toplam stok adedi{" "}
            <b>{product.wholesale.stock[i]}</b> adettir, teslimat{" "}
            <b>{product.wholesale.packaging_type[i]}</b> ile yapılacaktır
          </div>
        </div>
      );
    });

    const retailOptions = product.retail.title.map((d, i) => {
      return (
        <div className="border p-3">
          <h5>
            <input id={"retail" + i} type="radio" />
            <label htmlFor={"retail" + i}>{d}</label>
          </h5>
          <div>
            Satın alınacak bir ürün içeriği{" "}
            <b>{product.retail.quantity[i]} adet</b>, Toplam{" "}
            <b>
              {product.retail.price.wholesale.value[i] +
                product.retail.price.wholesale.currency[i]}
            </b>
            , bu seçenek ile en fazla <b>{product.retail.quantity[i]}</b> adet
            satın alınabilir, toplam stok adedi <b>{product.retail.stock[i]}</b>{" "}
            adettir, teslimat <b>{product.retail.packaging_type[i]}</b> ile
            yapılacaktır
          </div>
        </div>
      );
    });

    return (
      <div className="purchaseOptions row">
        <h4 className="mb-4">{product.title}</h4>

        <div className="col-lg-12 mb-4">
          <h3 className="mb-3">Toptan seçenekleri</h3>
          {wholesaleOptions}
        </div>

        <div className="col-lg-12">
          <h4 className="mb-3">Perakende seçenekleri</h4>
          {retailOptions}
        </div>
      </div>
    );
  }

  const maintenance = () => {
    return <div
      className="container mt-3 d-flex justify-content-center align-items-center"
      style={{ minHeight: 500 }}
    >
      <p className="alert alert-info">{t("maintenance_message")}</p>
    </div>;
  };

  const renderData = () => {
    return (
      <SideBar>
        <div className="row">
          {dummyProducts.map((p, i) => (
            <ProductItem
              key={i}
              data={p}
              purchaseOptionsClick={() => handlePurchaseOptionsButtonClick(p)}
            />
          ))}
        </div>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="purchaseOptions"
          aria-labelledby="purchaseOptionsLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="purchaseOptionsLabel">
              Satın alma seçenekleri
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">{purchaseOptionsBody()}</div>
        </div>
      </SideBar>
    );
  };

  return <>
    <SeoLayer
      title={t("seo_layer.supplier_store.title")}
      description={t("seo_layer.supplier_store.description")}
      endpoint="supplier/store"
    />  
    {maintenance()}
  </>
}
