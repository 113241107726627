import React, { useState, useEffect } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import HeaderSearchBar from "../HeaderSearchBar";
import "./categories_sidebar.css";
import productData from "../Categories/productData.json";
import { filterByCategory } from "../../context/feature/Search/searchSlice";

export default function CategoriesSideBar({
  children,
  filterProducts,
  handlePriceFilter,
  priceFilter,
  filterLast30Days,
  filterLast90Days,
  filterLast180Days,
  filterLast360Days,
  filterLast7Days,
  filterProductsByStars,
  handlePriceRange,
  filterSpesificPriceRange,
  filterProductsByBrand,
  onSearch,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeFilter, setActiveFilter] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const initialCategoriesToShow = 10;

  useEffect(() => {
    const uniqueCategories = [
      ...new Set(productData.map((product) => product.category)),
    ];
    setCategories(uniqueCategories);
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateMinPrice = (e) => setMinPrice(e.target.value);
  const updateMaxPrice = (e) => setMaxPrice(e.target.value);

  const applyPriceFilter = () => {
    handlePriceRange(minPrice, maxPrice);
  };

  return (
    <main className="container-xl">
      <div className="mainWrapper row">
        <div className="leftSide p-0 col-lg-3 d-none d-lg-block">
          <div className="d-flex flex-row justify-content-end mb-5 pe-3 pt-3"></div>
          <div className="leftSide-menu">
            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.new_arrivals")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <div className="list-group price-filter">
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast30Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-calendar-days"></i>
                  Son 30 Gün
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast90Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-calendar-days"></i>
                  Son 90 Gün
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast180Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-solid fa-calendar-days"></i>
                  Son 6 Ay
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast360Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-calendar-days"></i>
                  Son 1 Yıl
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast7Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-cloud-sun"></i>
                  Yeni Gelenler
                </button>
              </div>
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.relevant_categories")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <div className="offset-3 col-6">
                <HeaderSearchBar onSearch={onSearch} />
              </div>
              {categories
                .slice(
                  0,
                  showAllCategories
                    ? categories.length
                    : initialCategoriesToShow
                )
                .map((category) => (
                  <button
                    key={category}
                    className="list-group-item list-group-item-action mt-2"
                    style={{ fontSize: "14px" }}
                    onClick={() => dispatch(filterByCategory(category))}
                  >
                    <i className="buyrun-icon bi bi-dot"></i>{" "}
                    {t(`categories.${category}`)}
                  </button>
                ))}
              {!showAllCategories &&
                categories.length > initialCategoriesToShow && (
                  <button
                    className="btn btn-link"
                    style={{ fontSize: "14px", color: "#007bff" }}
                    onClick={() => setShowAllCategories(true)}
                  >
                    {t("categories.show_all")}
                  </button>
                )}
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.Customer_Review")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <div className="mt-3 d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center form-check">
                  <div className="filter-section" style={{ width: "100%" }}>
                    {[4, 3, 2, 1].map((star) => (
                      <div key={star} style={{ marginBottom: "5px" }}>
                        <button
                          className="btn btn-outline-primary"
                          style={{
                            width: "100%",
                            border: "none",
                            fontSize: "14px",
                          }}
                          onClick={() => filterProductsByStars(star)}
                        >
                          {[...Array(star)].map((_, i) => (
                            <i
                              key={i}
                              className="bi bi-star-fill"
                              style={{ color: "gold", marginRight: "5px" }}
                            ></i>
                          ))}
                          ve Üzeri
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.Price_Range")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <div className="list-group-item d-flex justify-content-center">
                <div className="form-check">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <input
                          className="buyrun-form-control form-control"
                          type="number"
                          value={minPrice}
                          onChange={updateMinPrice}
                          placeholder={t("categories.Min_Price")}
                          name="min-price"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          className="buyrun-form-control form-control"
                          type="number"
                          value={maxPrice}
                          onChange={updateMaxPrice}
                          name="max-price"
                          placeholder={t("categories.Max_Price")}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                      <button
                        className="col-12 col-md-6 btn btn-primary w-100"
                        onClick={applyPriceFilter}
                      >
                        <i className="fa-duotone fa-arrow-up-wide-short"></i>
                        Filtrele
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="list-group price-filter">
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(0, 150)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>0 - 150
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(150, 400)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                150 - 400
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(400, 500)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                400 - 500
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(500, 1000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                500 - 1000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(1000, 4000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                1000 - 4000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(4000, 10000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                4000 - 10000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(10000, 50000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                10000 - 50000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(50000, 100000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                50000 - 100000
              </button>
            </div>
          </div>
        </div>

        <div className="mainContainer col border-end p-3 ps-5">{children}</div>
      </div>

      <Button
        variant="primary"
        className="d-lg-none"
        onClick={handleShow}
        style={{
          position: "fixed",
          top: "10px",
          left: "10px",
          zIndex: "1030",
        }}
      >
        <i className="bi bi-filter"></i> Filtrele
      </Button>

      <Offcanvas show={show} onHide={handleClose} className="d-lg-none">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            style={{ color: "rgba(255, 80, 0, 1)", fontWeight: "bold" }}
          >
            Filtrele
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="leftSide-menu">
            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.new_arrivals")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <div className="list-group price-filter">
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast30Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-calendar-days"></i>
                  Son 30 Gün
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast90Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-calendar-days"></i>
                  Son 90 Gün
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast180Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-solid fa-calendar-days"></i>
                  Son 6 Ay
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast360Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-calendar-days"></i>
                  Son 1 Yıl
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={filterLast7Days}
                  className="list-group-item list-group-item-action"
                >
                  <i className="fa-duotone fa-cloud-sun"></i>
                  Yeni Gelenler
                </button>
              </div>
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.relevant_categories")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <HeaderSearchBar onSearch={onSearch} />
              {categories
                .slice(
                  0,
                  showAllCategories
                    ? categories.length
                    : initialCategoriesToShow
                )
                .map((category) => (
                  <button
                    key={category}
                    className="list-group-item list-group-item-action mt-2"
                    style={{ fontSize: "14px" }}
                    onClick={() => dispatch(filterByCategory(category))}
                  >
                    <i className="buyrun-icon bi bi-dot"></i>{" "}
                    {t(`categories.${category}`)}
                  </button>
                ))}
              {!showAllCategories &&
                categories.length > initialCategoriesToShow && (
                  <button
                    className="btn btn-link"
                    style={{ fontSize: "14px", color: "#007bff" }}
                    onClick={() => setShowAllCategories(true)}
                  >
                    {t("categories.show_all")}
                  </button>
                )}
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.Customer_Review")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <div className="mt-3 d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center form-check">
                  <div className="filter-section" style={{ width: "100%" }}>
                    {[4, 3, 2, 1].map((star) => (
                      <div key={star} style={{ marginBottom: "5px" }}>
                        <button
                          className="btn btn-outline-primary"
                          style={{
                            width: "100%",
                            border: "none",
                            fontSize: "14px",
                          }}
                          onClick={() => filterProductsByStars(star)}
                        >
                          {[...Array(star)].map((_, i) => (
                            <i
                              key={i}
                              className="bi bi-star-fill"
                              style={{ color: "gold", marginRight: "5px" }}
                            ></i>
                          ))}
                          ve Üzeri
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("categories.Price_Range")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <div className="list-group-item d-flex justify-content-center">
                <div className="form-check">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <input
                          className="buyrun-form-control form-control"
                          type="number"
                          value={minPrice}
                          onChange={updateMinPrice}
                          placeholder={t("categories.Min_Price")}
                          name="min-price"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          className="buyrun-form-control form-control"
                          type="number"
                          value={maxPrice}
                          onChange={updateMaxPrice}
                          name="max-price"
                          placeholder={t("categories.Max_Price")}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                      <button
                        className="col-12 col-md-6 btn btn-primary w-100"
                        onClick={applyPriceFilter}
                      >
                        <i className="fa-duotone fa-arrow-up-wide-short"></i>
                        Filtrele
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="list-group price-filter">
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(0, 150)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>0 - 150
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(150, 400)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                150 - 400
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(400, 500)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                400 - 500
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(500, 1000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                500 - 1000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(1000, 4000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                1000 - 4000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(4000, 10000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                4000 - 10000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(10000, 50000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                10000 - 50000
              </button>
              <button
                className="btn custom-btn list-group-item list-group-item-action"
                style={{ border: "none", fontSize: "14px" }}
                onClick={() => filterSpesificPriceRange(50000, 100000)}
              >
                <i className="fa-duotone fa-money-bill-1"></i>
                50000 - 100000
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </main>
  );
}
