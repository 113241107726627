import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./profilebar.module.css";

import { signOut } from "../../context/feature/User/userSlice";
import API from "../../utils/api";
import { Dropdown } from "react-bootstrap";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

export function HeaderUserPanel() {
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.user.currentUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await API.post("/users/logout");
      dispatch(signOut());
      localStorage.removeItem("persist:root");
      navigate("/auth/sign-in");
    } catch (error) {
      console.error("Çıkış işlemi sırasında bir hata meydana geldi", error);
    }
  };

  const loggedUserMenuToggle = forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <i className="fa-duotone fa-chevron-down ms-2"></i>
    </span>
  ));

  function loggedUserMenu() {
    return (
      <Dropdown>
        <Dropdown.Toggle as={loggedUserMenuToggle}>
          {currentUser.fullname || currentUser?.email || null}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="">
            {/* <span><b className="ms-3 mb-3">Tedarikçi</b></span> */}
            <Dropdown.Item href="/supplier" className="text-primary">
              <i class="fa-duotone fa-user-tie"></i> {t("header.supplier.profile")}
            </Dropdown.Item>
          </div>

          {/*
          <Dropdown.Divider />

          <div className="">
            <span><b className="ms-3 mb-3">Kullanıcı</b></span>
            <Dropdown.Item href="/users/profile">
              <i className="fa-duotone fa-user"></i> {t("header.user.profile")}
            </Dropdown.Item>
            
          </div>
          */}
          
          <Dropdown.Divider />
          
          <Dropdown.Item onClick={handleLogout} className="text-danger">
            {t("header.user.signout")}<i class="fa-duotone fa-right-from-bracket ms-1"></i>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  function visitorMenu() {
    return (
      <Link to="/auth/sign-in" className="header-userItemLink">
        <span>{t("header.user.signin")}</span>
      </Link>
    );
  }

  return (
    <div className={styles.navContainer}>
      {currentUser ? loggedUserMenu() : visitorMenu()}
    </div>
  );
}
