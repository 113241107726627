import { useState } from "react";
import { useTranslation } from "react-i18next";

const AsyncButton = function (props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const clickHandler = async () => {
    setLoading(true);

    // fake waiting
    await new Promise((resolve, reject) => {
      resolve(true);
      /*
      setTimeout(async () => {
        resolve(true);
      }, 2000);
      */
    });

    try {
      const jobResult = await props.job();
      props.onData(jobResult);
    } catch (error) {
      props.onError(error);
    }

    setLoading(false);
  };

  return (
    <button
      className={
        loading ? props.className + " btn btn-secondary" : props.className
      }
      onClick={clickHandler}
      disabled={loading}
      style={{
        boxShadow: "0 0 8px 0 #aaa",
      }}
    >
      {loading ? (
        <>
          <span
            class="spinner-border spinner-border-sm me-2"
            aria-hidden="true"
          ></span>
          <span role="status">Processing...</span>
        </>
      ) : (
        props.children
      )}
    </button>
  );
};

export default AsyncButton;
