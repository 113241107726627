import AsyncButton from "./AsyncButton";

import { useDispatch, useSelector } from "react-redux";
import { add, remove } from "../../context/feature/Cart/cartSlice";

import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import useDidMountEffect from "../../utils/useDidMountEffect";

const AddToCartButton = function AddToCartButton(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const jobHandler = async function () {
    const data = props.setData();
    return data;
  };

  const removeProduct = (id) => {
    dispatch(remove({ id: id }));
  };

  const addProduct = (data) => {
    dispatch(add(data));
  };

  const showSwal = (productName) => {
    Swal.fire({
      title: t(
        props.inCart ? "swal.title.product.remove" : "swal.title.product.add"
      ),
      text: t(
        props.inCart ? "swal.text.product.remove" : "swal.text.product.add",
        {
          product_name: productName,
        }
      ),
      icon: "success",
      confirmButtonText: t("swal.button.confirm"),
    });
  };

  const onData = (data) => {
    console.log("job finished", data);

    if (props.inCart) {
      removeProduct(data.id);
    } else {
      addProduct(data);
    }

    showSwal(data.title);
  };

  useDidMountEffect(() => {
    if (props.onDone) {
      props.onDone(props.inCart);
    }
  }, [props.inCart]);

  return (
    <AsyncButton
      className={
        "btn btn-" +
        (props.inCart ? "danger" : "primary") +
        " " +
        props.className
      }
      job={jobHandler}
      onData={props.onData || onData}
      onDone={props.onDone}
    >
      <i
        class={
          "fa-solid fa-" + (props.inCart ? "trash-can" : "cart-plus") + " me-2"
        }
      ></i>
      {props.children ||
        t(
          props.inCart
            ? "product.button.removeFromCart"
            : "product.button.addToCart"
        )}
    </AsyncButton>
  );
};

export default AddToCartButton;
