import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="skeleton-title mb-2"></div>
          <div className="skeleton-line mb-2"></div>
          <div className="skeleton-line mb-2"></div>
          <div className="skeleton-line mb-2"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
