import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { currency } from "../../utils/funcs";
import Currency from "../Currency";
import { calculate } from "../../context/feature/Cart/cartSlice";

export default function CartOrderSummary(props) {
  const { t } = useTranslation();

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useDidMountEffect(() => {
    dispatch(calculate());
  }, [cart.products]);

  return (
    <div className="order-summaryContainer">
      <div className="order-summary">
        <h3 className="order-summary-title">
          <i class="fa-solid fa-receipt me-2"></i>
          {t("cart.order.summaryTitle")}
        </h3>
        {cart.products.length > 0 ? (
          <div className="order-summary-inner">
            <div className="order-summary-innerItem order-summary-productsTotal">
              <span>
                {t("cart.order.summary.productsTotal", {
                  count: cart.summary.productsTotalCount,
                })}
              </span>
              <span>
                <Currency value={cart.summary.productsTotal} />
              </span>
            </div>
            <div className="order-summary-innerItem order-summary-deliveryTotal">
              <span>{t("cart.order.summary.deliveryTotal")}</span>
              <span>
                <Currency value={cart.summary.deliveryTotal} />
              </span>
            </div>

            <hr />

            <div className="order-summary-innerItem order-summary-withoutTaxTotal">
              <span>{t("cart.order.summary.withoutTaxTotal")}</span>
              <span>
                <Currency value={cart.summary.withoutTaxTotal} />
              </span>
            </div>

            <div className="order-summary-innerItem order-summary-taxTotal">
              <span>{t("cart.order.summary.taxTotal")} (%18)</span>
              <span>
                <Currency value={cart.summary.taxTotal} />
              </span>
            </div>

            <hr />

            <div className="order-summary-innerItem order-summary-total text-primary">
              <span>{t("cart.order.summary.total")}</span>
              <span>
                <Currency value={cart.summary.total} />
              </span>
            </div>
          </div>
        ) : (
          <p className="pt-3 ps-4">{t("cart.empty")}</p>
        )}
      </div>
      {/* order-summary */}
      {cart.products.length > 0 && (
        <button className="order-summary-payNow w-100 mt-4 btn btn-primary">
          <i class="fa-solid fa-check me-2"></i>
          {t("cart.order.payNow")}
        </button>
      )}
    </div>
  );
}
