import { useSelector } from "react-redux";
import { availableCurrencies } from "../../context/feature/Currency/currencySlice";

export default function Currency(props) {
  const defaultCurrency = useSelector((state) => state.currency);

  const format = (value, exchange) => {
    let exchangeObject;
    if (exchange) {
      exchangeObject = availableCurrencies.find((c) => c.currency === exchange);
    }

    const formatter = new Intl.NumberFormat(
      exchangeObject?.countryCode || defaultCurrency.countryCode,
      {
        style: "currency",
        currency: exchangeObject?.currency || defaultCurrency.currency,
      }
    );
    return formatter.format(value);
  };

  return format(props.value, props.exchange);
}
