import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

/*Styles*/
import "./head-link-bar.css";

export default function HeadLinkBar({
  filterProductsByStars,
  filterLast7Days,
  filterLast30Days,
  filterLast90Days,
  filterLast180Days,
  filterLast360Days,
  handlePriceRange,
  filterProductsBySpecificPriceRange,
  sortProductsByPriceAsc,
  sortProductsByPriceDesc,
  handleClose,
}) {
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="container mb-3">
        <div className="row d-flex align-items-center">
          <div className="col-4 mt-2 mb-2">
            {[4].map((star) => (
              <div key={star}>
                <button
                  className="btn col-12 custom-btn"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "rgba(255, 80, 0, 1)",
                  }}
                  onClick={() => filterProductsByStars(star)}
                >
                  Yüksek Puanlı Ürünler
                </button>
              </div>
            ))}
          </div>
          <div className="col-4 mt-2 mb-2">
            <button
              className="btn col-12 custom-btn"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "rgba(255, 80, 0, 1)",
              }}
              onClick={filterLast7Days}
            >
              Yeni Eklenenler
            </button>
          </div>
          <div className="col-4 mt-2 mb-2">
            <div className="dropdown">
              <span
                className="btn dropdown-toggle col-12 custom-btn"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "rgba(255, 80, 0, 1)",
                }}
              >
                Akıllı Sıralama
              </span>
              <ul
                className="dropdown-menu w-100"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <button
                    className="dropdown-item custom-btn"
                    style={{ border: "none" }}
                    onClick={sortProductsByPriceAsc}
                  >
                    Fiyata Göre Artan
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item custom-btn"
                    style={{ border: "none" }}
                    onClick={sortProductsByPriceDesc}
                  >
                    Fiyata Göre Azalan
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item custom-btn"
                    style={{ border: "none" }}
                    onClick={() => filterLast30Days()}
                  >
                    En Yeniler
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
