import React, { useEffect, useRef, useState } from "react";
import API from "../../utils/api";

import useDidMountEffect from "../../utils/useDidMountEffect";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import AsyncButton from "../CustomInputs/AsyncButton";

import Swal from "sweetalert2";

import "./apiKeyGenerator.css";
import SeoLayer from "../SeoLayer/SeoLayer";

const maxAllowedDomains = 3;

export default function ApiKeyGenerator(props) {
  const { t } = useTranslation();

  const domainRef = useRef();

  const [apiData, setApiData] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [domains, setDomain] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await API.post("/api/me");
        setApiData(response.data.api_data);
        setDomain(response.data.api_data.allowed_domains);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchApiKeyGenerate = async () => {
    setIsLoading(true);
    try {
      const response = await API.post("/api/create");
      return response.data.api_data;
    } catch (err) {
      throw new Error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onData = async (apiData) => {
    setApiData(apiData);
    setShowApiKey(true);
    Swal.fire({
      title: t("api_key_generator.swal.operation_successful"),
      text: t("api_key_generator.swal.text"),
      icon: "success",
      confirmButtonText: t("swal.button.confirm"),
    });
  };

  const onError = (message) => {
    Swal.fire({
      title: t("api_key_generator.swal.operation_failed"),
      text: message,
      icon: "error",
      confirmButtonText: t("swal.button.confirm"),
    });
  };

  const copyData = (data) => {
    navigator.clipboard.writeText(data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Kopyalandı",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const showApiKeyToggle = () => {
    setShowApiKey(!showApiKey);
  };

  function isValidDomain(domain) {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_\*]+\.)+[a-zA-Z]{2,11}?$/;
    return domainPattern.test(domain);
  }

  function isValidIPAddress(ip) {
    const ipPattern =
      /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
    return ipPattern.test(ip);
  }

  const handleClickDomainAdd = () => {
    const taggedDomainValue = domainRef.current.value
      .toString()
      .trim()
      .toLowerCase();
    domainRef.current.value = taggedDomainValue;

    try {
      if (taggedDomainValue.length === 0) {
        throw new DOMException(t("api_key_generator.domain_empty"));
      }

      if (domains.length >= maxAllowedDomains) {
        throw new DOMException(t("api_key_generator.domain_max"));
      }

      if (
        domains.includes(taggedDomainValue) === true ||
        (
          isValidIPAddress(taggedDomainValue) !== true &&
          isValidDomain(taggedDomainValue) !== true
        )
      ) {
        throw new DOMException(t("api_key_generator.domain_invalid"));
      }

      setDomain((prevData) => [...prevData, taggedDomainValue]);
    } catch (error) {
      console.log(error.message);
    } finally {
      domainRef.current.value = "";
    }
  };

  const handleOnClickDomainRemove = (index) => {
    setDomain((prevData) => [...prevData.filter((d, i) => i !== index)]);
  };

  const handleClickSaveDomains = async () => {
    if (domains.length > 0) {
      setIsLoading(true);
      try {
        const response = await API.post("/api/update", {
          allowed_domains: domains,
        });
        console.log("%c/api/update", response, "color:green");
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <SideBar>
      <SeoLayer
        title={t("seo_layer.supplier_api_key_generator.title")}
        description={t("seo_layer.supplier_api_key_generator.description")}
        endpoint="supplier/api-key-generator"
      />
      <h3 className="my-4">
        {/* API Erişimi */}
        {t("api_key_generator.title")}
        <button
          className="float-end btn btn-sm btn-dark"
          onClick={showApiKeyToggle}
        >
          <i
            className={"fa-duotone fa-" + (showApiKey ? "eye" : "eye-slash")}
          ></i>
        </button>
      </h3>
      <p className="mb-4">
        {/* API kullanımına başlamak için öncelikle bir API Anahtarı oluşturmanız
        gerekmektedir. Oluşturacağınız bu API anahtarını buy.run API uç
        noktalarına erişiminizde kullanabileceksiniz. */}
        {t("api_key_generator.api_key_use_description")}
      </p>

      {apiData !== false && (
        <>
          <div className="col-lg-12 col-12 mb-3">
            <label className="mb-2">
              <b>
                {/* API Erişim Anahtarınız */}{" "}
                {t("api_key_generator.your_api_key")}{" "}
              </b>
            </label>
            <p className="mb-4">
              {/* API anahtarını kullanırken göndereceğiniz istek ile ilgili header
            token türünü  */}{" "}
              {t("api_key_generator.create_api_key_info_1")}{" "}
              <u>Bearer Authentication</u>{" "}
              {/* formatında hazırlamalısınız ve bununla
            birlikte */}{" "}
              {t("api_key_generator.create_api_key_info_2")}{" "}
              <u>authorization-key</u>{" "}
              {/* anahtarı ile özel anahtarınızı
            iliştirdiğiinizden emin olmalısıız. */}{" "}
              {t("api_key_generator.create_api_key_info_3")}
            </p>

            <div className="border rounded p-3 mb-4">
              <label className="mb-2">
                <b>
                  {/* API Özel Anahtarı */}{" "}
                  {t("api_key_generator.api_private_key")}
                </b>
              </label>
              <button
                className="float-end btn btn-sm btn-primary ms-2"
                onClick={() => copyData(apiData.secret_key)}
              >
                <i className="fa-duotone fa-copy"></i>
              </button>
              <p className="api-text text-break m-0 mt-3 font-monospace">
                {showApiKey
                  ? apiData.secret_key
                  : apiData.secret_key.replace(/./g, "*")}
              </p>
            </div>

            <div className="border rounded p-3 mb-4">
              <label className="mb-2">
                <b>
                  {/* API Anahtarı */}
                  {t("api_key_generator.api_key")}
                </b>
              </label>
              <button
                className="float-end btn btn-sm btn-primary ms-2"
                onClick={() => copyData(apiData.token)}
              >
                <i className="fa-duotone fa-copy"></i>
              </button>
              <p className="api-text text-break m-0 mt-3 font-monospace">
                {showApiKey ? apiData.token : apiData.token.replace(/./g, "*")}
              </p>
            </div>

            <div className="border rounded p-3">
              <label className="mb-2">
                <b>{t("api_key_generator.domain_title")}</b>
              </label>
              <div className="col-lg-12 mb-3">
                <div className="input-group">
                  <input
                    ref={domainRef}
                    className="form-control"
                    type="domains"
                    name="domains"
                    required={true}
                    disabled={isLoading || domains.length >= maxAllowedDomains}
                  />
                  <button
                    className="btn btn-success"
                    onClick={handleClickDomainAdd}
                    disabled={isLoading || domains.length >= maxAllowedDomains}
                  >
                    <i className="fa-solid fa-plus me-1"></i>{" "}
                    {t("api_key_generator.domains_add")}
                  </button>
                </div>
                <div class="form-text">
                  {t("api_key_generator.domains_helper")}
                </div>
                {domains.length > 0 && (
                  <ul className="taggedDomains list-group mt-3">
                    {domains.map((d, i) => (
                      <li
                        key={i}
                        className="taggedDomains list-group-item list-group-item-success d-flex justify-content-between align-items-center"
                      >
                        {d}
                        <span
                          class="badge text-bg-danger"
                          onClick={() => handleOnClickDomainRemove(i)}
                        >
                          <i className="fa-solid fa-times m-0"></i>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <button
                className={
                  "btn btn-" + (domains.length === 0 ? "secondary" : "primary")
                }
                onClick={handleClickSaveDomains}
                disabled={domains.length === 0}
              >
                <i className="fa-duotone fa-save me-1"></i>{" "}
                {t("api_key_generator.domains_save")}
              </button>
            </div>
          </div>
        </>
      )}

      {apiData === false && isLoading === false && (
        <AsyncButton
          className="btn btn-primary"
          job={fetchApiKeyGenerate}
          onData={onData}
          onError={onError}
        >
          <i className="fa-duotone fa-sync me-2"></i>
          {/* API anahtarı oluştur */}
          {t("api_key_generator.create_api_key")}
        </AsyncButton>
      )}

      {isLoading && (
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">
              {" "}
              {t("api_key_generator.loading")}...
            </span>
          </div>
        </div>
      )}
    </SideBar>
  );
}
