import {
  Suspense,
  memo,
  useDeferredValue,
  useState,
  useEffect,
  useRef,
} from "react";
import "./headerSearchbar.css";
import { useTranslation } from "react-i18next";
/* import dummyData from "../Categories/dummyData.json"; */
import productData from "../Categories/productData.json";
import QuotationsOfRequest from "../Quotations/QuotationsOfRequest";
import "bootstrap/dist/css/bootstrap.min.css";

const ITEMS_PER_PAGE = 10;

const SearchResults = memo(function SearchResults({
  query,
  currentPage,
  onPageChange,
  showAll,
  setShowAll,
}) {
  const { t } = useTranslation();

  function highlightStr(str) {
    const regex = new RegExp(`(${query})`, "gi");
    return str.replace(regex, `<b style="color:orange;">$1</b>`);
  }

  const filteredData = productData.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
      (item.category &&
        item.category.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
      (item.brand &&
        item.brand.toLowerCase().indexOf(query.toLowerCase()) !== -1)
  );

  if (filteredData.length === 0) {
    return (
      <>
        <h5>
          "{query}" {t("search_bar.h5_1")}
        </h5>
        <p>
          {t("search_bar.p_1")}
          <b className="text-primary">{t("search_bar.p_2")}</b>
          {t("search_bar.p_3")}
        </p>
        <hr />
        <QuotationsOfRequest />
      </>
    );
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedData = filteredData.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const filteredDataHtml = paginatedData.map((item) => (
    <div key={item.id} className="col-12">
      <div className="headerSearchItem d-flex flex-row align-items-center">
        <img
          className="d-inline-block border rounded p-1 me-2"
          src={item.image}
          style={{ height: "32px" }}
          alt={item.name}
        />
        <h6
          dangerouslySetInnerHTML={{ __html: highlightStr(item.name) }}
          style={{ fontSize: 13 }}
        />
      </div>
    </div>
  ));

  const handleShowAll = () => {
    setShowAll(true);
  };

  return (
    <>
      <div className="row">
        {showAll
          ? filteredData.map((item) => (
              <div key={item.id} className="col-12">
                <div className="headerSearchItem d-flex flex-row align-items-center">
                  <img
                    className="d-inline-block border rounded p-1 me-2"
                    src={item.image}
                    style={{ height: "32px" }}
                    alt={item.name}
                  />
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: highlightStr(item.name),
                    }}
                    style={{ fontSize: 13 }}
                  />
                </div>
              </div>
            ))
          : filteredDataHtml}
      </div>
      {!showAll && (
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-primary" onClick={handleShowAll}>
            {t("search_bar.show_all_button")}
          </button>
        </div>
      )}
    </>
  );
});

const HeaderSearchBar = ({ onSearch }) => {
  const { t } = useTranslation();

  /* STATES */
  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");
  const deferredQuery = useDeferredValue(query);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResults, setShowResults] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const searchWrapperRef = useRef(null);
  /* STATES END */

  /* FUNCTIONS */
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (onSearch) {
      onSearch(e.target.value);
    }
    setCurrentPage(1);
    setShowResults(true);
    setShowAll(false);
  };

  const keyupHandler = (event) => {
    setQuery(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClickOutside = (event) => {
    if (
      searchWrapperRef.current &&
      !searchWrapperRef.current.contains(event.target)
    ) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header-search-wrapper" ref={searchWrapperRef}>
      <input
        className="header-searchInput buyrun-form-control form-control"
        type="text"
        name="header-search-q"
        placeholder={t("header.search.placeholder")}
        onKeyUp={keyupHandler}
        value={searchTerm}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {query && showResults && (
        <div className="header-search-results w-100 p-3">
          <Suspense fallback={<h2>Loading...</h2>}>
            <SearchResults
              query={deferredQuery}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              showAll={showAll}
              setShowAll={setShowAll}
            />
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default HeaderSearchBar;
