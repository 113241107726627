import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function AsyncModal(props) {
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSaveHandler = async (e) => {
    setLoading(true);
    await props.process(setShow);
    setLoading(false);
  };

  return (
    <>
      <Link
        id={props.lunchButtonId}
        className="d-inline-flex lunchButton"
        variant="primary"
        onClick={handleShow}
        style={{
          textDecoration: "none",
        }}
      >
        {props.lunchButtonText}
      </Link>

      <Modal
        show={show}
        //onHide={handleClose}
        keyboard={false}
        backdrop="static"
        size={props.modalSize}
      >
        <Modal.Header>
          <Modal.Title>{props.modalTitleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          {!isLoading && (
            <Button variant="secondary" onClick={handleClose}>
              {props.modalCloseButtonText}
            </Button>
          )}
          <Button
            type="button"
            variant="primary"
            onClick={onSaveHandler}
            disabled={isLoading}
          >
            {!isLoading ? props.modalSaveButtonText : 'İşlem devam ediyor...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AsyncModal;
