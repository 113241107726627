import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import "./contact.css";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import API from "../../utils/api";

import Swal from "sweetalert2";
import SeoLayer from "../SeoLayer/SeoLayer";

export default function Conctact(props) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.user.currentUser);

  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleProcess = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData(formRef.current);

    try {
      if (formRef.current.checkValidity() === false) {
        formRef.current.classList.add("was-validated");
        throw Object.assign({
          response: { data: { message: t("contact_form.empty-error") } },
        });
      }

      const response = await API.post("/contact/create", {
        fullname: currentUser.fullname,
        email: currentUser.email,
        message: formData.get("message"),
      });

      setIsSent(true);
    } catch (error) {
      Swal.fire({
        title: t("contact_form.swal-error-title"),
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function contactForm() {
    return (
      <div>
        <SeoLayer
          title={t("seo_layer.contact.title")}
          description={t("seo_layer.contact.description")}
          endpoint="contact"
        />
        <h3 className="my-4">{t("contact_form.title")}</h3>
        <form
          ref={formRef}
          id="contactForm"
          className="row"
          noValidate
          onSubmit={handleProcess}
        >
          <div className="col-lg-12 mb-3">
            <label className="mb-1">{t("contact_form.fullname")}</label>
            <input
              className="form-control"
              type="text"
              name="fullname"
              pattern="(.+)\s+(.+\s+)?(.+)"
              required={true}
              readOnly={true}
              disabled={isLoading}
              defaultValue={currentUser.fullname}
            />
            <div class="invalid-feedback">
              {t("contact_form.fullname-invalid")}
            </div>
          </div>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">{t("contact_form.email")}</label>
            <input
              className="form-control"
              type="email"
              name="email"
              required={true}
              readOnly={true}
              disabled={isLoading}
              defaultValue={currentUser.email}
            />
            <div class="invalid-feedback">
              {t("contact_form.email-invalid")}
            </div>
          </div>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">{t("contact_form.message")}</label>
            <textarea
              className="form-control"
              type="text"
              name="message"
              minLength="100"
              maxLength="500"
              rows={8}
              required={true}
              disabled={isLoading}
            ></textarea>
            <div class="invalid-feedback">
              {t("contact_form.message-invalid")}
            </div>
          </div>

          <div className="col-lg-12">
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>
                    {t("contact_form.sending")}{" "}
                    <i class="fa-duotone fa-paper-plane"></i>
                  </span>
                ) : (
                  <span>
                    {t("contact_form.send")}{" "}
                    <i class="fa-duotone fa-paper-plane-top"></i>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  function sentMessage() {
    return (
      <div className="alert alert-success">
        <h5>{t("contact_form.sent.title")}</h5>
        <p>{t("contact_form.sent.message")}</p>
      </div>
    );
  }

  return <SideBar>{isSent === false ? contactForm() : sentMessage()}</SideBar>;
}
