import React, { useRef, useState } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";

import useDidMountEffect from "../../utils/useDidMountEffect";
import SideBar from "../SideBar";

import { availableCurrencies } from "../../context/feature/Currency/currencySlice";

import "./manualProductAdd.css";
import { useTranslation } from "react-i18next";

import API from "../../utils/api";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Swal from "sweetalert2";
import SeoLayer from "../SeoLayer/SeoLayer";

const manufacturerList = [
  {
    id: 1,
    title: "GENUINE",
    value: "original",
  },
  {
    id: 4,
    title: "OEM",
    value: "oem",
  },
  {
    id: 3,
    title: "AFTERMARKET",
    value: "aftermarket",
  },
  {
    id: 6,
    title: "NO_NAME",
    value: "noName",
  },
];

const conditionList = [
  {
    id: 1,
    title: "ne",
    value: "ne",
  },
  {
    id: 2,
    title: "rd",
    value: "rd",
  },
  {
    id: 3,
    title: "ex",
    value: "ex",
  },
  {
    id: 4,
    title: "oh",
    value: "oh",
  },
  {
    id: 5,
    title: "rb",
    value: "rb",
  },
  {
    id: 6,
    title: "fn",
    value: "fn",
  },
  {
    id: 7,
    title: "in",
    value: "in",
  },
  {
    id: 8,
    title: "mo",
    value: "mo",
  },
  {
    id: 9,
    title: "sv",
    value: "sv",
  },
  {
    id: 10,
    title: "cr",
    value: "cr",
  },
  {
    id: 11,
    title: "tst",
    value: "tst",
  },
  {
    id: 12,
    title: "rp",
    value: "rp",
  },
  {
    id: 13,
    title: "ber",
    value: "ber",
  },
  {
    id: 14,
    title: "ns",
    value: "ns",
  },
  {
    id: 15,
    title: "ar",
    value: "ar",
  },
  {
    id: 16,
    title: "used",
    value: "used",
  },
  {
    id: 17,
    title: "or",
    value: "or",
  },
  {
    id: 5,
    title: "tk",
    value: "tk",
  },
];

const availableSizeUnits = ["CM", "MM", "M", "INCH"];
const availableWeightUnits = ["KG", "G", "LB", "OZ", "CARAT", "TON"];

const mediaConstraints = {
  image: {
    allowedTypes: ["image/jpeg", "image/jpg", "image/png"],
    maxSelect: 10,
    perMaxSize: 5,
  },
  video: {
    allowedTypes: ["video/mp4"],
    maxSelect: 2,
    perMaxSize: 20,
  },
};

export default function ManualProductAdd(props) {
  const { t } = useTranslation();

  const productFormRef = useRef();
  const wholesaleFormRef = useRef();
  const retailFormRef = useRef();

  const descriptionRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [product, setProduct] = useState({});
  const [wholesale, setWholesale] = useState([]);
  const [retail, setRetail] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useDidMountEffect(async () => {
    const countriesData = await fetch(
      "https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/countries.json"
    ).then((res) => res.json());

    setCountries(countriesData);
  }, []);

  function getError(text) {
    Swal.fire({
      title: "HATA!",
      text: text,
      icon: "error",
      confirmButtonText: t("swal.button.confirm"),
    });
  }

  function currenyOptionsForSelect(selectedCode) {
    console.log("availableCurrencies", availableCurrencies);
    return availableCurrencies.map((e) => (
      <option
        style={{ color: "rgb(234, 88, 12)" }}
        value={e.currency}
        selected={e.shortCode === selectedCode}
      >
        {e.currency}
      </option>
    ));
  }

  function sizeUnitsOptionsForSelect() {
    return availableSizeUnits.map((d) => <option value={d}>{d}</option>);
  }

  function weightUnitsOptionsForSelect() {
    return availableWeightUnits.map((d) => <option value={d}>{d}</option>);
  }

  function getYearsForSelect() {
    const startDate = new Date(1970, 0, 1);
    const endDate = new Date(2024, 11, 31);
    const dateRange = [];

    for (
      let year = endDate.getFullYear();
      year >= startDate.getFullYear();
      year--
    ) {
      dateRange.push(year);
    }

    return dateRange.map((year, index) => (
      <option key={index} value={year}>
        {year}
      </option>
    ));
  }

  const filterMedia = (mediaArr, types) => {
    return mediaArr.filter((m) => types.includes(m.type));
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    let prevData = [...selectedMedia];

    for (let i = 0; i < files.length; i++) {
      /* check allowed types */
      if (
        [
          ...mediaConstraints.image.allowedTypes,
          ...mediaConstraints.video.allowedTypes,
        ].includes(files[i].type) === false
      ) {
        console.warn("not allowed type");
        continue;
      }

      /* check same data */
      if (prevData.find((e) => e.name === files[i].name)) {
        console.warn("already selected media");
        continue;
      }

      /* image limitation */
      if (mediaConstraints.image.allowedTypes.includes(files[i].type)) {
        if (
          filterMedia(prevData, mediaConstraints.image.allowedTypes).length >=
          mediaConstraints.image.maxSelect
        ) {
          console.warn("max images limit exceeded");
          continue;
        }
        if (files[i].size / 1000000 > mediaConstraints.image.perMaxSize) {
          console.warn("images size exceeded per max size");
          continue;
        }
      }

      /* video limitation */
      if (mediaConstraints.video.allowedTypes.includes(files[i].type)) {
        if (
          filterMedia(prevData, mediaConstraints.video.allowedTypes).length >=
          mediaConstraints.video.maxSelect
        ) {
          console.warn("max videos limit exceeded");
          continue;
        }
        if (files[i].size / 1000000 > mediaConstraints.video.perMaxSize) {
          console.warn("videos size exceeded per max size");
          continue;
        }
      }

      /* everything is good therefore push it */
      /* thumbnail data */
      const pushItem = files[i];
      pushItem.blob = URL.createObjectURL(files[i]);
      prevData.push(pushItem);
    }

    setSelectedMedia(prevData);
  };

  function removeSelectedImage(event, index) {
    event.preventDefault();

    /* thumbnails */
    const filteredSelectedImages = selectedMedia.filter((d, i) => i !== index);
    setSelectedMedia(filteredSelectedImages);
  }

  /* Ürün */
  function productForm() {
    return (
      <form
        id="formProduct"
        name="formProduct"
        className="row"
        ref={productFormRef}
        noValidate
        autoComplete="off"
      >
        <div className="col-lg-12">
          <p className="alert alert-info">{t("manual_product_add.info")}</p>
        </div>

        <div className="col-lg-12 mb-3">
          <p className="mb-2">
            {t("manual_product_add.media")} - <b>{selectedMedia.length}</b>{" "}
            {t("manual_product_add.visual_content_selected_total_size")}{" "}
            <b>
              {(
                selectedMedia.reduce((a, b) => a + b.size, 0) / 1000000
              ).toFixed(2)}
              mb
            </b>
          </p>
          <div className="imagesWrapper">
            <label htmlFor="images" className="fileBrowseButton">
              <i class="fa-duotone fa-image mb-2" style={{ fontSize: 32 }}></i>
              <b>{t("manual_product_add.click_to_select_media")}</b>
            </label>

            {selectedMedia.length > 0 &&
              selectedMedia.map((img, i) => (
                <div className="imageThumbnail">
                  {img.type === "video/mp4" ? (
                    <i class="fa-duotone fa-video" style={{ fontSize: 32 }}></i>
                  ) : (
                    <img src={img.blob} />
                  )}
                  <span
                    className="imageThumbnail-remove"
                    onClick={(event) => removeSelectedImage(event, i)}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </span>
                  <span className="imageThumbnail-name">{img.name}</span>
                </div>
              ))}
          </div>
          <input
            id="images"
            className="d-none form-control"
            type="file"
            name="images"
            multiple={true}
            required
            onChange={handleImageChange}
            accept={[
              ...mediaConstraints.image.allowedTypes,
              ...mediaConstraints.video.allowedTypes,
            ]}
          />
          <div className="info-lines mt-1">
            <p className="m-0">
              * {t("manual_product_add.media_formats")}{" "}
              <b className="text-success">
                {[
                  ...mediaConstraints.image.allowedTypes,
                  ...mediaConstraints.video.allowedTypes,
                ].join(", ")}
              </b>{" "}
              {t("manual_product_add.may_be")}
            </p>
            <p className="m-0">
              * {t("manual_product_add.at_most")}{" "}
              <b className="text-success">
                {mediaConstraints.image.maxSelect}{" "}
                {t("manual_product_add.visual")}
              </b>{" "}
              {t("manual_product_add.and")}{" "}
              <b className="text-success">
                {mediaConstraints.video.maxSelect}{" "}
                {t("manual_product_add.video")}
              </b>{" "}
              {t("manual_product_add.can_be_added")}
            </p>
            <p className="m-0">
              * {t("manual_product_add.1_image_size_maximum")}{" "}
              <b className="text-success">
                {mediaConstraints.image.perMaxSize}mb
              </b>{" "}
              {t("manual_product_add.1_video_size_maximum")}{" "}
              <b className="text-success">
                {mediaConstraints.video.perMaxSize}mb
              </b>{" "}
              {t("manual_product_add.may_be")}
            </p>
            <p className="m-0">
              * {t("manual_product_add.total_size")}{" "}
              <b className="text-success">
                {mediaConstraints.image.perMaxSize *
                  mediaConstraints.image.maxSelect +
                  mediaConstraints.video.perMaxSize *
                    mediaConstraints.video.maxSelect}
                mb
              </b>{" "}
              {t("manual_product_add.must_not_exceed")}{" "}
            </p>
          </div>
          <div class="invalid-feedback">
            {t("manual_product_add.must_not_exceed_invalid")}
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            {" "}
            {t("manual_product_add.product_name")}
          </label>
          <input
            className="form-control"
            type="text"
            name="title"
            placeholder={t("manual_product_add.product_name_placeholder")}
            required
          />
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_name_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            {t("manual_product_add.product_description")}
          </label>
          <ReactQuill
            ref={descriptionRef}
            theme="snow"
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
            required={true}
          />
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {t("manual_product_add.product_brand")}
          </label>
          <input
            className="form-control"
            type="text"
            name="brand"
            placeholder={t("manual_product_add.product_brand_placeholder")}
            required
          />
          {/* <select className="form-select" name="brand" required>
            <option value="">Marka seç</option>
            <option value="Caterpillar" selected>
              Caterpillar
            </option>
          </select> */}
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_brand_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {t("manual_product_add.product_modal")}
          </label>
          <input
            className="form-control"
            type="text"
            name="model"
            placeholder={t("manual_product_add.product_modal_placeholder")}
            required
          />
          {/* <select className="form-select" name="model" required>
            <option value="">Model seç</option>
            <option value="CA1527375" selected>
              CA1527375
            </option>
          </select> */}
          <div class="invalid-feedback">
            {t("manual_product_add.product_modal_invalid_feedback")}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">{t("manual_product_add.product_year")}</label>
          <select className="form-select" name="production_year" required>
            <option value="">
              {" "}
              {t("manual_product_add.product_year_placeholder")}{" "}
            </option>
            {getYearsForSelect()}
          </select>
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_year_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {" "}
            {t("manual_product_add.product_number")}
          </label>
          <input
            className="form-control"
            type="text"
            name="part_no"
            placeholder={t("manual_product_add.product_number_placeholder")}
            required
          />
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_number_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {" "}
            {t("manual_product_add.product_serial")}{" "}
          </label>
          <input
            className="form-control"
            type="text"
            name="serial_no"
            placeholder={t("manual_product_add.product_serial_placeholder")}
            required
          />
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_serial_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {" "}
            {t("manual_product_add.product_origin")}{" "}
          </label>
          <select className="form-select" name="country_of_origin" required>
            <option value="">
              {" "}
              {t("manual_product_add.product_origin_placeholder")}{" "}
            </option>
            {countries.length > 0 &&
              countries.map((c) => (
                <option value={c.name}>{c.emoji + " " + c.name}</option>
              ))}
          </select>
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_origin_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {" "}
            {t("manual_product_add.product_raw_material")}{" "}
          </label>
          <input
            className="form-control"
            type="text"
            name="raw_material"
            placeholder={t(
              "manual_product_add.product_raw_material_placeholder"
            )}
            required
          />
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_raw_material_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {" "}
            {t("manual_product_add.product_customs_code")}{" "}
          </label>
          <input
            className="form-control"
            type="text"
            name="gtip"
            placeholder={t(
              "manual_product_add.product_customs_code_placeholder"
            )}
            required
          />
          <div class="invalid-feedback">
            {" "}
            {t("manual_product_add.product_customs_code_invalid_feedback")}{" "}
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            {" "}
            {t("manual_product_add.product_condition")}{" "}
          </label>
          <div className="manufacturerList mb-3">
            <h5 className="mb-1">
              {" "}
              {t("manual_product_add.product_manufacturer")}{" "}
            </h5>
            <div className="row manufacturerListItems">
              {manufacturerList.map((item) => {
                const id = `manufacturerItem${item.id}`;
                return (
                  <div key={id} className="manufacturerListItem col-lg-4">
                    <input
                      id={id}
                      className="form-check-input"
                      type="radio"
                      name="manufacturer"
                      value={item.value}
                      required
                    />
                    <label
                      htmlFor={id}
                      className="ms-2"
                      title={t(`manual_product_add.manufacturer.${item.title}`)}
                    >
                      {t(`manual_product_add.${item.title}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="conditionList">
            <h5 className="mb-1">
              {t("manual_product_add.product_condition")}
            </h5>
            <div className="row conditionListItems">
              {conditionList.map((item) => {
                const id = `conditionItem${item.id}`;
                return (
                  <div key={id} className="conditionListItem col-lg-4">
                    <input
                      id={id}
                      className="form-check-input"
                      type="radio"
                      name="condition"
                      value={item.value}
                      required
                    />
                    <label
                      htmlFor={id}
                      className="ms-2"
                      title={t(`manual_product_add.${item.title}`)}
                    >
                      {t(`manual_product_add.${item.title}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    );
  }

  async function productFormSubmit() {
    if (descriptionRef.current.value.length === 0) {
      document.querySelector(".quill").classList.add("invalid");
      getError(t("manual_product_add.errors_description_required"));
      return false;
    }

    if (productFormRef.current.checkValidity() === false) {
      document.querySelector(".quill").classList.remove("invalid");
      document.querySelector(".quill").classList.add("valid");
      productFormRef.current.classList.add("was-validated");
      getError(t("manual_product_add.errors_check_fields"));
      return false;
    }

    const sendFormData = new FormData(productFormRef.current);
    sendFormData.append("description", descriptionRef.current.value);
    sendFormData.append("images", selectedMedia);

    const productData = sendFormData.entries();
    setProduct(productData);

    if (retail.length === 0) {
      Swal.fire({
        title: t("manual_product_add.swal_error"),
        text: t("manual_product_add.retail_data_must_be_entered"),
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
      return false;
    }

    for (const o of wholesale) {
      for (const d of o) {
        sendFormData.append(d[0], d[1]);
      }
    }

    for (const o of retail) {
      for (const d of o) {
        sendFormData.append(d[0], d[1]);
      }
    }

    setIsLoading(true);
    try {
      const response = await API.post("/products/create", sendFormData, {
        timeout: 0,
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      Swal.fire({
        title: t("manual_product_add.swal_success"),
        text: t("manual_product_add.swal_product_added_success"),
        icon: "success",
        confirmButtonText: t("swal.button.confirm"),
      });
    } catch (error) {
      Swal.fire({
        title: t("manual_product_add.swal_product_added_error"),
        text: error.message,
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  /* Toptan */
  function modalBodyWholesale() {
    return (
      <div className="d-block">
        <p className="alert alert-info">
          <i className="fa-solid fa-circle-info me-2"></i>{" "}
          {t("manual_product_add.enter_product_variant_details_info")}{" "}
        </p>

        <form
          id="formWholesale"
          name="formWholesale"
          className="row"
          ref={wholesaleFormRef}
          noValidate
          autoComplete="off"
        >
          <h6 className="mb-3 mb-0">
            <i class="fa-duotone fa-money-check-dollar-pen me-2"></i>
            <b>{t("manual_product_add.modal_pricing_preferences_title")} </b>
          </h6>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">
              {t("manual_product_add.modal_variant_name")}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="wholesale[title]"
                required
                placeholder={t(
                  "manual_product_add.modal_variant_name_placeholder"
                )}
              />
              <div class="invalid-feedback">
                {t("manual_product_add.modal_variant_name_invalid_feedback")}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">
              {t("manual_product_add.modal_total_stocks")}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="wholesale[stock]"
                required
                placeholder="20"
              />
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_total_stocks_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_unit_price")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control w-50"
                type="text"
                name="wholesale[price][retail][value]"
                required
                placeholder="100"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[price][retail][currency]"
              >
                {currenyOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t("manual_product_add.modal_unit_price_invalid_feedback")}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {t("manual_product_add.modal_number_of_wholesale")}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="wholesale[quantity]"
                required
                placeholder="5"
              />
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_number_of_wholesale_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {t("manual_product_add.modal_wholesale_price")}
            </label>
            <div className="input-group">
              <input
                className="form-control w-50"
                type="text"
                name="wholesale[price][wholesale][value]"
                required
                placeholder="500"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[price][wholesale][currency]"
              >
                {currenyOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {t("manual_product_add.modal_wholesale_price_invalid_feedback")}
              </div>
            </div>
          </div>

          <h6 className="mt-4 mb-0">
            <i class="fa-duotone fa-arrow-progress me-2"></i>
            <b> {t("manual_product_add.modal_shipping_preferences")} </b>
          </h6>

          <span className="my-3">
            {" "}
            {t("manual_product_add.modal_shipping_preferences_info_net")}{" "}
          </span>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_length")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][net][height][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][net][height][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_length_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_width")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][net][width][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][net][width][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t("manual_product_add.modal_product_width_invalid_feedback")}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_depth")}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][net][depth][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][net][depth][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_depth_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <span className="my-3">
            {" "}
            {t("manual_product_add.modal_brute_product_size_packed")}{" "}
          </span>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_length")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][gross][height][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][gross][height][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_length_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_width")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][gross][width][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][gross][width][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_width_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_depth")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][gross][depth][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][gross][depth][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_depth_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3 mb-3">
            <div className="info-lines">
              <p className="m-0">
                * {t("manual_product_add.modal_net_product_size_info")}
              </p>
              <p className="m-0">
                * {t("manual_product_add.modal_brute_product_size_info")}
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_net_weight_unpacked")}{" "}
            </label>
            <label className="d-block mb-2">
              {" "}
              {t("manual_product_add.modal_product_weight")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][net][weight][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][net][weight][unit]"
              >
                {weightUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t("manual_product_add.modal_net_weight_invalid_feedback")}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_brute_weight_unpacked")}{" "}
            </label>
            <label className="d-block mb-2">
              {" "}
              {t("manual_product_add.modal_product_weight")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="wholesale[transportation][gross][weight][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="wholesale[transportation][gross][weight][unit]"
              >
                {weightUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_brute_weight_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_packaging_type")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="wholesale[packaging_type]"
                required
                placeholder="Palet"
              />
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_packaging_type_invalid_feedback"
                )}{" "}
              </div>
            </div>
            <div className="form-text">
              {" "}
              {t(
                "manual_product_add.modal_product_packaging_type_placeholder"
              )}{" "}
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <div className="info-lines">
              <p className="m-0">
                * {t("manual_product_add.modal_product_net_size_info")}{" "}
              </p>
              <p className="m-0">
                * {t("manual_product_add.modal_product_brute_size_info")}
              </p>
              <p className="m-0">
                * {t("manual_product_add.modal_product_packaging_type_info")}
              </p>
            </div>
          </div>
        </form>
      </div>
    );
  }

  async function wholesaleFormSubmit(setShow) {
    if (wholesaleFormRef.current.checkValidity() === false) {
      wholesaleFormRef.current.classList.add("was-validated");
      return false;
    }

    const wholesaleFormData = new FormData(wholesaleFormRef.current);
    const newData = wholesaleFormData.entries().toArray();

    setWholesale((prevData) => [...prevData, newData]);
    setShow(false);
  }

  /* Perakende */
  function modalBodyRetail() {
    return (
      <div className="d-block">
        <p className="alert alert-info">
          <i className="fa-solid fa-circle-info me-2"></i>{" "}
          {t("manual_product_add.enter_product_variant_details_info")}
        </p>

        <form
          id="formRetail"
          name="formRetail"
          className="row"
          ref={retailFormRef}
          noValidate
          autoComplete="off"
        >
          <h6 className="mb-3 mb-0">
            <i class="fa-duotone fa-money-check-dollar-pen me-2"></i>
            <b>{t("manual_product_add.modal_pricing_preferences_title")}</b>
          </h6>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_variant_name")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="retail[title]"
                required
                placeholder={t(
                  "manual_product_add.modal_variant_name_placeholder_retail"
                )}
              />
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_variant_name_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_total_stocks")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="retail[stock]"
                required
                placeholder="20"
              />
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_total_stocks_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-3">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_unit_price")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control w-50"
                type="text"
                name="retail[price][retail][value]"
                required
                placeholder="100"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[price][retail][currency]"
              >
                {currenyOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t("manual_product_add.modal_unit_price_invalid_feedback")}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_number_of_wholesale_retail")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="retail[quantity]"
                required
                placeholder="5"
              />
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_retail_number_of_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_retail_price")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control w-50"
                type="text"
                name="retail[price][wholesale][value]"
                required
                placeholder="500"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[price][wholesale][currency]"
              >
                {currenyOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {t("manual_product_add.modal_retail_price_invalid_feedback")}
              </div>
            </div>
          </div>

          <h6 className="mt-4 mb-0">
            <i class="fa-duotone fa-arrow-progress me-2"></i>
            <b> {t("manual_product_add.modal_shipping_preferences")} </b>
          </h6>

          <span className="my-3">
            {" "}
            {t("manual_product_add.modal_net_product_size_info")}{" "}
          </span>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_length")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][net][height][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][net][height][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_length_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_width")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][net][width][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][net][width][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_width_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_depth")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][net][depth][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][net][depth][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_depth_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <span className="my-3">
            {" "}
            {t("manual_product_add.modal_brute_product_size_packed")}{" "}
          </span>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_length")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][gross][height][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][gross][height][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_length_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_width")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][gross][width][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][gross][width][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_width_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_depth")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][gross][depth][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][gross][depth][unit]"
              >
                {sizeUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {t("manual_product_add.modal_product_depth_invalid_feedback")}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3 mb-3">
            <div className="info-lines">
              <p className="m-0">
                * {t("manual_product_add.modal_net_product_size_info")}
              </p>
              <p className="m-0">
                * {t("manual_product_add.modal_brute_product_size_info")}
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_net_weight_unpacked")}{" "}
            </label>
            <label className="d-block mb-2">
              {" "}
              {t("manual_product_add.modal_product_weight")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][net][weight][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][net][weight][unit]"
              >
                {weightUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t("manual_product_add.modal_net_weight_invalid_feedback")}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_brute_weight_packed")}{" "}
            </label>
            <label className="d-block mb-2">
              {" "}
              {t("manual_product_add.modal_product_weight")}
            </label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="retail[transportation][gross][weight][value]"
                required
                placeholder="10"
              />
              <select
                className="form-select w-fit bg-subtle br-6"
                name="retail[transportation][gross][weight][unit]"
              >
                {weightUnitsOptionsForSelect()}
              </select>
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_brute_weight_invalid_feedback"
                )}{" "}
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <label className="mb-1">
              {" "}
              {t("manual_product_add.modal_product_packaging_type")}{" "}
            </label>
            <div className="input-group">
              <input
                className="form-control br-6"
                type="text"
                name="retail[packaging_type]"
                required
                placeholder="Palet"
              />
              <div class="invalid-feedback">
                {" "}
                {t(
                  "manual_product_add.modal_product_packaging_type_invalid_feedback"
                )}{" "}
              </div>
            </div>
            <div className="form-text">
              {" "}
              {t(
                "manual_product_add.modal_product_packaging_type_placeholder"
              )}{" "}
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <div className="info-lines">
              <p className="m-0">
                * {t("manual_product_add.modal_net_product_size_info")}{" "}
              </p>
              <p className="m-0">
                * {t("manual_product_add.modal_brute_product_size_info")}
              </p>
              <p className="m-0">
                * {t("manual_product_add.modal_product_packaging_type_info")}
              </p>
            </div>
          </div>
        </form>
      </div>
    );
  }

  async function retailFormSubmit(setShow) {
    if (retailFormRef.current.checkValidity() === false) {
      retailFormRef.current.classList.add("was-validated");
      return false;
    }

    const retailFormData = new FormData(retailFormRef.current);
    const newData = retailFormData.entries().toArray();

    setRetail((prevData) => [...prevData, newData]);
    setShow(false);
  }

  function removeVariant(type, index) {
    switch (type) {
      case "wholesale":
        const newWholesale = wholesale.filter((d, i) => i !== index);
        setWholesale(newWholesale);
        break;
      case "retail":
        const newRetail = retail.filter((d, i) => i !== index);
        setRetail(newRetail);
        break;
      default:
        break;
    }
  }

  return (
    <SideBar>
      <SeoLayer
        title={t("seo_layer.supplier_manual_add.title")}
        description={t("seo_layer.supplier_manual_add.description")}
        endpoint="supplier/manual-product-add"
      />
      <div className="row mt-5">
        <h3 className="">
          {" "}
          {t("manual_product_add.manual_product_add_title")}{" "}
        </h3>

        {productForm()}

        <h3 className="my-5">
          {t("manual_product_add.pricing_shipping_delivery_options")}
        </h3>

        <div className="col-lg-12 variantTool blue mt-3 mb-5">
          <h4 className="variantTool-title mb-4">
            {" "}
            {t("manual_product_add.retail")}{" "}
          </h4>
          <p className="variantTool-info text-secondary mb-4">
            <i className="fa-solid fa-circle-info me-2"></i>{" "}
            {t("manual_product_add.variant_create_info")}
          </p>

          <div id="variantRetail" className="variantTool-wrapper">
            <span className="m-0">{t("manual_product_add.detail_info")}</span>
            <AsyncModal
              modalSize="lg"
              modalTitleText={t(
                "manual_product_add.modal_retail_variant_detail_title"
              )}
              modalCloseButtonText={
                <span>
                  <i className="fa-solid fa-times me-2"></i>{" "}
                  {t("manual_product_add.give_up")}
                </span>
              }
              modalSaveButtonText={
                <span>
                  <i className="fa-solid fa-plus me-2"></i>{" "}
                  {t("manual_product_add.save_and_add")}
                </span>
              }
              lunchButtonText={
                <span className="variantWholesaleNew variantTool-addButton">
                  {t("manual_product_add.detail")}{" "}
                  <i class="fa-solid fa-square-plus ms-2"></i>
                </span>
              }
              process={retailFormSubmit}
              formId="formRetail"
            >
              {modalBodyRetail()}
            </AsyncModal>
            <div className="d-none variantTool-addButtonWrapper">
              <button className="variantRetailNew variantTool-addButton">
                <i className="fa-solid fa-plus"></i> {/* Varyant ekle */}
                {t("manual_product_add.variant_add")}
              </button>
            </div>
          </div>

          <h6 className="my-4">
            {/* Mevcut perakende varyantları */}{" "}
            {t("manual_product_add.available_retail_variants")}{" "}
          </h6>
          {retail.length === 0 && (
            <span className="text-secondary m-0">
              {/* Perakende varyantı eklemediniz */}
              {t("manual_product_add.no_retail_variants")}
            </span>
          )}

          <div className="variantItemList mt-4">
            {retail.length > 0 &&
              retail.map((d, i) => (
                <div className="variantItem">
                  <p className="variantItem-title m-0">
                    <b>
                      {" "}
                      {t("manual_product_add.variant")} {i + 1}:
                    </b>{" "}
                    {d[0][1]}
                  </p>
                  <span
                    className="variantItem-remove ms-3 text-danger"
                    onClick={() => removeVariant("retail", i)}
                  >
                    <i className="fa-solid fa-times"></i> {/* Varyant sil */}
                    {t("manual_product_add.remove")}
                  </span>
                </div>
              ))}
          </div>
        </div>

        <div className="col-lg-12 variantTool orange mb-5">
          <h4 className="variantTool-title mb-4">
            {" "}
            {t("manual_product_add.wholesale")}{" "}
          </h4>

          <p className="variantTool-info text-secondary mb-4">
            <i className="fa-solid fa-circle-info me-2"></i>
            {/* Eklediğiniz ürün
            için satış adedine göre varyant oluşturarak detaylı fiyatlandırma
            yapabilirsiniz */}
            {t("manual_product_add.variant_create_info")}
          </p>

          <div id="variantWholesale" className="variantTool-wrapper">
            <span className="m-0">
              {/*  Bilgileri girmek için "Detaylandır" seçeneğini kullanın */}
              {t("manual_product_add.detail_info")}
            </span>
            <AsyncModal
              modalSize="lg"
              modalTitleText={t(
                "manual_product_add.modal_wholesale_variant_detail_title"
              )}
              modalCloseButtonText={
                <span>
                  <i className="fa-solid fa-times me-2"></i>
                  {/* Vazgeç */}
                  {t("manual_product_add.give_up")}
                </span>
              }
              modalSaveButtonText={
                <span>
                  <i className="fa-solid fa-plus me-2"></i>
                  {/* Kaydet ve ekle */}
                  {t("manual_product_add.save_and_add")}
                </span>
              }
              lunchButtonText={
                <span className="variantWholesaleNew variantTool-addButton">
                  {/* Detaylandır */} {t("manual_product_add.detail")}{" "}
                  <i class="fa-solid fa-square-plus ms-2"></i>
                </span>
              }
              process={wholesaleFormSubmit}
              formId="formWholesale"
            >
              {modalBodyWholesale()}
            </AsyncModal>
            <div className="d-none variantTool-addButtonWrapper">
              <button className="variantWholesaleNew variantTool-addButton">
                <i className="fa-solid fa-plus me-2"></i>
                {/* Varyant ekle */}
                {t("manual_product_add.variant_add")}
              </button>
            </div>
          </div>

          <h6 className="my-4">
            {/* Mevcut toptan varyantları */}
            {t("manual_product_add.available_wholesale_variants")}
          </h6>
          {wholesale.length === 0 && (
            <span className="text-secondary m-0">
              {/* Toptan varyantı eklemediniz */}
              {t("manual_product_add.no_wholesale_variants")}
            </span>
          )}

          <div className="variantItemList mt-4">
            {wholesale.length > 0 &&
              wholesale.map((d, i) => (
                <div className="variantItem">
                  <p className="variantItem-title m-0">
                    <b>
                      {/* Varyant */} {t("manual_product_add.variant")} {i + 1}:
                    </b>{" "}
                    {d[0][1]}
                  </p>
                  <span
                    className="variantItem-remove ms-3 text-danger"
                    onClick={() => removeVariant("wholesale", i)}
                  >
                    <i className="fa-solid fa-times"></i> {/* Kaldır */}
                    {t("manual_product_add.remove")}
                  </span>
                </div>
              ))}
          </div>
        </div>

        <div className="col-lg-12">
          <button
            className={"w-100 btn btn-" + (isLoading ? "secondary" : "primary")}
            onClick={productFormSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              t("manual_product_add.please_waiting")
            ) : (
              <>
                <i class="fa-solid fa-plus me-2"></i>
                {/* Ürünü ekle */}
                {t("manual_product_add.product_add")}
              </>
            )}
          </button>
          <div class="form-text mt-3">
            * {t("manual_product_add.product_create_before_info")}{" "}
            {/* Ürünün eklenmesi sırasında seçeceğiniz görsellerin boyutuna bağlı
            olarak işlem süresi uzayabileceğinden dolayı, işlem tamamlanana
            kadar sabırla beklemelisiniz. */}
          </div>
        </div>
      </div>
    </SideBar>
  );
}
