import React, { useRef } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";

import "./quotationsOfRequest.css";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { useTranslation } from "react-i18next";

const manufacturerList = [
  {
    id: 1,
    title: "GENUINE - Original",
    value: "original",
  },
  {
    id: 4,
    title: "OEM - Orjinal ekipman üreticisi",
    value: "oem",
  },
  {
    id: 3,
    title: "AFTERMARKET - Yan Sanayi",
    value: "aftermarket",
  },
  {
    id: 6,
    title: "NO NAME - İsimsiz",
    value: "noName",
  },
];

const conditionList = [
  {
    id: 1,
    title: "NE - NEW",
    value: "ne",
  },
  {
    id: 2,
    title: "RD - REPAIRED - (Tamir edilmiş)",
    value: "rd",
  },
  {
    id: 3,
    title: "EX - EXCHANGED - (Eskisi ile değiştirildi)",
    value: "rd",
  },
  {
    id: 4,
    title: "OH - OVERHAULED - (Yenilendi)",
    value: "oh",
  },
  {
    id: 5,
    title: "RB - REBUILT - (Yeniden yapıldı)",
    value: "rb",
  },
  {
    id: 6,
    title: "FN - FACTORY NEW - (Üreticiden)",
    value: "fn",
  },
  {
    id: 7,
    title: "IN - DENETLENDİ",
    value: "rb",
  },
  {
    id: 8,
    title: "MO - MODIFIED - (Modifiye edildi)",
    value: "mo",
  },
  {
    id: 9,
    title: "SV - SERVICEABLE - (Bakım yapılmalı)",
    value: "sv",
  },
  {
    id: 10,
    title: "CR - CORE - (Dönüştürülen parça)",
    value: "cr",
  },
  {
    id: 11,
    title: "TST - TESTED - (Test edildi)",
    value: "tst",
  },
  {
    id: 12,
    title: "RP - REPAIRABLE - (Tamir edilebilir)",
    value: "rp",
  },
  {
    id: 13,
    title: "BER - BEYOND ECONOMICAL REPAIR - (Ekonomik onarım)",
    value: "ber",
  },
  {
    id: 14,
    title: "NS - NEW SURPLUS - (Üretim fazlası)",
    value: "ns",
  },
  {
    id: 15,
    title: "AR - AS REMOVED - (Üretimden kaldırıldı)",
    value: "ar",
  },
  {
    id: 16,
    title: "USED - İkinci el - Kullanılmış",
    value: "used",
  },
  {
    id: 17,
    title: "OR - ON REQUEST - (Talep üzerine)",
    value: "or",
  },
  {
    id: 5,
    title: "TK - TAKE OFF - Çıkma parça",
    value: "tk",
  },
];

export default function QuotationsOfRequest(props) {
  const { t } = useTranslation();
  const formRef = useRef();

  const handleProcess = async () => {
    if (formRef.current.checkValidity() === false) {
      //formRef.current.classList.add('was-validated');
      return false;
    }

    const formData = new FormData(formRef.current);

    for (let input of formData) {
      console.log("input", input);
    }

    const response = await new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log("formData", formData);
        resolve(true);
      }, 1000);
    });
  };

  useDidMountEffect(() => {
    const lunchButtonEl = document.getElementById("lunchAddNewQuotation");
    const eventName = "keyup";
    const eventFunc = (e) => {
      if (e.key === "Enter") {
        lunchButtonEl.click();
      }
    };
    window.addEventListener(eventName, eventFunc);
    return () => window.removeEventListener(eventName, eventFunc);
  }, []);

  function modalBody() {
    return (
      <form
        ref={formRef}
        id="addNewQuotation"
        className="row was-validated"
        noValidate
      >
        <div className="col-lg-12">
          <p className="alert alert-info">
            {/* Formu olabildiğince eksiksiz bir şekilde doldurmaya özen gösteriniz.
            Ön kontrollerimiz yapay zeka aracılığı ile yapılmaktadır ve
            sunacağınız bilgilerin doğruluğu çok önemlidir. Aksi takdirde
            talebiniz reddedilebilir. */}
            {t("quotations.info_1")}
          </p>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            {/* Ürün ile ilgili bir belge veya fotoğraf seçin */}
            {t("quotations.select_docs_1")}
          </label>
          <input
            className="buyrun-form-control form-control"
            type="file"
            name="file"
            required={true}
          />
          <div class="invalid-feedback">
            {/* Please choose a document or photo about product */}
            {t("quotations.invalid_feedback_1")}
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1"> {t("quotations.product_name")} </label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="title"
            placeholder="Ürün başlığı"
            required={true}
          />
          <div class="invalid-feedback">
            {/*  Please type a caption for quotation of request */}
            {t("quotations.invalid_feedback_2")}
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            {/* Parça No (Ürün Numarası) */} {t("quotations.product_number")}{" "}
          </label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="partNo"
            placeholder="Parça No"
            required={true}
          />
          <div class="invalid-feedback">
            {/*  Please type a caption for quotation of request */}
            {t("quotations.invalid_feedback_3")}
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            {/* Ürün açıklaması */} {t("quotations.description")}
          </label>
          <textarea
            rows={5}
            className="buyrun-form-control form-control rounded-4"
            type="text"
            name="description"
            placeholder={t("quotations.description_placeholder")}
            required={true}
            style={{ resize: "none" }}
          />
          <div class="invalid-feedback">
            {/*  Please provide more knowledge easy to find your product of request */}
            {t("quotations.invalid_feedback_4")}
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1"> {t("quotations.brand")} </label>
          <select
            className="buyrun-form-control form-select"
            name="country"
            required={true}
          >
            <option value="">
              {/* Marka seç */} {t("quotations.select_brand")}{" "}
            </option>
            <option value="Caterpillar">Caterpillar</option>
          </select>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1"> {t("quotations.model")} </label>
          <select
            className="buyrun-form-control form-select"
            name="city"
            required={true}
          >
            <option value="">{t("quotations.select_model")}</option>
            <option value="CA1527375">CA1527375</option>
          </select>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1"> {t("quotations.year")} </label>
          <select
            className="buyrun-form-control form-select"
            name="productionYear"
          >
            <option value="">{t("quotations.select_year")}</option>
            <option value="2024">2024</option>
          </select>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            {/* Menşei (Üretim Yeri) */} {t("quotations.origin")}{" "}
          </label>
          <select
            className="buyrun-form-control form-select"
            name="countryOfOrigin"
          >
            <option value=""> {t("quotations.select_origin")} </option>
            <option value="ABD">ABD</option>
          </select>
        </div>

        <div className="col-lg-6 mb-3">
          <label className="mb-1"> {t("quotations.piece")} </label>
          <input
            className="buyrun-form-control form-control"
            type="number"
            name="quantity"
            required={true}
            defaultValue="1"
            min={1}
            minLength={1}
          />
        </div>

        <div className="col-lg-12">
          <label className="mb-1">
            {/* Ürün durumu */} {t("quotations.condition")}{" "}
          </label>
          <div className="manufacturerList mb-3">
            <h5 className="mb-1">
              {/* Manufacturer */} {t("quotations.manufacturer")}{" "}
            </h5>
            <div className="row manufacturerListItems">
              {manufacturerList.map((item) => {
                const id = "manufacturerItem" + item.id;
                return (
                  <div className="manufacturerListItem col-lg-4">
                    <input
                      id={id}
                      className="form-check-input"
                      type="radio"
                      name="manufacturer"
                      value={item.value}
                      required={true}
                    />
                    <label for={id} className="ms-2" title={item.title}>
                      {item.title}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="conditionList">
            <h5 className="mb-1">
              {/* Condition */} {t("quotations.condition")}
            </h5>
            <div className="row conditionListItems">
              {conditionList.map((item) => {
                const id = "conditionItem" + item.id;
                return (
                  <div className="conditionListItem col-lg-4">
                    <input
                      id={id}
                      className="form-check-input"
                      type="radio"
                      name="condition"
                      value={item.value}
                      required={true}
                    />
                    <label for={id} className="ms-2" title={item.title}>
                      {item.title}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    );
  }

  return (
    <AsyncModal
      modalSize="xl"
      modalTitleText={t("quotations.create")}
      modalCloseButtonText={
        <span>
          <i className="fa-solid fa-times"></i> {/* Vazgeç */}{" "}
          {t("quotations.cancel")}
        </span>
      }
      modalSaveButtonText={
        <span>
          <i className="fa-solid fa-plus"></i> {/* Talep Gönder */}{" "}
          {t("quotations.send")}
        </span>
      }
      lunchButtonId="lunchAddNewQuotation"
      lunchButtonText={t("quotations.new_create")}
      process={handleProcess}
      formId="addNewQuotation"
    >
      {modalBody()}
    </AsyncModal>
  );
}
