import { useReducer, useRef, useState } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";
import { add } from "../../context/feature/PaymentMethods/paymentMethodsSlice";
import { useDispatch } from "react-redux";

export default function AddressModal() {
  const formRef = useRef();
  const cardTypeImgRef = useRef("");

  const dispatch = useDispatch();

  const handleProcess = async () => {
    if (formRef.current.checkValidity() === false) {
      //formRef.current.classList.add('was-validated');
      return false;
    }

    const formData = new FormData(formRef.current);

    for (let input of formData) {
      console.log("input", input);
    }

    const response = await new Promise((resolve, reject) => {
      setTimeout(() => {
        const id = parseInt(Math.random() * 999999999) + 1;
        dispatch(
          add({
            id: id,
            title: formData.get("title"),
            cardType: getCardType(formData.get("cardNumber")),
            cardNumber: formData.get("cardNumber"),
            cardHolder: formData.get("cardHolder"),
            cardMonth: formData.get("cardMonth"),
            cardYear: formData.get("cardYear"),
            isDefault: formData.get("isDefault") === "on",
          })
        );
        resolve(true);
      }, 1000);
    });
  };

  const getCardType = (input) => {
    let cardType = "";
    if (/^(300|30[1-5]|36|38)/.test(input)) {
      cardType = "Diners Club";
    } else if (/^(2131|1800)/.test(input)) {
      cardType = "JCB";
    } else if (/^6011/.test(input)) {
      cardType = "Discover";
    } else if (/^5/.test(input)) {
      cardType = "Mastercard";
    } else if (/^4/.test(input)) {
      cardType = "Visa";
    } else if (/^3[47]/.test(input)) {
      cardType = "American Express";
    }

    if (cardType.length === 0) {
      cardTypeImgRef.current.style.display = "none";
    } else {
      cardTypeImgRef.current.style.display = "inline-block";
      cardTypeImgRef.current.src = "/assets/img/" + cardType + ".svg";
    }

    return cardType;
  };

  const handleCardNumberChange = (e) => {
    let input = e.target.value.replace(/\D/g, ""); // Sadece rakamları alır
    let formattedInput = "";
    let cardType = getCardType(input);

    // Kredi kartı numarasını biçimlendir
    switch (cardType) {
      case "Visa":
        formattedInput = input.replace(
          /^(\d{4})(\d{0,4})(\d{0,4})(\d{0,3}).*/,
          "$1 $2 $3 $4"
        );
        break;
      case "Mastercard":
      case "Discover":
        formattedInput = input.replace(
          /^(\d{4})(\d{0,4})(\d{0,4})(\d{0,4}).*/,
          "$1 $2 $3 $4"
        );
        break;
      case "American Express":
      case "JCB":
        formattedInput = input.replace(
          /^(\d{4})(\d{0,6})(\d{0,5}).*/,
          "$1 $2 $3"
        );
        break;
      case "Diners Club":
        formattedInput = input.replace(
          /^(\d{4})(\d{0,6})(\d{0,4}).*/,
          "$1 $2 $3"
        );
        break;
      default:
        formattedInput = input.replace(
          /^(\d{4})(\d{0,4})(\d{0,4})(\d{0,4}).*/,
          "$1 $2 $3 $4"
        );
    }

    e.target.value = formattedInput;
  };

  function modalBody() {
    return (
      <form ref={formRef} id="addNewCreditCard" className="row was-validated">
        <div className="col-lg-12 mb-3">
          <label className="mb-1">Başlık</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="title"
            placeholder="Genel Kullanım"
            required={true}
            defaultValue="Genel Kullanım"
          />
          <div class="invalid-feedback">Please type a caption for address.</div>
        </div>
        <div className="col-lg-12 mb-3">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <label className="mb-1">Kart Numarası</label>
            <img
              ref={cardTypeImgRef}
              className="float-end me-2"
              src=""
              width={64}
            />
          </div>
          <input
            className="buyrun-form-control form-control is-invalid"
            type="text"
            name="cardNumber"
            placeholder="XXXX-XXXX-XXXX-XXXX"
            onChange={handleCardNumberChange}
            required={true}
            defaultValue="5549 6079 9700 6644"
          />
        </div>
        <div className="col-lg-12 mb-3">
          <label className="mb-1">Kart Üzerindeki Adınız</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="cardHolder"
            placeholder="Ali Işık Satıcı"
            pattern=".+\s+(.+\s+)?.+"
            required={true}
            defaultValue="erhan sönmez"
          />
          <div class="invalid-feedback">
            Please type fullname as First Middle Last Name
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">Ay</label>
          <input
            className="buyrun-form-control form-control"
            type="number"
            minLength={2}
            maxLength={2}
            min={1}
            name="cardMonth"
            placeholder="01"
            required={true}
            defaultValue="04"
          />
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">Yıl</label>
          <input
            className="buyrun-form-control form-control"
            type="number"
            minLength={4}
            maxLength={4}
            min={2024}
            name="cardYear"
            placeholder="2028"
            required={true}
            defaultValue="2028"
          />
        </div>
        <div className="d-flex">
          <input
            className="form-check-input"
            type="checkbox"
            name="isDefault"
            defaultChecked={true}
          />
          <label className="form-check-label ms-2">
            Bunu varsayılan ödeme yöntemi olarak kaydet
          </label>
        </div>
      </form>
    );
  }

  return (
    <AsyncModal
      modalSize="s"
      modalTitleText="Yeni bir kart ekle"
      modalCloseButtonText={<span><i className="fa-solid fa-times"></i> Vazgeç</span>}
      modalSaveButtonText={<span><i className="fa-solid fa-plus"></i> Kaydet</span>}
      lunchButtonText={<span><i className="fa-solid fa-plus"></i> Yeni bir kart ekle</span>}
      process={handleProcess}
    >
      {modalBody()}
    </AsyncModal>
  );
}
