import React, { forwardRef, useEffect, useRef, useState } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";

import "./passwordMeter.css";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { useTranslation } from "react-i18next";

const defaultPassword = 0;
const defaultPasswordColor = "red";

const defaultPasswordUsage = {
  lower: false,
  upper: false,
  number: false,
  special: false,
};

export default function PasswordMeter(props) {
  const { t } = useTranslation();

  const [passwordStrong, setPasswordStrong] = useState(defaultPassword);
  const [passwordStrongText, setPasswordStrongText] = useState(
    t("password_meter_component.weak")
  );
  const [passwordStrongColor, setPasswordStrongColor] =
    useState(defaultPasswordColor);
  const [passwordUsage, setPasswordUsage] = useState(defaultPasswordUsage);

  useEffect(() => {
    const handleMeasure = () => {
      let strongValue = defaultPassword;
      let strongValueText = t("password_meter_component.weak");
      let strongValueColor = defaultPasswordColor;

      setPasswordUsage(defaultPasswordUsage);

      if (/[a-z]/.test(props.value)) {
        strongValue++;
        setPasswordUsage((prevUsage) => ({ ...prevUsage, lower: true }));
      }
      if (/[A-Z]/.test(props.value)) {
        strongValue++;
        setPasswordUsage((prevUsage) => ({ ...prevUsage, upper: true }));
      }
      if (/[0-9]/.test(props.value)) {
        strongValue++;
        setPasswordUsage((prevUsage) => ({ ...prevUsage, number: true }));
      }
      if (/\W/.test(props.value)) {
        strongValue++;
        setPasswordUsage((prevUsage) => ({ ...prevUsage, special: true }));
      }

      switch (strongValue) {
        case 1:
          strongValueText = <i class="fa-duotone fa-face-disappointed" style={{ fontSize: 24, color: "red" }}></i>;
          strongValueColor = "red";
          break;
        case 2:
          strongValueText = <i class="fa-duotone fa-face-frown-slight" style={{ fontSize: 24, color: "orange" }}></i>;
          strongValueColor = "orange";
          break;
        case 3:
          strongValueText = <i class="fa-duotone fa-face-smile" style={{ fontSize: 24, color: "lime" }}></i>;
          strongValueColor = "lime";
          break;
        case 4:
          strongValueText = <i class="fa-duotone fa-face-party" style={{ fontSize: 24, color: "green" }}></i>;
          strongValueColor = "green";
          break;
        default:
          strongValueText = t("password_meter_component.weak");
          strongValueColor = "red";
          break;
      }

      setPasswordStrong(strongValue);
      setPasswordStrongText(strongValueText);
      setPasswordStrongColor(strongValueColor);
    };

    handleMeasure();
  }, [props.value, t]);

  return (
    <div className={props.className} style={props.style}>
      {/* <small>Password strong meter</small> */}
      <div className="passwordStrongMeter">
        <div
          className="passwordStrongMeter-bar"
          style={{ width: passwordStrong * 25 + "%" }}
        >
          <span
            className="passwordStrongMeter-barHandle"
            style={{
              backgroundColor: passwordStrongColor,
            }}
          ></span>
          {passwordStrong > 0 && (
            <span className="passwordStrongMeter-indicator">
              <b className="passwordStrongMeter-indicator-text">
                {passwordStrongText}
              </b>
            </span>
          )}
        </div>
      </div>
      <ul className="ps-3 mt-3">
        <li
          className={
            "text-" + (passwordUsage.lower === true ? "success" : "danger")
          }
        >
          {t("password_meter_component.lower")}
        </li>
        <li
          className={
            "text-" + (passwordUsage.upper === true ? "success" : "danger")
          }
        >
          {t("password_meter_component.upper")}
        </li>
        <li
          className={
            "text-" + (passwordUsage.number === true ? "success" : "danger")
          }
        >
          {t("password_meter_component.number")}
        </li>
        <li
          className={
            "text-" + (passwordUsage.special === true ? "success" : "danger")
          }
        >
          {t("password_meter_component.special")}
        </li>
      </ul>
    </div>
  );
}
