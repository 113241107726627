// currencySlice.js
import { createSlice } from "@reduxjs/toolkit";

export const availableCurrencies = [
  {
    language: "Türkçe",
    countryCode: "tr-TR",
    currency: "TRY",
    shortCode: "tr",
  },
  {
    language: "English",
    countryCode: "en-US",
    currency: "USD",
    shortCode: "en",
  },
  {
    language: "中文",
    countryCode: "zh-CN",
    currency: "CNY",
    shortCode: "zh",
  },
  {
    language: "Русский",
    countryCode: "ru-RU",
    currency: "RUB",
    shortCode: "ru",
  },
  {
    language: "Español",
    countryCode: "es-ES",
    currency: "EUR",
    shortCode: "es",
  },
  {
    language: "اللغة العربية",
    countryCode: "ar-SA",
    currency: "SAR",
    shortCode: "ar",
  },
];

const initialState = {
  language: "English",
  countryCode: "en-US",
  currency: "USD",
  shortCode: "en",
  default: true
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    changeFormat: (state, action) => {
      const currencyObject = availableCurrencies.find((e) => e.countryCode === action.payload.countryCode);
      state.language = currencyObject.language;
      state.countryCode = currencyObject.countryCode;
      state.currency = currencyObject.currency;
      state.shortCode = currencyObject.shortCode;
      state.default = false;
    },
  },
});

export const { changeFormat } = currencySlice.actions;

export default currencySlice.reducer;
