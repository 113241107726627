
import SideBar from '../SideBar';

import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import SeoLayer from '../SeoLayer/SeoLayer';

// https://documenter.getpostman.com/view/34333569/2sA3JQ3z4e

function ApiIntegration() {

  const {t} = useTranslation();

  return (
    <SideBar>
      <SeoLayer
        title={t("seo_layer.supplier_api_integration.title")}
        description={t("seo_layer.supplier_api_integration.description")}
        endpoint="supplier/api-integration"
      />
      <h1>{t("api_integration.introduction.t1")}</h1>
      <p>{t("api_integration.introduction.t1p1")}</p>

      <h2 className="mt-5">1. {t("api_integration.s1.t1")}</h2>
      <p>{t("api_integration.s1.t1p1")}</p>
      <p>{t("api_integration.s1.t1p2")} <Link to="/supplier/api-key-generator">https://buy.run/supplier/api-key-generator</Link></p>
      <p>{t("api_integration.s1.t1p3")}</p>

      <h2 className="mt-5">2. {t("api_integration.s2.t1")}</h2>
      <p>{t("api_integration.s2.t1p1")}</p>
      <p>{t("api_integration.s2.t1p2")}</p>
      <p>{t("api_integration.s2.t1p3")}</p>

      <h2 className="mt-5">3. {t("api_integration.s3.t1")}</h2>
      <p>{t("api_integration.s3.t1p1")}</p>
      <p>{t("api_integration.s3.t1p2")}</p>
      <p>{t("api_integration.s3.t1p3")}https://buy.run/api/v1/buyrun/products/create</p>

      <h2 className="mt-5">4. {t("api_integration.s4.t1")}</h2>
      <p>{t("api_integration.s4.t1p1")}</p>
      <div className="border p-4 rounded shadow">
        <p>{t("api_integration.s4.t1p2")}</p>
        <ul>
          <li>
          {t("api_integration.s4.l1p1")}
            <ul>
              <li>TRY</li>
              <li>USD</li>
              <li>CNY</li>
              <li>RUB</li>
              <li>EUR</li>
              <li>SAR</li>
            </ul>
          </li>
          <li>
            {t("api_integration.s4.l1p3")}
            <ul>
              <li>CM</li>
              <li>MM</li>
              <li>M</li>
              <li>INCH</li>
            </ul>
          </li>
          <li>
            {t("api_integration.s4.l1p4")}
            <ul>
              <li>KG</li>
              <li>GR</li>
              <li>LB</li>
              <li>OZ</li>
              <li>CARAT</li>
              <li>TON</li>
            </ul>
          </li>
          <li>
            {t("api_integration.s4.l1p5")}
            <ul>
              <li>.jpg - image/jpeg</li>
              <li>.jpg - image/jpg</li>
              <li>.png - image/png</li>
              <li>.mp4 - video/mp4</li>
            </ul>
          </li>
          <li>{t("api_integration.s4.l2p1")}</li>
          <li>{t("api_integration.s4.l2p2")}</li>
          <li>{t("api_integration.s4.l2p3")}</li>
          <li>{t("api_integration.s4.l2p4")}</li>
        </ul>
      </div>

      <div className="border p-4 rounded shadow mt-3">
        <p className="alert alert-info">{t("api_integration.s5.info")}</p>
        <h3>{t("api_integration.s5.t1")}</h3>
        <p>{t("api_integration.s5.t1p1")}</p>
        <ul>
          <li>original - {t("manual_product_add.GENUINE")}</li>
          <li>oem - {t("manual_product_add.OEM")}</li>
          <li>aftermarket - {t("manual_product_add.AFTERMARKET")}</li>
          <li>noName - {t("manual_product_add.NO_NAME")}</li>
        </ul>
      </div>

      <div className="border p-4 rounded shadow mt-3">
        <p className="alert alert-info">{t("api_integration.s6.info")}</p>
        <h3>{t("api_integration.s6.t1")}</h3>
        <p>{t("api_integration.s6.t1p1")}</p>
        <ul>
          <li>ne - {t("manual_product_add.ne")}</li>
          <li>rd - {t("manual_product_add.rd")}</li>
          <li>ex - {t("manual_product_add.ex")}</li>
          <li>oh - {t("manual_product_add.oh")}</li>
          <li>rb - {t("manual_product_add.rb")}</li>
          <li>fn - {t("manual_product_add.fn")}</li>
          <li>in - {t("manual_product_add.in")}</li>
          <li>mo - {t("manual_product_add.mo")}</li>
          <li>sv - {t("manual_product_add.sv")}</li>
          <li>cr - {t("manual_product_add.cr")}</li>
          <li>tst - {t("manual_product_add.tst")}</li>
          <li>rp - {t("manual_product_add.rp")}</li>
          <li>ber - {t("manual_product_add.ber")}</li>
          <li>ns - {t("manual_product_add.ns")}</li>
          <li>ar - {t("manual_product_add.ar")}</li>
          <li>used - {t("manual_product_add.used")}</li>
          <li>or - {t("manual_product_add.or")}</li>
          <li>tk - {t("manual_product_add.tk")}</li>
        </ul>
      </div>

      <h2 className="mt-5">5. {t("api_integration.s7.t1")}</h2>
      <p>{t("api_integration.s7.t1p1")}</p>
      
      <h3>{t("api_integration.s7.t2")}</h3>
      <p>{t("api_integration.s7.t2p1")}</p>

      <h3>{t("api_integration.s7.t3")}</h3>
      <p>{t("api_integration.s7.t3p1")}</p>

      <h3>{t("api_integration.s7.t4")}</h3>
      <p>{t("api_integration.s7.t4p1")}</p>

      <div className="border p-4 rounded shadow mt-5">
        <h1>{t("api_integration.s8.t1")}</h1>
        <p>{t("api_integration.s8.t1p1")}</p>
        <Link class="w-100 btn btn-primary" to="https://documenter.getpostman.com/view/34333569/2sA3JQ3z4e" target='_blank'>{t("api_integration.s8.link")}</Link>
      </div>
    </SideBar>
  )
}

export default ApiIntegration;