import { useReducer, useRef, useState } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";
import { add } from "../../context/feature/Address/addressSlice";
import { useDispatch } from "react-redux";

export default function AddressModal() {
  const formRef = useRef();
  const dispatch = useDispatch();

  const handleProcess = async () => {
    if (formRef.current.checkValidity() === false) {
      //formRef.current.classList.add('was-validated');
      return false;
    }

    const formData = new FormData(formRef.current);

    for (let input of formData) {
      console.log("input", input);
    }

    const response = await new Promise((resolve, reject) => {
      setTimeout(() => {
        const id = parseInt(Math.random() * 999999999) + 1;
        dispatch(
          add({
            id: id,
            title: formData.get("title"),
            fullname: formData.get("fullname"),
            phone: formData.get("phone"),
            country: formData.get("country"),
            city: formData.get("city"),
            district: formData.get("district"),
            neighborhood: formData.get("neighborhood"),
            zip: formData.get("zip"),
            address: formData.get("address"),
            isDefault: formData.get("isDefaultAddress") === "on",
            isDefaultInvoice: formData.get("isDefaultInvoiceAddress") === "on",
          })
        );
        resolve(true);
      }, 1000);
    });
  };

  function modalBody() {
    return (
      <form
        ref={formRef}
        id="addNewAddress"
        className="row was-validated"
        noValidate
      >
        <div className="col-lg-12 mb-3">
          <label className="mb-1">Adres Başlığı</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="title"
            placeholder="Ev Adresi"
            required={true}
            defaultValue="ev adresi"
          />
          <div class="invalid-feedback">Please type a caption for address.</div>
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">Tam Ad</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="fullname"
            placeholder="Ali Işık Satılmış"
            pattern=".+\s+(.+\s+)?.+"
            required={true}
            defaultValue="erhan sönmez"
          />
          <div class="invalid-feedback">
            Please type fullname as First Middle Last Name
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">Telefon Numarası</label>
          <input
            className="buyrun-form-control form-control"
            type="tel"
            name="phone"
            placeholder="XXX XXX XXXX..."
            pattern="[0-9]{1,3} [0-9]{3} [0-9]{4,}"
            required={true}
            defaultValue="90 537 8597269"
          />
          <div class="invalid-feedback">
            Please type phone number as +XXX-XXX-XXXX... format
          </div>
        </div>
        <div className="col-lg-12 mb-3">
          <label className="mb-1">Ülke</label>
          <select className="buyrun-form-control form-select" name="country" required={true}>
            <option value="">Ülke seç</option>
            <option value="Türkiye" selected>
              Türkiye
            </option>
          </select>
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">Şehir</label>
          <select className="buyrun-form-control form-select" name="city" required={true}>
            <option value="">Şehir seç</option>
            <option value="Bursa" selected>
              Bursa
            </option>
            <option value="İstanbul">İstanbul</option>
          </select>
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">İlçe</label>
          <select className="buyrun-form-control form-select" name="district" required={true}>
            <option value="">İlçe seç</option>
            <option value="Nilüfer" selected>
              Nilüfer
            </option>
            <option value="Yıldırım">Yıldırım</option>
            <option value="Bahçelievler">Bahçelievler</option>
          </select>
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">Mahalle / Köy</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="neighborhood"
            placeholder="Kurtuluş mah."
            required={true}
            defaultValue="Kurtuluş mah."
          />
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">Posta Kodu</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="zip"
            placeholder="16000"
            required={true}
            defaultValue="16285"
          />
        </div>
        <div className="col-lg-12 mb-3">
          <label className="mb-1">Adres</label>
          <textarea
            rows={2}
            className="buyrun-form-control form-control rounded-3"
            type="text"
            name="address"
            placeholder="Adres"
            required={true}
            style={{ resize: "none" }}
            defaultValue="Zübeyde Hanım cad. No:7/B Kat 1 Daire 4 - Loca 22 Sitesi"
          />
          <div class="invalid-feedback">
            Please provide some knowledge easy to find your exact location
          </div>
        </div>
        <div className="d-flex mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            name="isDefaultAddress"
            defaultChecked={true}
          />
          <label className="form-check-label ms-2">
            Bunu varsayılan adresim olarak kaydet
          </label>
        </div>
        <div className="d-flex">
          <input
            className="form-check-input"
            type="checkbox"
            name="isDefaultInvoiceAddress"
            defaultChecked={true}
          />
          <label className="form-check-label ms-2">
            Bu adres fatura adresi olarak kaydet
          </label>
        </div>
      </form>
    );
  }

  return (
    <AsyncModal
      modalSize="lg"
      modalTitleText="Yeni bir adres ekle"
      modalCloseButtonText={<span><i className="fa-solid fa-times"></i> Vazgeç</span>}
      modalSaveButtonText={<span><i className="fa-solid fa-plus"></i> Kaydet</span>}
      lunchButtonText={<span><i className="fa-solid fa-plus"></i> Yeni adres ekle</span>}
      process={handleProcess}
      formId="addNewAddress"
    >
      {modalBody()}
    </AsyncModal>
  );
}
