import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import CategoriesSideBar from "../CategoriesSideBar";
import ProductSellCard from "../ProductSellCard";
import HeadIconBar from "./HeadIconBar";
import HeadLinkBar from "./HeadLinkBar";
import Pagination from "../PagenationComp";
import ProductDetailsApiTest from "../ProductDetailsApiTest";

import {
  fetchProducts,
  filterProductsBySearchTerm,
  filterProductsByPriceRange,
  filterProductsByStars,
  filterProductsByDateRange,
  filterProductsBySpecificPriceRange,
  resetFilters,
  sortProductsByPriceAsc,
  sortProductsByPriceDesc,
} from "../../context/feature/Search/searchSlice";

const Categories = () => {
  const dispatch = useDispatch();
  const { filteredProducts, noProductsFound } = useSelector(
    (state) => state.search
  );

  /* States */

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const [pageNumberLimit, setPageNumberLimit] = useState(10);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /* Functions */

  // Filtering products by search term using Redux
  const handleSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim() === "") {
        dispatch(resetFilters());
      } else {
        dispatch(filterProductsBySearchTerm(searchTerm));
      }
      setCurrentPage(1);
    },
    [dispatch]
  );

  // Filtering products by price range using Redux
  const handlePriceRange = useCallback(
    (minPrice, maxPrice) => {
      dispatch(filterProductsByPriceRange({ minPrice, maxPrice }));
      setCurrentPage(1);
    },
    [dispatch]
  );

  // Filtering products by stars using Redux
  const handleStars = useCallback(
    (minStars) => {
      dispatch(filterProductsByStars(minStars));
      setCurrentPage(1);
    },
    [dispatch]
  );

  // Filtering products by specific price range using Redux
  const handleSpecificPriceRange = useCallback(
    (minPriceRange, maxPriceRange) => {
      dispatch(
        filterProductsBySpecificPriceRange({ minPriceRange, maxPriceRange })
      );
      setCurrentPage(1);
    },
    [dispatch]
  );

  // Filtering products by date range using Redux
  const handleDateRange = useCallback(
    (days) => {
      const currentDate = new Date();
      const pastDate = new Date(
        currentDate.setDate(currentDate.getDate() - days)
      );
      dispatch(
        filterProductsByDateRange({ startDate: pastDate, endDate: new Date() })
      );
      setCurrentPage(1);
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // Pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return (
    <>
      <div className="container">
        <ProductDetailsApiTest />
        <HeadIconBar />
      </div>
      <div
        style={{
          height: "2px",
          background: "#fff",
          boxShadow: "0px -5px 10px rgba(0, 0, 0, 0.9)",
        }}
      />
      <div className="container">
        <HeadLinkBar
          handlePriceRange={handlePriceRange}
          filterLast30Days={() => handleDateRange(30)}
          filterLast90Days={() => handleDateRange(90)}
          filterLast180Days={() => handleDateRange(180)}
          filterLast360Days={() => handleDateRange(360)}
          filterLast7Days={() => handleDateRange(7)}
          filterProductsByStars={handleStars}
          filterProductsBySpecificPriceRange={handleSpecificPriceRange}
          sortProductsByPriceAsc={() => dispatch(sortProductsByPriceAsc())}
          sortProductsByPriceDesc={() => dispatch(sortProductsByPriceDesc())}
          handleClose={handleClose}
        />
      </div>

      <CategoriesSideBar
        handlePriceRange={handlePriceRange}
        filterLast30Days={() => handleDateRange(30)}
        filterLast90Days={() => handleDateRange(90)}
        filterLast180Days={() => handleDateRange(180)}
        filterLast360Days={() => handleDateRange(360)}
        filterLast7Days={() => handleDateRange(7)}
        filterProductsByStars={handleStars}
        filterSpesificPriceRange={handleSpecificPriceRange}
        onSearch={handleSearch}
        show={show}
      >
        <div className="row" style={{ marginTop: "10px", overflowY: "hidden" }}>
          {noProductsFound ? (
            <div
              className="text-center"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "rgba(255, 80, 0, 1)",
              }}
            >
              Ürün bulunamadı{" "}
              <i className="fa-duotone fa-circle-exclamation"></i>
            </div>
          ) : (
            <>
              <div
                className="text-center"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#2ad352",
                }}
              >
                <i className="fa-duotone fa-square-check"></i>{" "}
                {filteredProducts.length} ürün gösteriliyor ...
              </div>
              {currentProducts.map((product) => (
                <ProductSellCard key={product.id} product={product} />
              ))}
            </>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
              handleNextbtn={handleNextbtn}
              handlePrevbtn={handlePrevbtn}
              maxPageNumberLimit={maxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              pageNumberLimit={pageNumberLimit}
            />
          </div>
        </div>
      </CategoriesSideBar>
    </>
  );
};

export default Categories;
