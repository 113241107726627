import { useState, useReducer, forwardRef } from "react";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { useDispatch } from "react-redux";
import { quantity } from "../../context/feature/Cart/cartSlice";

export const reducer = (state, action) => {
  switch (action.type) {
    case "increment":
      return { count: parseInt(state.count) + 1 };
    case "decrement":
      return { count: parseInt(state.count) - 1 };
    case "set":
      return { count: parseInt(action.value) };
    default:
      return state;
  }
};

const buttonStyles = {
  //fontFamily: "monospace",
  //fontSize: 21,
  lineHeight: "100%",
};

const IncrementalButton = forwardRef(function IncrementalButton(props, ref) {
  const minValue = props.min || 1;
  const [state, dispatch] = useReducer(reducer, {
    count: props.defaultValue || minValue,
  });

  const cartDispatch = useDispatch();

  useDidMountEffect(() => {
    console.log(props);
    if (props.inCart === false ){
      dispatch({ type: "set", value: props.defaultValue || minValue });
    }
  }, [props.inCart]);

  useDidMountEffect(() => {
    if (props.inCart){
      cartDispatch(quantity({ id:props.productId, quantity: state.count }));
    }
    if (props.onChange !== undefined){
      props.onChange(ref.current);
    }
  }, [state.count]);

  return (
    <div
      id={props.id}
      //onClick={props.onClick}
      className={"btn-group incremental-button-wrapper " + props.className}
      style={{
        boxShadow: "0 0 8px 0 #aaa",
      }}
    >
      <button
        disabled={!(state.count > minValue)}
        className={
          (state.count > minValue ? "btn-primary" : "btn-secondary") +
          " btn incremental-button incremental-button-increase"
        }
        onClick={() => {
          dispatch({ type: "decrement" });
        }}
        style={buttonStyles}
      >
        <i className="fa-solid fa-solid fa-minus"></i>
      </button>
      <input
        ref={ref}
        className="form-control incremental-input text-center rounded-0"
        type="text"
        min={minValue}
        value={state.count}
        defaultValue={state.count}
        readOnly={true}
      />
      <button
        className="btn btn-primary incremental-button incremental-button-decrease"
        onClick={() => {
          dispatch({ type: "increment" });
        }}
        style={buttonStyles}
      >
        <i className="fa-solid fa-solid fa-plus"></i>
      </button>
    </div>
  );
});

export default IncrementalButton;
