import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation, NavLink, useNavigate } from "react-router-dom";

import "./suppliers.css";
import API from "../../utils/api";
import { remoteFile, showAgreement } from "../../utils/funcs";

import Swal from "sweetalert2";
import useDidMountEffect from "../../utils/useDidMountEffect";

import SideBar from "../SideBar";
import SeoLayer from "../SeoLayer/SeoLayer";

const defaultFormData = {
  supplierUuid: null,
  taxNumber: "",
  companyName: "",
  email: "",
  phoneAreaCode: "-1",
  phone: "",
  businessPhoneAreaCode: "-1",
  businessPhone: "",
  country: "-1",
  city: "-1",
  town: "-1",
  zip: "",
  companyAddress: "",
  companyAddress2: "",
  foundationDay: "-1",
  foundationMonth: "-1",
  foundationYear: "-1",
  supplierAgreement: false,
  bankName: "",
  bankBranchAddress: "",
  bankAddress: "",
  bankAccountNo: "",
  bankAccountIban: "",
  bankAccountSwiftCode: "",
  beneficiaryAddress: "",
  beneficiaryName: "",
  taxPlateFile: null,
  passportFile: null,
  invoiceFile: null,
  status: false,
};

function SupplierForm() {
  const { t } = useTranslation();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const currentUser = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState(defaultFormData);

  const [isLoading, setLoading] = useState(true);

  const stateRef = useRef();
  const cityRef = useRef();

  useDidMountEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const breadcrumbPaths = pathnames.map((_, index, arr) => {
      const path = `/${arr.slice(0, index + 1).join("/")}`;
      const translationKey = `breadcrumb.${arr[index]}`;
      const label = t(translationKey);
      return {
        path,
        label,
        isActive: index === arr.length - 1,
      };
    });

    setBreadcrumbs(breadcrumbPaths);
  }, [location, t]);

  useEffect(() => {
    async function fetchData() {
      const [countriesData, statesData, citiesData] = await Promise.all([
        fetch(remoteFile("/statics/contents/countries.json")).then((res) => res.json()),
        fetch(remoteFile("/statics/contents/states.json")).then((res) => res.json()),
        fetch(remoteFile("/statics/contents/cities.json")).then((res) => res.json()),
      ]);
  
      const supplier = await API.get("/suppliers/me");
  
      setLoading(false);
  
      setCities(citiesData);
      setStates(statesData);
      setCountries(countriesData);
  
      if (supplier && supplier.data && supplier.data.result) {
        const supplierData = supplier.data.result;
        const newFormData = {
          supplierUuid: supplierData.supplier_uuid,
          taxNumber: supplierData.tax_number,
          companyName: supplierData.company_name,
          email: supplierData.email,
          phoneAreaCode: supplierData.phone_area_code,
          phone: supplierData.phone,
          businessPhoneAreaCode: supplierData.business_phone_area_code,
          businessPhone: supplierData.business_phone,
          country: supplierData.country,
          city: supplierData.city,
          town: supplierData.town,
          zip: supplierData.zip,
          companyAddress: supplierData.company_address,
          companyAddress2: supplierData.company_address2,
          foundationDay: supplierData.foundation_day.toString().padStart(2, 0),
          foundationMonth: supplierData.foundation_month.toString().padStart(2, 0),
          foundationYear: supplierData.foundation_year,
          supplierAgreement: supplierData.supplier_agreement,

          bankName: supplierData.bank?.bank_name,
          bankBranchAddress: supplierData.bank?.bank_branch_address,
          bankAddress: supplierData.bank?.bank_address,
          bankAccountNo: supplierData.bank?.bank_account_no,
          bankAccountIban: supplierData.bank?.bank_account_iban,
          bankAccountSwiftCode: supplierData.bank?.bank_account_swift_code,
          beneficiaryAddress: supplierData.bank?.beneficiary_address,
          beneficiaryName: supplierData.bank?.beneficiary_name,

          taxPlateFile: supplierData.tax_plate,
          passportFile: supplierData.passport,
          invoiceFile: supplierData.invoice,
          status: supplierData.status,
        };
        setFormData(newFormData);
  
        setTimeout(() => {
          const countryElement = document.getElementById("supplier-country");
          const cityElement = document.getElementById("supplier-city");
          
          if (countryElement) {
            let event = new Event("input", { bubbles: true });
            countryElement.dispatchEvent(event);
          }
  
          if (cityElement) {
            let event = new Event("input", { bubbles: true });
            cityElement.dispatchEvent(event);
          }
          
          setFormData({ ...newFormData, city: supplierData.city, town: supplierData.town });
        }, 1000);
      }
    }
  
    if (countries.length === 0) {
      fetchData();
    }
  }, []);

  const countryInputHandler = function (event) {
    document.getElementById("supplier-city").length = 0;
    document.getElementById("supplier-town").length = 0;
    const countryId = parseInt(
      document.getElementById("supplier-country").value
    );
    const filteredStates = states.filter((e) => e.country_id === countryId);
    for (const fs of filteredStates) {
      const option = document.createElement("option");
      option.value = fs.id;
      option.text = fs.name;
      document.getElementById("supplier-city").appendChild(option);
    }
  };

  const stateInputHandler = function (event) {
    document.getElementById("supplier-town").length = 0;
    const countryId = parseInt(
      document.getElementById("supplier-country").value
    );
    const cityId = formData.city || parseInt(document.getElementById("supplier-city").value);
    const filteredCities = cities.filter(
      (e) => e.country_id === countryId && e.state_id === cityId
    );
    for (const fc of filteredCities) {
      const option = document.createElement("option");
      option.value = fc.id;
      option.text = fc.name;
      document.getElementById("supplier-town").appendChild(option);
    }
  };

  const supplierSubmitHandler = (e) => {
    e.preventDefault();

    if (formData.supplierUuid) {
      supplierUpdateSubmitHandler();
    } else {
      supplierCreateSubmitHandler();
    }
  };

  const supplierCreateSubmitHandler = async () => {
    const validateResult = await isFormValidated();

    if (validateResult?.error === true) {
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      setLoading(true);

      const response = await API.post("/suppliers/create", formDataToSend, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });

      setFormData((prevState) => ({
        ...prevState,
        ["supplierUuid"]: response.data.data.supplier_uuid,
        ["taxPlateFile"]: response.data.data.tax_plate,
        ["passportFile"]: response.data.data.passport,
        ["invoiceFile"]: response.data.data.invoice,
      }));

      Swal.fire({
        title: t("swal.title.create"),
        text: t(response.data.message),
        icon: "success",
        confirmButtonText: t("swal.button.confirm"),
      });
    } catch (err) {
      console.error("Hata:", err);
      Swal.fire({
        title: t("swal.title.error"),
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
    } finally {
      setLoading(false);
    }
  };

  const supplierUpdateSubmitHandler = async () => {
    const validateResult = await isFormValidated();

    if (validateResult?.error === true) {
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      setLoading(true);

      const response = await API.post("/suppliers/update", formDataToSend, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });

      setFormData((prevState) => ({
        ...prevState,
        ["supplierUuid"]: response.data.data.supplier_uuid,
        ["taxPlateFile"]: response.data.data.tax_plate,
        ["passportFile"]: response.data.data.passport,
        ["invoiceFile"]: response.data.data.invoice,
      }));

      Swal.fire({
        title: t("swal.title.update"),
        text: t(response.data.message),
        icon: "success",
        confirmButtonText: t("swal.button.confirm"),
      });
    } catch (err) {
      console.error("Hata:", err);
      Swal.fire({
        title: t("swal.title.error"),
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
    } finally {
      setLoading(false);
    }
  };

  const fileInputEventHandler = (e) => {
    document.getElementById(e.target.dataset["target"]).innerText =
      e.target.files[0].name;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    let setValue = value;

    if (type === "checkbox") {
      setValue = checked;
    } else if (type === "file") {
      setValue = files[0];
    }

    setFormData({ ...formData, [name]: setValue });
  };

  const isFormValidated = async function () {
    const formData = new FormData(document.getElementById("supplier-form"));
    for (const [key, value] of Array.from(formData)) {
      const isRequired = document.querySelector(
        '[name="' + key + '"]'
      ).required;
      try {
        if (isRequired && value === "-1") {
          throw {
            msg: t("supplier.input.message.validInput"),
            data: [key, value],
          };
        }

        if (
          ["taxPlateFile", "passportFile", "invoiceFile"].includes(key) &&
          formData.supplierUuid === null
        ) {
          if (value.size === 0) {
            throw {
              msg: t("supplier.input.message.validFileInput"),
              data: [key, value],
            };
          }
        }
      } catch (err) {
        Swal.fire({
          title: t("swal.title.warning"),
          //text: err.msg + ` - Key: ${err.data[0]}, Value: ${err.data[1]}`,
          text: err.msg,
          icon: "warning",
          confirmButtonText: t("swal.button.confirm"),
        });
        console.error(err);
        return { error: true, data: err.data };
      }
    }
  };

  return (
    <>
      <SeoLayer
        title={t("seo_layer.supplier.title")}
        description={t("seo_layer.supplier.description")}
        endpoint="supplier"
      />
      <SideBar>
        <h3 className="d-flex align-items-center py-3">
          {formData.supplierUuid != null &&
            (formData.status ? (
              <i className="buyrun-icon buyrun-icon-m buyrun-icon-approvedSupplier"></i>
            ) : (
              <i className="buyrun-icon buyrun-icon-m buyrun-icon-pendingSupplier"></i>
            ))}{" "}
          {t("breadcrumb.supplier")}
          <b
            className={
              "ps-2 " + (formData.status ? "buyrun-txtGreen" : "buyrun-txtGray")
            }
          >
            {formData.status
              ? t("approved")
              : formData.supplierUuid != null
              ? t("breadcrumb.awaiting_review")
              : ""}
          </b>
        </h3>

        {formData.supplierUuid != null && (
          <p className="mb-4 buyrun-txtGray">
            BuyRun Supplier ID: {formData.supplierUuid}
          </p>
        )}

        <form
          id="supplier-form"
          className="row"
          method="post"
          encType="multipart/form-data"
          onSubmit={supplierSubmitHandler}
        >
          <div className="col-lg-6">
            <div className="mb-4">
              <label
                className="buyrun-form-label form-label"
                htmlFor="supplier-taxNumber"
              >
                <b className="required">*</b>{" "}
                {t("supplier_creation_form.tax_number")}
              </label>
              <input
                type="text"
                className="buyrun-form-control form-control"
                id="supplier-taxNumber"
                placeholder="1234567890"
                name="taxNumber"
                required
                defaultValue={formData.taxNumber}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
            {/*  <!-- input --> */}

            <div className="mb-4">
              <label
                htmlFor="supplier-companyName"
                className="buyrun-form-label form-label"
              >
                <b className="required">*</b>{" "}
                {t("supplier_creation_form.company_name")}
              </label>
              <input
                type="text"
                className="buyrun-form-control form-control"
                id="supplier-companyName"
                placeholder="Buy Run"
                name="companyName"
                required
                defaultValue={formData.companyName}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
            {/*  <!-- input --> */}

            <div className="mb-4">
              <label
                htmlFor="supplier-email"
                className="buyrun-form-label form-label"
              >
                <b className="required">*</b>{" "}
                {t("supplier_creation_form.email")}
              </label>
              <input
                type="email"
                className="buyrun-form-control form-control"
                id="supplier-email"
                placeholder="Buy Run"
                name="email"
                required
                defaultValue={formData.email}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
            {/*  <!-- input --> */}

            <div className="row mb-4 align-items-center">
              <div className="col-5">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-phoneAreaCode"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.area_code")}
                </label>
                <select
                  className="buyrun-form-select form-select"
                  id="supplier-phoneAreaCode"
                  name="phoneAreaCode"
                  required
                  value={formData.phoneAreaCode}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="-1">
                    {t("supplier_creation_form.area_code_placeholder")}
                  </option>
                  {countries.length > 0 &&
                    countries.map((c) => (
                      <option value={c.phone_code}>
                        {c.emoji + " " + c.phone_code}
                      </option>
                    ))}
                </select>
              </div>
              {/* <!-- area code --> */}
              <div className="col">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-phone"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.authorized_phone")}
                </label>
                <input
                  type="tel"
                  className="buyrun-form-control form-control"
                  id="supplier-phone"
                  placeholder="555 000 00 00"
                  name="phone"
                  required
                  defaultValue={formData.phone}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- area code --> */}
            </div>
            {/* <!-- input --> */}

            <div className="row mb-4 align-items-center">
              <div className="col-5">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-businessPhoneAreaCode"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.area_code")}
                </label>
                <select
                  className="buyrun-form-select form-select"
                  id="supplier-businessPhoneAreaCode"
                  name="businessPhoneAreaCode"
                  required
                  value={formData.businessPhoneAreaCode}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="-1">
                    {t("supplier_creation_form.area_code_placeholder")}
                  </option>
                  {countries.length > 0 &&
                    countries.map((c) => (
                      <option value={c.phone_code}>
                        {c.emoji + " " + c.phone_code}
                      </option>
                    ))}
                </select>
              </div>
              {/* <!-- area code --> */}
              <div className="col">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-businessPhone"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.company_phone")}
                </label>
                <input
                  type="tel"
                  className="buyrun-form-control form-control"
                  id="supplier-businessPhone"
                  placeholder="555 000 00 00"
                  name="businessPhone"
                  required
                  defaultValue={formData.businessPhone}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*   <!-- area code --> */}
            </div>
            {/* <!-- input --> */}
          </div>
          {/*  <!-- left side inputs --> */}

          <div className="col-lg-6">
            <div className="mb-4">
              <label
                className="buyrun-form-label form-label"
                htmlFor="supplier-country"
              >
                <b className="required">*</b>{" "}
                {t("supplier_creation_form.country")}
              </label>
              <select
                className="buyrun-form-select form-select"
                id="supplier-country"
                name="country"
                required
                value={formData.country}
                onChange={handleInputChange}
                onInput={countryInputHandler}
                disabled={isLoading}
              >
                <option value="-1">
                  {t("supplier_creation_form.country")}
                </option>
                {countries.length > 0 &&
                  countries.map((c) => (
                    <option value={c.id}>{c.emoji + " " + c.name}</option>
                  ))}
              </select>
            </div>
            {/*  <!-- input --> */}

            <div className="row mb-4 align-items-center">
              <div className="col">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-city"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.province_state")}
                </label>
                <select
                  className="buyrun-form-select form-select"
                  id="supplier-city"
                  name="city"
                  required
                  value={formData.city}
                  onChange={handleInputChange}
                  onInput={stateInputHandler}
                  ref={stateRef}
                  disabled={isLoading}
                >
                  <option value="-1">
                    {t("supplier_creation_form.province_state_placeholder")}
                  </option>
                </select>
              </div>
              {/* <!-- city --> */}

              <div className="col">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-town"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.district_region")}
                </label>
                <select
                  className="buyrun-form-select form-select"
                  id="supplier-town"
                  name="town"
                  required
                  value={formData.town}
                  onChange={handleInputChange}
                  ref={cityRef}
                  disabled={isLoading}
                >
                  <option value="-1">
                    {t("supplier_creation_form.district_region_placeholder")}
                  </option>
                </select>
              </div>
              {/*   <!-- town --> */}

              <div className="col">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-zip"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.postal_code")}
                </label>
                <input
                  className="buyrun-form-control form-control"
                  id="supplier-zip"
                  placeholder={t(
                    "supplier_creation_form.postal_code_placeholder"
                  )}
                  name="zip"
                  required
                  defaultValue={formData.zip}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- zip --> */}
            </div>
            {/* <!-- input --> */}

            <div className="mb-4">
              <label
                htmlFor="supplier-address"
                className="buyrun-form-label form-label"
              >
                <b className="required">*</b>{" "}
                {t("supplier_creation_form.company_address")}
              </label>
              <input
                type="text"
                className="buyrun-form-control form-control"
                id="supplier-address"
                placeholder={t(
                  "supplier_creation_form.company_address_placeholder"
                )}
                name="companyAddress"
                required
                defaultValue={formData.companyAddress}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
            {/*  <!-- input --> */}

            <div className="mb-4">
              <label
                htmlFor="supplier-address2"
                className="buyrun-form-label form-label"
              >
                {t("supplier_creation_form.company_address_2")}
              </label>
              <input
                type="text"
                className="buyrun-form-control form-control"
                id="supplier-address2"
                placeholder={t("supplier_creation_form.company_address_2")}
                name="companyAddress2"
                defaultValue={formData.companyAddress2}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
            {/*  <!-- input --> */}

            <div className="row mb-4 align-items-center">
              <label className="buyrun-form-label form-label">
                {t("supplier_creation_form.date_of_company_incorporation")}
              </label>
              <div className="col">
                <select
                  className="buyrun-form-select form-select"
                  id="supplier-foundationDay"
                  name="foundationDay"
                  value={formData.foundationDay}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="-1">{t("supplier_creation_form.day")}</option>
                  <option defaultValue="01">01</option>
                  <option defaultValue="02">02</option>
                  <option defaultValue="03">03</option>
                  <option defaultValue="04">04</option>
                  <option defaultValue="05">05</option>
                  <option defaultValue="06">06</option>
                  <option defaultValue="07">07</option>
                  <option defaultValue="08">08</option>
                  <option defaultValue="09">09</option>
                  <option defaultValue="10">10</option>
                  <option defaultValue="11">11</option>
                  <option defaultValue="12">12</option>
                  <option defaultValue="13">13</option>
                  <option defaultValue="14">14</option>
                  <option defaultValue="15">15</option>
                  <option defaultValue="16">16</option>
                  <option defaultValue="17">17</option>
                  <option defaultValue="18">18</option>
                  <option defaultValue="19">19</option>
                  <option defaultValue="20">20</option>
                  <option defaultValue="21">21</option>
                  <option defaultValue="22">22</option>
                  <option defaultValue="23">23</option>
                  <option defaultValue="24">24</option>
                  <option defaultValue="25">25</option>
                  <option defaultValue="26">26</option>
                  <option defaultValue="27">27</option>
                  <option defaultValue="28">28</option>
                  <option defaultValue="29">29</option>
                  <option defaultValue="30">30</option>
                  <option defaultValue="31">31</option>
                </select>
              </div>
              {/* <!-- year --> */}
              <div className="col">
                <select
                  className="buyrun-form-select form-select"
                  id="supplier-foundationMonth"
                  name="foundationMonth"
                  value={formData.foundationMonth}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="-1">
                    {" "}
                    {t("supplier_creation_form.month")}
                  </option>
                  <option defaultValue="01">01</option>
                  <option defaultValue="02">02</option>
                  <option defaultValue="03">03</option>
                  <option defaultValue="04">04</option>
                  <option defaultValue="05">05</option>
                  <option defaultValue="06">06</option>
                  <option defaultValue="07">07</option>
                  <option defaultValue="08">08</option>
                  <option defaultValue="09">09</option>
                  <option defaultValue="10">10</option>
                  <option defaultValue="11">11</option>
                  <option defaultValue="12">12</option>
                </select>
              </div>
              {/*  <!-- year --> */}
              <div className="col">
                <select
                  className="buyrun-form-select form-select"
                  id="supplier-foundationYear"
                  name="foundationYear"
                  value={formData.foundationYear}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="-1">{t("supplier_creation_form.year")}</option>
                  <option defaultValue="1920">1920</option>
                  <option defaultValue="1921">1921</option>
                  <option defaultValue="1922">1922</option>
                  <option defaultValue="1923">1923</option>
                  <option defaultValue="1924">1924</option>
                  <option defaultValue="1925">1925</option>
                  <option defaultValue="1926">1926</option>
                  <option defaultValue="1927">1927</option>
                  <option defaultValue="1928">1928</option>
                  <option defaultValue="1929">1929</option>
                  <option defaultValue="1930">1930</option>
                  <option defaultValue="1931">1931</option>
                  <option defaultValue="1932">1932</option>
                  <option defaultValue="1933">1933</option>
                  <option defaultValue="1934">1934</option>
                  <option defaultValue="1935">1935</option>
                  <option defaultValue="1936">1936</option>
                  <option defaultValue="1937">1937</option>
                  <option defaultValue="1938">1938</option>
                  <option defaultValue="1939">1939</option>
                  <option defaultValue="1940">1940</option>
                  <option defaultValue="1941">1941</option>
                  <option defaultValue="1942">1942</option>
                  <option defaultValue="1943">1943</option>
                  <option defaultValue="1944">1944</option>
                  <option defaultValue="1945">1945</option>
                  <option defaultValue="1946">1946</option>
                  <option defaultValue="1947">1947</option>
                  <option defaultValue="1948">1948</option>
                  <option defaultValue="1949">1949</option>
                  <option defaultValue="1950">1950</option>
                  <option defaultValue="1951">1951</option>
                  <option defaultValue="1952">1952</option>
                  <option defaultValue="1953">1953</option>
                  <option defaultValue="1954">1954</option>
                  <option defaultValue="1955">1955</option>
                  <option defaultValue="1956">1956</option>
                  <option defaultValue="1957">1957</option>
                  <option defaultValue="1958">1958</option>
                  <option defaultValue="1959">1959</option>
                  <option defaultValue="1960">1960</option>
                  <option defaultValue="1961">1961</option>
                  <option defaultValue="1962">1962</option>
                  <option defaultValue="1963">1963</option>
                  <option defaultValue="1964">1964</option>
                  <option defaultValue="1965">1965</option>
                  <option defaultValue="1966">1966</option>
                  <option defaultValue="1967">1967</option>
                  <option defaultValue="1968">1968</option>
                  <option defaultValue="1969">1969</option>
                  <option defaultValue="1970">1970</option>
                  <option defaultValue="1971">1971</option>
                  <option defaultValue="1972">1972</option>
                  <option defaultValue="1973">1973</option>
                  <option defaultValue="1974">1974</option>
                  <option defaultValue="1975">1975</option>
                  <option defaultValue="1976">1976</option>
                  <option defaultValue="1977">1977</option>
                  <option defaultValue="1978">1978</option>
                  <option defaultValue="1979">1979</option>
                  <option defaultValue="1980">1980</option>
                  <option defaultValue="1981">1981</option>
                  <option defaultValue="1982">1982</option>
                  <option defaultValue="1983">1983</option>
                  <option defaultValue="1984">1984</option>
                  <option defaultValue="1985">1985</option>
                  <option defaultValue="1986">1986</option>
                  <option defaultValue="1987">1987</option>
                  <option defaultValue="1988">1988</option>
                  <option defaultValue="1989">1989</option>
                  <option defaultValue="1990">1990</option>
                  <option defaultValue="1991">1991</option>
                  <option defaultValue="1992">1992</option>
                  <option defaultValue="1993">1993</option>
                  <option defaultValue="1994">1994</option>
                  <option defaultValue="1995">1995</option>
                  <option defaultValue="1996">1996</option>
                  <option defaultValue="1997">1997</option>
                  <option defaultValue="1998">1998</option>
                  <option defaultValue="1999">1999</option>
                  <option defaultValue="2000">2000</option>
                  <option defaultValue="2001">2001</option>
                  <option defaultValue="2002">2002</option>
                  <option defaultValue="2003">2003</option>
                  <option defaultValue="2004">2004</option>
                  <option defaultValue="2005">2005</option>
                  <option defaultValue="2006">2006</option>
                  <option defaultValue="2007">2007</option>
                  <option defaultValue="2008">2008</option>
                  <option defaultValue="2009">2009</option>
                  <option defaultValue="2010">2010</option>
                  <option defaultValue="2011">2011</option>
                  <option defaultValue="2012">2012</option>
                  <option defaultValue="2013">2013</option>
                  <option defaultValue="2014">2014</option>
                  <option defaultValue="2015">2015</option>
                  <option defaultValue="2016">2016</option>
                  <option defaultValue="2017">2017</option>
                  <option defaultValue="2018">2018</option>
                  <option defaultValue="2019">2019</option>
                  <option defaultValue="2020">2020</option>
                  <option defaultValue="2021">2021</option>
                  <option defaultValue="2022">2022</option>
                  <option defaultValue="2023">2023</option>
                </select>
              </div>
              {/*  <!-- year --> */}
            </div>
            {/*  <!-- input --> */}
          </div>
          {/*  <!-- right side inputs --> */}

          <div className="col-lg-12 mt-4 align-items-center">
            <div className="col">
              <input
                type="checkbox"
                className="form-check-input"
                id="supplier-supplierAgreement"
                name="supplierAgreement"
                checked={formData.supplierAgreement}
                onChange={handleInputChange}
                disabled={isLoading}
              />
              <label
                className="text-secondary ms-1"
                htmlFor="supplier-supplierAgreement"
              >
                {" Buy.Run "}
                <Link target="_blank" to={remoteFile("/agreements/supplier-agreement.html")} className="text-primary">
                  {t("supplier_creation_form.supplier_agreement")}'
                </Link>
                {t("supplier_creation_form.promotional_mail_permission_text_2")}
              </label>
            </div>
            {/* <!-- checkbox --> */}
          </div>
          {/*  <!-- input --> */}

          <div className="col-lg-12">
            <h4 className="pt-5 pb-3">
              {t("supplier_creation_form.bankInformation")}
            </h4>
            <p className="text-secondary">
              {t("supplier_creation_form.bankInformationNotes")}
            </p>
          </div>
          {/*  <!-- uploader header --> */}

          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-bankName"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.bankName")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-bankName"
                  placeholder={t("supplier_creation_form.bankName")}
                  name="bankName"
                  required
                  defaultValue={formData.bankName}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- input --> */}
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-bankBranchAddress"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.bankBranchAddress")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-bankBranchAddress"
                  placeholder={t("supplier_creation_form.bankBranchAddress")}
                  name="bankBranchAddress"
                  required
                  defaultValue={formData.bankBranchAddress}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- input --> */}
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-bankAddress"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.bankAddress")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-bankAddress"
                  placeholder={t("supplier_creation_form.bankAddress")}
                  name="bankAddress"
                  required
                  defaultValue={formData.bankAddress}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- input --> */}
            </div>
            {/* supplier bank info */}

            <div className="col-lg-6">
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-bankAccountNo"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.bankAccountNo")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-bankAccountNo"
                  placeholder={t("supplier_creation_form.bankAccountNo")}
                  name="bankAccountNo"
                  required
                  defaultValue={formData.bankAccountNo}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- input --> */}
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-bankAccountIban"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.bankAccountIban")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-bankAccountIban"
                  placeholder={t("supplier_creation_form.bankAccountIban")}
                  name="bankAccountIban"
                  required
                  defaultValue={formData.bankAccountIban}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- input --> */}
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-bankAccountSwiftCode"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.bankAccountSwiftCode")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-bankAccountSwiftCode"
                  placeholder={t("supplier_creation_form.bankAccountSwiftCode")}
                  name="bankAccountSwiftCode"
                  required
                  defaultValue={formData.bankAccountSwiftCode}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- input --> */}
            </div>
            {/* supplier bank account info */}

            <div className="col-lg-12">
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-beneficiaryAddress"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.beneficiaryAddress")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-beneficiaryAddress"
                  placeholder={t("supplier_creation_form.beneficiaryAddress")}
                  name="beneficiaryAddress"
                  required
                  defaultValue={formData.beneficiaryAddress}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {/*  <!-- input --> */}
              <div className="mb-4">
                <label
                  className="buyrun-form-label form-label"
                  htmlFor="supplier-beneficiaryName"
                >
                  <b className="required">*</b>{" "}
                  {t("supplier_creation_form.beneficiaryName")}
                </label>
                <input
                  type="text"
                  className="buyrun-form-control form-control"
                  id="supplier-beneficiaryName"
                  placeholder={t("supplier_creation_form.beneficiaryName")}
                  name="beneficiaryName"
                  required
                  defaultValue={formData.beneficiaryName}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>
            {/* beneficiary info */}
          </div>

          <div className="col-lg-12">
            <h4 className="pt-5 pb-3">
              {t("supplier_creation_form.upload_your_files")}
            </h4>
            <p className="text-secondary">
              {t("supplier_creation_form.upload_your_files_notes")}
            </p>
          </div>
          {/*  <!-- uploader header --> */}

          <div className="row mt-4">
            <div className="col-lg-6">
              <h6 className="buyrun-form-label form-labe mb-3">
                {t("supplier_creation_form.tax_plate")}
              </h6>
              <label className="buyrun-fileLabel" htmlFor="supplier-taxPlate">
                <input
                  className="buyrun-fileInput d-none"
                  type="file"
                  id="supplier-taxPlate"
                  name="taxPlateFile"
                  data-target="supplier-taxPlateFile"
                  onChange={handleInputChange}
                  onInput={fileInputEventHandler}
                  disabled={isLoading}
                />
                <div className="text-center">
                  {formData.supplierUuid && (
                    <a href={remoteFile(formData.taxPlateFile.webpath)}>
                      {t("supplier_creation_form.supplier.download.tax_plate")}
                    </a>
                  )}

                  <p className="buyrun-filename" id="supplier-taxPlateFile">
                    {t("supplier_creation_form.select_file")}
                  </p>
                  <p className="mb-1 text-secondary">
                    {t("supplier_creation_form.allowed_file_types")}
                  </p>
                  <p className="mb-0">
                    <b className="badge rounded-pill text-bg-success">PDF</b>
                    <b className="badge rounded-pill text-bg-success">
                      JPG / JPEG
                    </b>
                    <b className="badge rounded-pill text-bg-success">PNG</b>
                  </p>
                </div>
              </label>

              {/*  <!-- file uploader --> */}
            </div>
            {/* <!-- file --> */}

            <div className="col-lg-6">
              <h6 className="buyrun-form-label form-label mb-3">
                {t("supplier_creation_form.passport")}
              </h6>
              <label className="buyrun-fileLabel" htmlFor="supplier-passport">
                <input
                  className="buyrun-fileInput d-none"
                  type="file"
                  id="supplier-passport"
                  name="passportFile"
                  data-target="supplier-passportFile"
                  onChange={handleInputChange}
                  onInput={fileInputEventHandler}
                  disabled={isLoading}
                />
                <div className="text-center">
                  {formData.supplierUuid && (
                    <a href={remoteFile(formData.passportFile.webpath)}>
                      {t("supplier_creation_form.supplier.download.passport")}
                    </a>
                  )}

                  <p className="buyrun-filename" id="supplier-passportFile">
                    {t("supplier_creation_form.select_file")}
                  </p>
                  <p className="mb-1 text-secondary">
                    {t("supplier_creation_form.allowed_file_types")}
                  </p>
                  <p className="mb-0">
                    <b className="badge rounded-pill text-bg-success">PDF</b>
                    <b className="badge rounded-pill text-bg-success">
                      JPG / JPEG
                    </b>
                    <b className="badge rounded-pill text-bg-success">PNG</b>
                  </p>
                </div>
              </label>
              {/*      <!-- <div className="d-flex supplier-fileProgress mt-3">
                <img src="" alt="" width="50" height="50"> 
                <div className="mx-3 w-100">
                  <div className="d-flex text-secondary mb-2">
                    <span className="w-100"></span>
                    <span>%0</span>
                  </div>
                  <div className="progress" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="height: 6px">
                    <div className="progress-bar buyrun-bgBlue" style="width: 0%"></div>
                  </div>
                </div>
                <img src="" alt="" width="50" height="50"> 
              </div> --> */}
              {/*  <!-- file uploader --> */}
            </div>
            {/* <!-- file --> */}

            <div className="col-lg-6 mt-5">
              <h6 className="buyrun-form-label form-label mb-3">
                {t("supplier_creation_form.invoice")}
              </h6>
              <label className="buyrun-fileLabel" htmlFor="supplier-invoice">
                <input
                  className="buyrun-fileInput d-none"
                  type="file"
                  id="supplier-invoice"
                  name="invoiceFile"
                  data-target="supplier-invoiceFile"
                  onChange={handleInputChange}
                  onInput={fileInputEventHandler}
                  disabled={isLoading}
                />
                <div className="text-center">
                  {formData.supplierUuid && (
                    <a href={remoteFile(formData.invoiceFile.webpath)}>
                      {t("supplier_creation_form.supplier.download.invoice")}
                    </a>
                  )}
                  <p className="buyrun-filename" id="supplier-invoiceFile">
                    {t("supplier_creation_form.select_file")}
                  </p>
                  <p className="mb-1 text-secondary">
                    {t("supplier_creation_form.allowed_file_types")}
                  </p>
                  <p className="mb-0">
                    <b className="badge rounded-pill text-bg-success">PDF</b>
                    <b className="badge rounded-pill text-bg-success">
                      JPG / JPEG
                    </b>
                    <b className="badge rounded-pill text-bg-success">PNG</b>
                  </p>
                </div>
              </label>
              {/*    <!-- <div className="d-flex supplier-fileProgress mt-3">
                <img src="" alt="" width="50" height="50"/> 
                <div className="mx-3 w-100">
                  <div className="d-flex text-secondary mb-2">
                    <span className="w-100"></span>
                    <span>%0</span>
                  </div>
                  <div className="progress" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="height: 6px">
                    <div className="progress-bar buyrun-bgYellow" style="width: 0%"></div>
                  </div>
                </div>
                <img src="" alt="" width="50" height="50"/> 
              </div> --> */}
              {/* <!-- file uploader --> */}
            </div>
            {/*  <!-- file --> */}
          </div>
          {/* <!-- file uploads --> */}

          <div className="row mt-5">
            <div className="col-lg-3">
              <button className="w-100 btn btn-primary" disabled={isLoading}>
                {formData.supplierUuid ? (
                  <span>
                    <i class="fa-duotone fa-pen-to-square me-1"></i>
                    {t("supplier_creation_form.update")}
                  </span>
                ) : (
                  <span>
                    <i class="fa-duotone fa-plus-large me-1"></i>
                    {t("supplier_creation_form.create")}
                  </span>
                )}
              </button>
            </div>
          </div>
          {/*  <!-- action button --> */}
        </form>
      </SideBar>

      {/* <!-- inputs --> */}

      {/*  <!-- .mainContainer --> */}
    </>
  );
}

export default SupplierForm;
