import React, { useEffect, useRef, useState } from "react";
import API from "../../utils/api";

import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import AsyncButton from "../CustomInputs/AsyncButton";

import Swal from "sweetalert2";

import "./faq.css";
import SeoLayer from "../SeoLayer/SeoLayer";

export default function Faq(props) {
  const { t } = useTranslation();

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

/*   useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await API.post("/supplier/products", {
          page: page,
        });
        setProducts(response.data.products);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [page]); */

  return (
    <div className="container mt-3 d-flex justify-content-center align-items-center" style={{ minHeight: 500 }}>
      <SeoLayer
        title={t("seo_layer.faq.title")}
        description={t("seo_layer.faq.description")}
        endpoint="faq"
      />
      <p className="alert alert-info">
        {t("maintenance_message")}
      </p>
    </div>
  );
}
