// Pagination.js
import React from "react";

const Pagination = ({
  currentPage,
  totalPages,
  paginate,
  handleNextbtn,
  handlePrevbtn,
  maxPageNumberLimit,
  minPageNumberLimit,
  pageNumberLimit,
}) => {
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <button onClick={() => paginate(number)} className="page-link">
            {number}
          </button>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <nav className="mt-3">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={handlePrevbtn}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {renderPageNumbers}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          style={{
            cursor: "pointer",
          }}
        >
          <button
            className="page-link"
            onClick={handleNextbtn}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
