import styles from "./footer.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      {/*       <div className="container-fluid mt-5 d-flex justify-content-center ">
        <div className="row">
          <ul className={`${styles.wrapper}`}>
            <li className={`${styles.icon} ${styles.facebook}`}>
              <span className={`${styles.tooltip}`}>Facebook</span>
              <i className="bi bi-facebook"></i>
            </li>
            <li className={`${styles.icon} ${styles.twitter}`}>
              <span className={`${styles.tooltip}`}>Twitter</span>
              <i className="bi bi-twitter"></i>
            </li>
            <li className={`${styles.icon} ${styles.instagram}`}>
              <span className={`${styles.tooltip}`}>Instagram</span>
              <i className="bi bi-instagram"></i>
            </li>
            <li className={`${styles.icon} ${styles.linkedin}`}>
              <span className={`${styles.tooltip}`}>Linkedin</span>
              <i className="bi bi-linkedin"></i>
            </li>
            <li className={`${styles.icon} ${styles.youtube}`}>
              <span className={`${styles.tooltip}`}>Youtube</span>
              <i className="bi bi-youtube"></i>
            </li>
          </ul>
        </div>
      </div> */}
      <footer
        className={`container-fluid text-center py-3 mt-auto ${styles.footer}`}
      >
        <div className="container">
          <div className={`${styles.linksContainer}`}>
            <Link className={`${styles.footerLink}`} to="/about">
              <i class="fa-duotone fa-pen-swirl me-1"></i>
              {t("header.about")}
            </Link>
            <Link className={`${styles.footerLink}`} to="/supplier">
              <i class="fa-duotone fa-user-plus me-1"></i>
              {t("header.supplier.become")}
            </Link>
            <Link className={`${styles.footerLink}`} to="/faq">
              <i class="fa-duotone fa-badge-check me-1"></i>
              {t("header.questions")}
            </Link>
            <Link className={`${styles.footerLink}`} to="/contact">
              <i class="fa-duotone fa-paper-plane me-1"></i>
              {t("header.contact")}
            </Link>
          </div>

          <p className="mt-3">© {(new Date).getFullYear()} BuyRun</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;


 