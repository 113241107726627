import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import userReducer from "../feature/User/userSlice";
import navigationReducer from "../feature/Ui/navigationSlice";
import cartReducer from "../feature/Cart/cartSlice";
import currencyReducer from "../feature/Currency/currencySlice";
import addressReducer from "../feature/Address/addressSlice";
import paymentMethodsReducer from "../feature/PaymentMethods/paymentMethodsSlice";
import searchReducer from "../feature/Search/searchSlice";

import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  navigation: navigationReducer,
  user: userReducer,
  cart: cartReducer,
  currency: currencyReducer,
  address: addressReducer,
  paymentMethods: paymentMethodsReducer,
  search: searchReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
