import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export function remoteFile(filePath){
  if (!filePath){
    return "https://cdn.buy.run/statics/img/buyrunlogo.png";
  }
  return "https://cdn.buy.run" + (filePath.startsWith("/") ? filePath : '/' + filePath);
}

export function showAgreement(filename) {
  const iframeHtml = `<iframe style="width:100%;height:1000px" src="https://view.officeapps.live.com/op/view.aspx?src=https://cdn.buy.run/agreements/${filename}"></iframe>`;
  Swal.fire({
    html: iframeHtml,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    focusConfirm: false,
    width: 1080
  });
} 

export function LoginAgreement() {
  const { t } = useTranslation();
  
  return <div className="border-top pt-3" style={{
    fontSize: 12,
    lineHeight: 1.5,
    color: "#757575"
  }}>
    {t("welcome_agreement.agreement")} <Link style={{ color: "inherit"}} target="_blank" to="https://cdn.buy.run/agreements/membership-agreement.html"><u>{t("welcome_agreement.membership_agreement")}</u></Link>, <Link style={{ color: "inherit"}} target="_blank" to="https://cdn.buy.run/agreements/cookie-and-privacy-agreement.html"><u>{t("welcome_agreement.cookie_privacy_agreement")}</u></Link> ve <Link style={{ color: "inherit"}} target="_blank" to="https://cdn.buy.run/agreements/terms-of-use.html"><u>{t("welcome_agreement.terms_of_use")}</u></Link> {t("welcome_agreement.accepted")}
  </div>
}