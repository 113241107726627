import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

import "./sidebar.css";
import useDidMountEffect from "../../utils/useDidMountEffect";

export default function SideBar({ children }) {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  /*   const [isLinkActive, setIsLinkActive] = useState(false); */

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const breadcrumbPaths = pathnames.map((_, index, arr) => {
      const path = `/${arr.slice(0, index + 1).join("/")}`;
      const translationKey = `breadcrumb.${arr[index]}`;
      const label = t(translationKey);
      return {
        path,
        label,
        isActive: index === arr.length - 1,
      };
    });

    setBreadcrumbs(breadcrumbPaths);
  }, [location, t]);

  /*   const handleActiveLink = (e) => {
    if (isLinkActive) {
      e.preventDefault();
      alert("Bu linke şu anda tıklanamaz.");
    }
  }; */
  return (
    <main className="container">
      <div className="mainWrapper row">
        <div className="leftSide p-0 col-lg-3">
          <div className="d-flex flex-column align-items-center my-4">
            <div className="supplier-profileImage">
              <img
                className="rounded-pill bg-light shadow border mb-3"
                src={currentUser?.profilePicture}
                alt="Profile image"
                width="75"
                height="75"
              />
            </div>
            <div className="w-100 d-inline-flex flex-column align-items-center supplier-info">
              <span>
                <b>
                  {currentUser?.fullname || (
                    <span className="text-secondary">-</span>
                  )}
                </b>
              </span>
              <span>{currentUser?.email}</span>
            </div>
          </div>

          {/*  <!-- .supplier-wrapper --> */}
          <div className="leftSide-menu">
            {/* <h5
              className="leftSide-menuTitle leftSide-sp"
              style={{ "--bgColor": "var(--buyrunBlue)" }}
            >
              <span>{t("breadcrumb.customer_dashboard")}</span>
            </h5> */}

            <h5
              className="leftSide-menuTitle leftSide-sp active"
              style={{ "--bgColor": "var(--buyrunOrange)" }}
            >
              <span> {t("breadcrumb.supplier_dashboard")} </span>
            </h5>

            {/* <h5
              className="leftSide-menuTitle leftSide-sp"
              style={{ "--bgColor": "var(--buyrunGreen)" }}
            >
              <span>{t("breadcrumb.dropshipping_dashboard")}</span>
            </h5> */}

            {/*
            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("breadcrumb.my_orders")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <NavLink
                to="/supplier/orders"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-orders"></i>{" "}
                {t("breadcrumb.orders")}
              </NavLink>
              <NavLink
                to="/supplier/returns"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-refunds"></i>{" "}
                {t("breadcrumb.returns")}
              </NavLink>
              <NavLink
                to="/supplier/reviews"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-reviews"></i>{" "}
                {t("breadcrumb.rewiews")}
              </NavLink>
              <NavLink
                to="/supplier/user-messages"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-userMessages"></i>{" "}
                {t("breadcrumb.user_messages")}
              </NavLink>
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("breadcrumb.my_demands")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <NavLink
                to="/supplier/demand-archive"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-requestArchive"></i>{" "}
                {t("breadcrumb.demand_archive")}
              </NavLink>
              <NavLink
                to="/supplier/demand-reports"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-demandReports"></i>{" "}
                {t("breadcrumb.demand_reports")}
              </NavLink>
              <NavLink
                to="/supplier/new-demand"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-newRequest"></i>{" "}
                {t("breadcrumb.create_new_demand")}
              </NavLink>
            </div>
            */}

            {/* <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("breadcrumb.account_and_help")}
            </h5> */}

            <div className="leftSide-listGroup list-group mb-4">
              {/*
              <NavLink
                to="/supplier"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-supplierProfile"></i>{" "}
                {t("breadcrumb.supplier_dashboard")}
              </NavLink>
              <NavLink
                to="/supplier/change-password"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-changePassword"></i>{" "}
                {t("breadcrumb.change_password")}
              </NavLink>
              */}
              <NavLink
                to="/supplier"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
                end
              >
                {/* <i className="buyrun-icon buyrun-icon-shoppingCentre"></i> */}
                <i class="fa-duotone fa-user-tie"></i>
                {t("header.supplier.profile")}
              </NavLink>
              {/*
              <NavLink
                to="/supplier/sale-reports"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-salesCharts"></i>{" "}
                {t("breadcrumb.sale_reports")}
              </NavLink>
              <NavLink
                to="/supplier/invoicing"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-demandReports"></i>{" "}
                {t("breadcrumb.invoicing")}
              </NavLink>
              <NavLink
                to="/supplier/payment-methods"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-paymentMethods"></i>{" "}
                {t("breadcrumb.payment_methods")}
              </NavLink>
              <NavLink
                to="/supplier/help"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                <i className="buyrun-icon buyrun-icon-help"></i>{" "}
                {t("breadcrumb.help")}
              </NavLink>
              */}
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("breadcrumb.product_options")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <NavLink
                to="/supplier/store"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                {/* <i className="buyrun-icon buyrun-icon-shoppingCentre"></i> */}
                <i class="fa-duotone fa-store"></i>
                {t("breadcrumb.store")}
              </NavLink>

              <NavLink
                to="/supplier/manual-product-add"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                {/* <i className="buyrun-icon buyrun-icon-addRemoveProducts"></i> */}
                <i class="fa-duotone fa-rectangle-history-circle-plus"></i>
                {t("breadcrumb.add_subtract_products")}
              </NavLink>
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("breadcrumb.developer_options")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <NavLink
                to="/supplier/api-key-generator"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                {/* <i className="buyrun-icon buyrun-icon-addRemoveProducts"></i> */}
                <i class="fa-duotone fa-webhook"></i>
                {t("breadcrumb.api-key-generator")}
              </NavLink>

              <NavLink
                to="/supplier/api-integration"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                {/* <i className="buyrun-icon buyrun-icon-addRemoveProducts"></i> */}
                <i class="fa-duotone fa-book"></i>
                {t("breadcrumb.api-integration")}
              </NavLink>
            </div>

            <h5 className="leftSide-menuTitle buyrun-bgOrange">
              {t("breadcrumb.user_messages")}
            </h5>
            <div className="leftSide-listGroup list-group mb-4">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `list-group-item ${isActive ? "active-link" : ""}`
                }
              >
                {/* <i className="buyrun-icon buyrun-icon-addRemoveProducts"></i> */}
                <i class="fa-duotone fa-paper-plane"></i>
                {t("breadcrumb.contact")}
              </NavLink>
            </div>

          </div>

          {/* <!-- .leftSide-menu --> */}
        </div>
        {/* <!-- .leftSideMenu --> */}

        <div className="mainContainer col border-end p-3 ps-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {breadcrumbs.map((crumb, index) => (
                <li
                  key={index}
                  className={`breadcrumb-item ${
                    crumb.isActive ? "active" : ""
                  }`}
                >
                  {crumb.isActive ? (
                    <span>{crumb.label}</span>
                  ) : (
                    <NavLink to={crumb.path}>{crumb.label}</NavLink>
                  )}
                </li>
              ))}
            </ol>
          </nav>

          {children}
        </div>

        {/* <!-- inputs --> */}
      </div>
      {/*  <!-- .mainContainer --> */}
    </main>
  );
}
