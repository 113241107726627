import React, { useState } from "react";
import { Navbar, Nav, Button, Offcanvas } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./head-icon-bar.css";

export default function HeadIconBar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="container d-none d-lg-block">
        <div className="row d-flex align-items-center">
          <div className="col-lg-2 col-md-4 col-sm-4 mt-3">
            <button className="btn col-12">
              <i
                className="fa-duotone fa-table-cells-large fa icon-horizontal-spin"
                style={{ fontSize: "50px", color: "#007bff" }}
              />
              <p
                className="mt-2"
                style={{ color: "rgba(255, 80, 0, 1)", fontWeight: "bold" }}
              >
                Tümü
              </p>
            </button>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 mt-3">
            <button className="btn col-12">
              <i
                className="fa-duotone fa-earth-europe icon-horizontal-spin"
                style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
              />
              <p
                className="mt-2"
                style={{ color: "#007bff", fontWeight: "bold" }}
              >
                Yurt Dışı Ürünleri
              </p>
            </button>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 mt-3">
            <button className="btn col-12">
              <i
                className="fa-duotone fa-laptop icon-horizontal-spin"
                style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
              />
              <p
                className="mt-2"
                style={{ color: "#007bff", fontWeight: "bold" }}
              >
                Bilgisayar
              </p>
            </button>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 mt-3">
            <button className="btn col-12">
              <i
                className="fa-duotone fa-screwdriver-wrench icon-horizontal-spin"
                style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
              />
              <p
                className="mt-2"
                style={{ color: "#007bff", fontWeight: "bold" }}
              >
                Yapı Market
              </p>
            </button>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 mt-3">
            <button className="btn col-12">
              <i
                className="fa-duotone fa-kitchen-set icon-horizontal-spin"
                style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
              />
              <p
                className="mt-2"
                style={{ color: "#007bff", fontWeight: "bold" }}
              >
                Mutfak Ürünleri
              </p>
            </button>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 mt-3">
            <button className="btn col-12">
              <i
                className="fa-duotone fa-truck-monster icon-horizontal-spin"
                style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
              />
              <p
                className="mt-2"
                style={{ color: "#007bff", fontWeight: "bold" }}
              >
                Motorlu Araç Parçaları
              </p>
            </button>
          </div>
        </div>
      </div>

      <Navbar bg="light" expand={false} className="d-lg-none">
        <Navbar.Brand
          href="#"
          style={{ color: "rgba(255, 80, 0, 1)", fontWeight: "bold" }}
        >
          Kategoriler
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title
              id="offcanvasNavbarLabel"
              style={{ color: "#007bff" }}
            >
              <i className="fa-duotone fa-icons"></i> {""}
              Kategoriler
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3 text-center">
              <Nav.Link
                href="#"
                className="d-flex flex-column align-items-center"
              >
                <i
                  className="fa-duotone fa-table-cells-large fa icon-horizontal-spin"
                  style={{ fontSize: "50px", color: "#007bff" }}
                />
                <p
                  className="mt-2"
                  style={{
                    color: "rgba(255, 80, 0, 1)",
                    fontWeight: "bold",
                  }}
                >
                  Tümü
                </p>
              </Nav.Link>
              <Nav.Link
                href="#"
                className="d-flex flex-column align-items-center"
              >
                <i
                  className="fa-duotone fa-earth-europe icon-horizontal-spin"
                  style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
                />
                <p
                  className="mt-2"
                  style={{ color: "#007bff", fontWeight: "bold" }}
                >
                  Yurt Dışı Ürünleri
                </p>
              </Nav.Link>
              <Nav.Link
                href="#"
                className="d-flex flex-column align-items-center"
              >
                <i
                  className="fa-duotone fa-laptop icon-horizontal-spin"
                  style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
                />
                <p
                  className="mt-2"
                  style={{ color: "#007bff", fontWeight: "bold" }}
                >
                  Bilgisayar
                </p>
              </Nav.Link>
              <Nav.Link
                href="#"
                className="d-flex flex-column align-items-center"
              >
                <i
                  className="fa-duotone fa-screwdriver-wrench icon-horizontal-spin"
                  style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
                />
                <p
                  className="mt-2"
                  style={{ color: "#007bff", fontWeight: "bold" }}
                >
                  Yapı Market
                </p>
              </Nav.Link>
              <Nav.Link
                href="#"
                className="d-flex flex-column align-items-center"
              >
                <i
                  className="fa-duotone fa-kitchen-set icon-horizontal-spin"
                  style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
                />
                <p
                  className="mt-2"
                  style={{ color: "#007bff", fontWeight: "bold" }}
                >
                  Mutfak Ürünleri
                </p>
              </Nav.Link>
              <Nav.Link
                href="#"
                className="d-flex flex-column align-items-center"
              >
                <i
                  className="fa-duotone fa-truck-monster icon-horizontal-spin"
                  style={{ fontSize: "50px", color: "rgba(255, 80, 0, 1)" }}
                />
                <p
                  className="mt-2"
                  style={{ color: "#007bff", fontWeight: "bold" }}
                >
                  Motorlu Araç Parçaları
                </p>
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
}
