import React, { useRef, useState } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";

import "./userChangePassword.css";
import useDidMountEffect from "../../utils/useDidMountEffect";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import PasswordMeter from "../PasswordMeter/PasswordMeter";

export default function UserChangePassword(props) {
  const { t } = useTranslation();

  const formRef = useRef();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newAgainPassword, setNewAgainPassword] = useState("");

  const [isLoading, setLoading] = useState(false);

  const [isCurrentPasswordMatched, setCurrentPasswordMatched] = useState(false);
  const [isNewPasswordsMatched, setNewPasswordsMatched] = useState(false);

  const handleProcess = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (formRef.current.checkValidity() === false) {
      //formRef.current.classList.add('was-validated');
      return false;
    }

    const formData = new FormData(formRef.current);

    for (let input of formData) {
      console.log("input", input);
    }

    const response = await new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log("formData", formData);
        resolve(true);
      }, 1000);
    });

    setLoading(false);
  };

  const handleCurrentPassword = (event) => {
    setOldPassword(event.target.value);
    setCurrentPasswordMatched(oldPassword === "4700496Es...");
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleAgainPassword = (event) => {
    setNewAgainPassword(event.target.value);
    setNewPasswordsMatched(newPassword === newAgainPassword);
  };

  return (
    <SideBar>
      <form
        ref={formRef}
        id="userProfileForm"
        className="row was-validated"
        noValidate
        onSubmit={handleProcess}
      >
        <h3 className="my-4">Şifre Değiştir</h3>

        <div className="col-lg-12 col-12 mb-3">
          <label className="mb-1 ms-3">Mevcut Şifre</label>
          <input
            className="buyrun-form-control form-control"
            type="password"
            name="password[current]"
            minLength={8}
            maxLength={16}
            required={true}
            placeholder="KQPweLLWBe"
            onKeyDown={handleCurrentPassword}
          />
          <div class="invalid-feedback">Please provide current password</div>
        </div>

        <p className="text-secondary">
          Passwords must be 8 at least and at most 32 characters that is
          contains.
        </p>

        <div className="col-lg-6 col-12 mb-3">
          <label className="mb-1 ms-3">Yeni Şifre</label>
          <input
            className="buyrun-form-control form-control"
            type="password"
            name="password[new]"
            minLength={8}
            maxLength={16}
            required={true}
            placeholder="XyqHcKJ1ha"
            onKeyDown={handleNewPassword}
          />
          <div class="invalid-feedback">Please provide new password</div>
        </div>

        <div className="col-lg-6 col-12 mb-3">
          <label className="mb-1 ms-3">Şifre tekrarı</label>
          <input
            className="buyrun-form-control form-control"
            type="password"
            name="password[again]"
            minLength={8}
            maxLength={16}
            required={true}
            placeholder="XyqHcKJ1ha"
            onKeyDown={handleAgainPassword}
          />
          <div class="invalid-feedback">Please provide new password again</div>
        </div>

        <PasswordMeter value={newPassword} />

        <div className="col-lg-12">
          <div className="d-flex flex-column align-items-end">
            <div>
              {((isCurrentPasswordMatched && isNewPasswordsMatched) === true) && (
                <p className="text-danger">Şifreler uyumsuz!</p>
              )}
            </div>
            <button
              type="submit"
              className="w-25 btn btn-primary"
              disabled={(isCurrentPasswordMatched && isNewPasswordsMatched) === true}
            >
              <i className="fa-solid fa-check me-2"></i>Kaydet
            </button>
          </div>
        </div>
      </form>
    </SideBar>
  );
}
