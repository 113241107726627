import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* import LanguageSwitcher from "../Languages/LanguageSwitcher" */
import OAuth from "../OAuth/OAuth";
/* import LanguageSwitcher from "../LanguageSwitcher"; */

import styles from "./register.module.css";

// useUSER hook'u auth0 ile kullanılmaktadır
// auth0 ile kayıt olan kullanıcının bilgilerini tutar...
/* import { useUser } from "@auth0/nextjs-auth0/client"; */

import API from "../../utils/api";

import useDidMountEffect from "../../utils/useDidMountEffect";

import PasswordMeter from "../PasswordMeter/PasswordMeter";
import LanguageSwitcher from "../LanguageSwitcher";
import { LoginAgreement, remoteFile, renderLoginAgreement } from "../../utils/funcs";
import SeoLayer from "../SeoLayer/SeoLayer";

const RegisterPage = () => {
  // useRouter ile sayfa root yönledirmesi yapacağız...
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  /*  const clickHandleLanguage = (lang) => {
    startTransition(() => {
      i18n.changeLanguage(lang).catch((error) => {
        console.error('Dil değiştirme hatası:', error);
      });
    });
  }; */

  const clickHandleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  //Form Verilerini useState ile tutuyoruz
  const [formData, setFormData] = useState({
    //firstname: "",
    //lastname: "",
    fullname: "",
    username: "",
    email: "",
    password: "",
  });

  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [informedAboutCampaigns, setInformedAboutCampaigns] = useState(false);

  const handlePrivacyPolicyChange = (e) => {
    setAcceptPrivacyPolicy(e.target.checked);
    console.warn(acceptPrivacyPolicy);
  };

  const handleCampaignsChange = (e) => {
    setInformedAboutCampaigns(e.target.checked);
    console.warn(informedAboutCampaigns);
  };

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [invalidFullname, setInvalidFullname] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateFullname = (fullname) => /^.+$/.test(fullname);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password) => /^.+$/.test(password);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "fullname") {
      setInvalidFullname(false);
    }

    if (name === "email") {
      setInvalidEmail(false);
    }

    if (name === "password") {
      setInvalidPassword(false);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setInvalidFullname(false);
    setInvalidEmail(false);

    if (!validateFullname(formData.fullname)) {
      setInvalidFullname(true);
      return;
    }

    if (!validateEmail(formData.email)) {
      setInvalidEmail(true);
      return;
    }
    
    if (!validatePassword(formData.password)) {
      setInvalidPassword(true);
      return;
    }

    try {
      setLoading(true);
      setIsDisabled(true);
      setError("");

      const res = await API.post(
        "/users/create",
        formData
      );

      navigate("/auth/sign-in");
    } catch (error) {
      setError(error.response.data.message);
      console.error("Registration Failed", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.main}>
      <SeoLayer
        title={t("seo_layer.sign_up.title")}
        description={t("seo_layer.sign_up.description")}
        endpoint="auth/sign-up"
      />
      <svg width="0" height="0">
        <defs>
          <clipPath id="clip-path-left" clipPathUnits="objectBoundingBox">
            <polygon points="0 0, 1 0.38, 1 0.84, 0.65 1, 0 1" />
          </clipPath>
          <clipPath id="clip-path-right" clipPathUnits="objectBoundingBox">
            <polygon points="0 0.39, 1 0, 1 1, 0.32 1, 0 0.85" />
          </clipPath>
        </defs>
      </svg>

      <div className={styles.customPartLeft1a}></div>
      <div className={styles.customPartLeft1b}>
        <div className={styles.customPartLeft1bIn}></div>
      </div>

      <div className={styles.customPartRight1a}></div>
      <div className={styles.customPartRight1b}>
        <div className={styles.customPartRight1bIn}></div>
      </div>
      {/* <h3 style={{ marginTop: "20px", fontWeight: "bold" }}>
              <span style={{ color: "rgb(255, 80, 0)" }}>BUY</span>
              <span style={{ color: "rgb(21, 124, 252)" }}>.RUN</span>
            </h3> */}
      <div className={styles.card}>
        <div className={styles.cardInner}>
          <div className="d-flex flex-column justify-content-center align-items-center mb-4">
            <img
              src={remoteFile("/statics/assets/img/buyrunLogo.svg")}
              alt="Logo"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                zIndex: "10",
              }}
            />
            <div class="header-logoText" title="buyrun, buy.run">
              <span class="header-logoText-orange">BUY</span>
              <span class="header-logoText-blue">.RUN</span>
            </div>
          </div>

          <div className={styles.languageOption}>
            <LanguageSwitcher />
          </div>

          {/*  <LanguageSwitcher/> */}

          {loading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"> ⌛ </span>
              </div>
            </div>
          )}

          <form id="form" onSubmit={handleSubmit}>
            <input
              id="fullname"
              className={"mb-3 form-control " + styles["form-control"]}
              type="text"
              placeholder={t("fullname_placeholder")}
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
            />
            {invalidFullname && (
              <div
                className={`${styles.invalidFeedback} ${styles.invalidFeedbackActive}`}
              >
                <p>{t("invalid_fullname")}</p>
              </div>
            )}
            <input
              id="email"
              className={"mb-3 form-control " + styles["form-control"]}
              type="email"
              placeholder={t("email_placeholder")}
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {invalidEmail && (
              <div
                className={`${styles.invalidFeedback} ${styles.invalidFeedbackActive}`}
              >
                <p>{t("invalid_email")}</p>
              </div>
            )}

            <div className="input-group">
              <input
                id="password"
                className={"form-control " + styles["form-control"]}
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                placeholder={t("password_placeholder")}
                onChange={handleChange}
              />
              <button
                type="button"
                className="btn btn-dark"
                onClick={togglePasswordVisibility}
                style={{ border: "none" }}
              >
                <i
                  className={`bi ${
                    !showPassword ? "bi-eye-slash-fill" : "bi-eye-fill"
                  }`}
                ></i>
              </button>
            </div>
            {invalidPassword && (
              <div
                className={`${styles.invalidFeedback} ${styles.invalidFeedbackActive}`}
              >
                <p>{t("invalid_password")}</p>
              </div>
            )}

            <PasswordMeter value={formData.password} style={{ marginTop: 35}} />

            <button
              className={`${styles.btnRegister} ${styles.button} btn-register`}
              type="submit"
              disabled={isDisabled}
            >
              <i class="fa-duotone fa-user-plus me-1"></i>
              {t("register_button")}
            </button>
            {isDisabled && (
              <div>
                {/* <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div> */}
                <p
                  style={{
                    color: "rgb(21, 124, 253)",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  İşleminiz yapılıyor...
                </p>
              </div>
            )}
          </form>

          <div className={styles.socialButtons}>
            <OAuth />
            {error && (
              <p>
                <span
                  style={{
                    color: "rgb(255, 80, 0)",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {error}
                </span>
              </p>
            )}
          </div>

          <LoginAgreement />

          <div className="d-flex border-top justify-content-between mt-3 pt-3">
            <Link className={styles.link} to="/auth/sign-in">
              <span style={{ color: "rgb(21, 124, 253)" }}>
                {t("sign_in")}
                <i class="fa-duotone fa-arrow-right-to-arc ms-1"></i>
              </span>
            </Link>
            <Link className={styles.link} to="/auth/forgot-password">
              <span style={{ color: "rgb(234, 88, 12)" }}>
                <i class="fa-duotone fa-lock me-1"></i>
                {t("forgot_password")}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
