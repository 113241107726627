import React from "react";
import Categories from "../components/Categories";

export default function CategoriesPage() {
  return (
    <div>
      <Categories />
    </div>
  );
}
