import React from "react";
import { Helmet } from "react-helmet";

const SeoLayer = ({ title, description, endpoint }) => {
  const url = `https://buy.run/${endpoint}`;
  const imageUrl = "https://cdn.buy.run/statics/assets/img/buyrunLogo.svg";

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": title,
    "url": url,
    "description": description,
    "publisher": {
      "@type": "Organization",
      "name": "Buy.Run",
      "logo": {
        "@type": "ImageObject",
        "url": imageUrl
      }
    }
  };

  return (
    <Helmet>
      <title>{`${title} - Buy.Run - BuyRun`}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={`${title} - Buy.Run - BuyRun`} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${title} - Buy.Run - BuyRun`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={imageUrl} />
      
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default SeoLayer;
