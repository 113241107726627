// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC5N-TV_n72l2uc9RI67aG1xtfrQ6jddOA",
    authDomain: "buyrundev.firebaseapp.com",
    projectId: "buyrundev",
    storageBucket: "buyrundev.appspot.com",
    messagingSenderId: "828021593150",
    appId: "1:828021593150:web:159b0e484eb58a85102d6f"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);