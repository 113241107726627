import React, { useRef, useState } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";

import "./profile.css";
import useDidMountEffect from "../../utils/useDidMountEffect";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";

export default function Profile(props) {
  const { t } = useTranslation();

  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);

  const handleProcess = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (formRef.current.checkValidity() === false) {
      //formRef.current.classList.add('was-validated');
      return false;
    }

    const formData = new FormData(formRef.current);

    for (let input of formData) {
      console.log("input", input);
    }

    const response = await new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log("formData", formData);
        resolve(true);
      }, 1000);
    });

    setLoading(false);
  };

  useDidMountEffect(() => {}, []);

  return (
    <SideBar>
      <form
        ref={formRef}
        id="userProfileForm"
        className="row was-validated"
        noValidate
        onSubmit={handleProcess}
      >
        <h3 className="my-4">Profil Bilgileri</h3>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">Ad Soyad</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="fullname"
            placeholder="Ali Işık Gümüş"
            pattern="(.+)\s+(.+\s+)?(.+)"
            required={true}
          />
          <div class="invalid-feedback">Please type your fullname</div>
        </div>

        <div className="row mb-4 align-items-center">
          <div className="col-5">
            <label
              className="buyrun-form-label form-label"
              htmlFor="profile-phoneAreaCode"
            >
              {t("area_code")}
            </label>
            <select
              className="buyrun-form-select form-select"
              id="profile-phoneAreaCode"
              name="phoneAreaCode"
              required
            >
              <option value="-1">{t("area_code_placeholder")}</option>
            </select>
          </div>
          {/* <!-- area code --> */}
          <div className="col">
            <label
              className="buyrun-form-label form-label"
              htmlFor="profile-phone"
            >
              {t("authorized_phone")}
            </label>
            <input
              type="tel"
              className="buyrun-form-control form-control"
              id="profile-phone"
              placeholder="555 000 00 00"
              name="phone"
              required
            />
          </div>
          {/*  <!-- area code --> */}
        </div>
        {/* <!-- input --> */}

        <div className="col-lg-4 mb-3">
          <label className="mb-1">Gün</label>
          <select
            className="buyrun-form-control form-select"
            name="birthday[day]"
            required={true}
          >
            <option value="">Gün seç</option>
            <option value="01" selected>
              01
            </option>
          </select>
        </div>

        <div className="col-lg-4 mb-3">
          <label className="mb-1">Ay</label>
          <select
            className="buyrun-form-control form-select"
            name="birthday[month]"
            required={true}
          >
            <option value="">Ay seç</option>
            <option value="01" selected>
              01
            </option>
          </select>
        </div>

        <div className="col-lg-4 mb-3">
          <label className="mb-1">Yıl</label>
          <select
            className="buyrun-form-control form-select"
            name="birthday[year]"
            required={true}
          >
            <option value="">Yıl seç</option>
            <option value="1970" selected>
              1970
            </option>
          </select>
        </div>

        <div className="col-lg-12 mb-3">
          <p>Cinsiyet</p>
          <label className="form-check-label me-4">
            <input
              className="form-check-input me-2"
              type="radio"
              name="gender"
              value="male"
              defaultChecked={true}
            />
            Bay
          </label>

          <label className="form-check-label me-4">
            <input
              className="form-check-input me-2"
              type="radio"
              name="gender"
              value="female"
              defaultChecked={true}
            />
            Bayan
          </label>

          <label className="form-check-label">
            <input
              className="form-check-input me-2"
              type="radio"
              name="gender"
              value="none"
              defaultChecked={true}
            />
            Belirtmek istemiyorum
          </label>
        </div>

        <div className="col-lg-12">
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              <i className="fa-solid fa-check me-2"></i>Kaydet
            </button>
          </div>
        </div>
      </form>
    </SideBar>
  );
}
