import React from "react";
import ApiIntegration from "../components/ApiIntegration";

function ApiIntegrationPage() {
  return (
    <> 
        <ApiIntegration />
    </>
  );
}

export default ApiIntegrationPage;
