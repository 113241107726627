import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./language-switcher.module.css";
import {
  availableCurrencies,
  changeFormat,
  changeLanguage,
} from "../../context/feature/Currency/currencySlice";

import { useDispatch, useSelector } from "react-redux";

function LanguageSwitcher(props) {
  const { i18n } = useTranslation();

  const currency = useSelector((state) => state.currency);
  const dispatch = useDispatch();

  const changeLanguage = useCallback(
    (countryCode) => {
      dispatch(changeFormat({ countryCode: countryCode }));
      i18n.changeLanguage(countryCode);
    },
    [dispatch, i18n]
  );

  useEffect(() => {
    if (currency.default === true) {
      let userLanguage = navigator.language || navigator.userLanguage;
      if (userLanguage === "tr") {
        userLanguage = "tr-TR";
      }
      changeLanguage(userLanguage);
      console.log("%cDil tarayıcıya göre ayarlandı", "color:purple;");
    } else {
      changeLanguage(currency.countryCode);
      console.log("%cDil kullanıcı seçimine göre ayarlandı", "color:purple;");
    }
  }, []);

  function renderSelectOptions() {
    return availableCurrencies.map((e) => (
      <option
        style={{ color: "rgb(234, 88, 12)" }}
        value={e.countryCode}
        selected={e.countryCode === currency.countryCode}
      >
        {e.language}
      </option>
    ));
  }

  return (
    <div className={styles.languageOption}>
      <label htmlFor="language" style={{ color: "rgb(234, 88, 12)" }}>
        <i class="fa-duotone fa-globe me-1"></i>
      </label>
      <select
        name="language"
        id="language"
        onChange={(e) => changeLanguage(e.target.value)}
        className={props.className}
        style={props.style}
      >
        {renderSelectOptions()}
      </select>
    </div>
  );
}

export default LanguageSwitcher;
