import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const ProductSellCard = ({ product }) => {
  if (!product) return <div>Loading...</div>;

  function truncateDescription(description) {
    if (!description) return "";
    const words = description.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + "...";
    }
    return description;
  }

  const truncatedDescription = truncateDescription(product.name);

  const renderStars = (score) => {
    let stars = [];
    for (let i = 0; i < Math.floor(score); i++) {
      stars.push(<i key={i} className="bi bi-star-fill text-warning"></i>);
    }
    // For half stars
    if (score % 1 !== 0) {
      stars.push(
        <i key={stars.length} className="bi bi-star-half text-warning"></i>
      );
    }
    return stars;
  };

  return (
    <div className="col sm-6 col-md-4 col-lg-3 my-3">
      <div className="card h-100 shadow-sm">
        <img
          src={product.image}
          className="card-img-top"
          alt={product.name}
          style={{ objectFit: "cover", height: "200px" }}
        />
        <div className="card-body d-flex flex-column">
          <h5
            className="card-title"
            style={{ fontSize: "16px", fontWeight: "bold", color: "#007bff" }}
          >
            {truncatedDescription}
          </h5>
          <p className="card-text mb-1" style={{ fontSize: "14px" }}>
            {renderStars(product.score)}
            <span className="text-muted" style={{ fontSize: "13px" }}>
              {" "}
              ({product.sales} satıldı)
            </span>
          </p>
          <h5 className="card-title text-primary" style={{ fontSize: "20px" }}>
            {product.price} TL
          </h5>
          <p className="text-success" style={{ fontSize: "13px" }}>
            Sevkiyat Tercihleri
          </p>
          <div className="d-flex justify-content-between align-items-center mt-auto">
            <button
              className="btn btn-outline-secondary"
              style={{
                borderRadius: "50%",
                width: "36px",
                height: "36px",
                padding: "0",
              }}
            >
              <i className="bi bi-cart"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSellCard;
