//import axios from "axios";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import styles from "../Login/login.module.css";
import "./forgotPassword.css";
import API from "../../utils/api";

import Swal from "sweetalert2";
import LanguageSwitcher from "../LanguageSwitcher";
import { remoteFile } from "../../utils/funcs";
import SeoLayer from "../SeoLayer/SeoLayer";

const ForgotPassword = () => {
  const { t } = useTranslation();

  const formRef = useRef();
  const emailRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  async function handleFormSubmit() {
    const isEmail = /^[a-zA-Z0-9._\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$/.test(
      emailRef.current.value
    );

    if (formRef.current.checkValidity() === false || isEmail === false) {
      formRef.current.classList.add("was-validated");
      return false;
    }

    setIsLoading(true);

    try {
      const response = await API.post(
        "/users/forgotPassword",
        { email: emailRef.current.value },
        {
          timeout: 30000,
        }
      );

      if (response.data.resetTokenResult === false) {
        throw new Error(t("forgot_password_page.error_while_reset"));
      }

      if (response.data.isSent === false) {
        throw new Error(t("forgot_password_page.didnt_mail_send"));
      }

      Swal.fire({
        title: t("forgot_password_page.mail_sent"),
        text: t("forgot_password_page.mail_sent_reset_link"),
        icon: "success",
        confirmButtonText: t("swal.button.confirm"),
      });

      setIsSent(response.data.isSent);
    } catch (error) {
      Swal.fire({
        title: t("forgot_password_page.didnt_mail_send"),
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
      setIsSent(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.main}>
      <SeoLayer
        title={t("seo_layer.forgot_password.title")}
        description={t("seo_layer.forgot_password.description")}
        endpoint="auth/forgot-password"
      />
      <svg width="0" height="0">
        <defs>
          <clipPath id="clip-path-left" clipPathUnits="objectBoundingBox">
            <polygon points="0 0, 1 0.38, 1 0.84, 0.65 1, 0 1" />
          </clipPath>
          <clipPath id="clip-path-right" clipPathUnits="objectBoundingBox">
            <polygon points="0 0.39, 1 0, 1 1, 0.32 1, 0 0.85" />
          </clipPath>
        </defs>
      </svg>

      <div className={styles.customPartLeft1a}></div>
      <div className={styles.customPartLeft1b}>
        <div className={styles.customPartLeft1bIn}></div>
      </div>

      <div className={styles.customPartRight1a}></div>
      <div className={styles.customPartRight1b}>
        <div className={styles.customPartRight1bIn}></div>
      </div>

      {/*  <h3 style={{ marginTop: "20px", fontWeight: "bold" }}>
              <span style={{ color: "rgb(234, 88, 12)" }}>BUY</span>
              <span style={{ color: "#0d6efd" }}>.RUN</span>
            </h3> */}

      <div className={styles.card}>
        <div className={styles.cardInner}>
          <div className="d-flex flex-column justify-content-center align-items-center mb-4">
            <img
              src={remoteFile("/statics/assets/img/buyrunLogo.svg")}
              alt="Logo"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                zIndex: "10",
              }}
            />
            <div class="header-logoText" title="buyrun, buy.run">
              <span class="header-logoText-orange">BUY</span>
              <span class="header-logoText-blue">.RUN</span>
            </div>
          </div>

          <div className={styles.languageOption}>
            <LanguageSwitcher />
          </div>

          <form
            id="forgotPasswordForm"
            name="forgotPasswordForm"
            ref={formRef}
            noValidate
            autoComplete="off"
          >
            {isSent === true && (
              <p className="alert alert-success">
                {t("forgot_password_page.check_mailbox")}
              </p>
            )}

            {isSent === false && (
              <>
                <div className="col-lg-12 mb-3">
                  <input
                    ref={emailRef}
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder={t("email_placeholder")}
                    pattern="^[a-zA-Z0-9._\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$"
                    required={true}
                    disabled={isLoading}
                  />
                  <div class="invalid-feedback">
                    {t("forgot_password_page.invalid_mail")}
                  </div>
                </div>

                <button
                  className="btn btn-primary w-100"
                  type="button"
                  variant="primary"
                  onClick={handleFormSubmit}
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    <span>
                      <i className="fa-duotone fa-sync me-1"></i>
                      {t("forgot_password_page.text_send_reset_link")}
                    </span>
                  ) : (
                    t("forgot_password_page.text_sending_reset_link")
                  )}
                </button>
              </>
            )}
          </form>

          <div className="d-flex border-top justify-content-between mt-3 pt-3">
            <Link className={styles.link} to="/auth/sign-in">
              <span style={{ color: "rgb(21, 124, 253)" }}>
                {t("sign_in")}
                <i class="fa-duotone fa-arrow-right-to-arc ms-1"></i>
              </span>
            </Link>
            <Link className={styles.link} to="/auth/sign-up">
              <span style={{ color: "rgb(234, 88, 12)" }}>
                <i class="fa-duotone fa-user-plus me-1"></i>
                {t("register_button")}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
