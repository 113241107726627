import React, { Suspense } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";

import {
  Home,
  About,
  Contact,
  SignUp,
  SignIn,
  SupplierProfile,
  ManualProductAdd,
  ProductDetail,
  ApiIntegrationPage,
  CategoriesPage,
  UserProfile,
  Refund,
  UserChangePassword,
  ApiKeyGeneratorPage,
  ForgotPassword,
  ResetPassword,
  SupplierStore,
  Faq,
  NotFound
} from "./pages";

import PrivateRoute from "./components/PrivateRoute";
import SupplierPrivateRoute from "./components/SupplierPrivateRoute";

import Cart from "./components/Cart";
import LoadingSkeleton from "./components/Skeleton/LoadingSkeleton";

function AuthLayoutRoutes() {
  return (
    <AuthLayout>
      <Routes>
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<Navigate replace to="/not-found" />} />
      </Routes>
    </AuthLayout>
  );
}

function MainLayoutRoutes() {
  return (
    <MainLayout>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/categories" element={<CategoriesPage />} />

        <Route element={<PrivateRoute />}>
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/users/profile" element={<UserProfile />} />
          <Route path="/users/refund" element={<Refund />} />
          <Route path="/users/changePassword" element={<UserChangePassword />} />
          <Route path="/product" element={<ProductDetail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/contact" element={<Contact />} />
        </Route>

        <Route element={<SupplierPrivateRoute />}>
          <Route path="/supplier" element={<SupplierProfile />} />
          <Route path="/supplier/store" element={<SupplierStore />} />
          <Route path="/supplier/api-key-generator" element={<ApiKeyGeneratorPage />} />
          <Route path="/supplier/manual-product-add" element={<ManualProductAdd />} />
          <Route path="/supplier/api-integration" element={<ApiIntegrationPage />} />
        </Route>

        <Route path="*" element={<Navigate replace to="/not-found" />} />
      </Routes>
    </MainLayout>
  );
}

export default function App() {
  return (
    <Router>
      <Suspense fallback={<LoadingSkeleton />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/auth/sign-in" />} />
          <Route path="/*" element={<MainLayoutRoutes />} />
          <Route path="/auth/*" element={<AuthLayoutRoutes />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
