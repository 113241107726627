import styles from "./oauth.module.css";

import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { app } from "../../firebase";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import axios from "axios";

import {
  signInSuccess,
  signInFailure,
  signOut,
} from "../../context/feature/User/userSlice";

import { useTranslation } from "react-i18next";

export default function OAuth() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoogleClick = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithPopup(auth, provider);
      console.log("result", result);

      const response = await axios.post("https://buy.run/api/v1/users/google", {
        name: result.user.displayName,
        email: result.user.email,
        photo: result.user.photoURL,
      });

      dispatch(signInSuccess(response.data.result));
      console.log("Yanıt:", response);
      navigate("/supplier");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.socialButtons}>
      <button
        className={`${styles.socialButtonsCustom} ${styles.button}`}
        onClick={handleGoogleClick}
      >
        {t("continue_with_google")}
        <i class="fa-duotone fa-arrow-right-to-arc ms-1"></i>
      </button>
    </div>
  );
}
