import "./header.css";

import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { filterProductsBySearchTerm } from "../../context/feature/Search/searchSlice";

import SearchBar from "../SearchBar";
import LanguageSwitcher from "../LanguageSwitcher";
import { HeaderUserPanel } from "../ProfileBar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HeaderSearchBar from "../HeaderSearchBar";
import { remoteFile } from "../../utils/funcs";

const Header = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleSearch = (term) => {
    dispatch(filterProductsBySearchTerm(term));
  };

  const productsLength = useSelector((state) => state.cart.products.length);

  return (
    <header id="topHeader" className="topHeader p-4 shadow">
      <div className="container">
        <nav className="header-top d-flex justify-content-end mb-3">
          <NavLink className="header-topLink me-5" to="/about">
            <i class="fa-duotone fa-pen-swirl me-1"></i>
            {t("header.about")}
          </NavLink>
          <NavLink className="header-topLink me-5" to="/supplier">
            <i class="fa-duotone fa-user-plus me-1"></i>
            {t("header.supplier.become")}
          </NavLink>
          <NavLink className="header-topLink me-5" to="/faq">
            <i class="fa-duotone fa-badge-check me-1"></i>
            {t("header.questions")}
          </NavLink>
          <NavLink className="header-topLink me-5" to="/contact">
            <i class="fa-duotone fa-paper-plane me-1"></i>
            {t("header.contact")}
          </NavLink>
          <div className="header-topLink ">
            <LanguageSwitcher
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#ff5000",
              }}
            />
          </div>
        </nav>
        {/* header top */}

        <div className="header d-flex align-items-end">
          <div className="header-logo">
            <img
              className="header-logoImg"
              src={remoteFile("/statics/assets/img/buyrunLogo.svg")}
              width={60}
            />
            <div className="header-logoText">
              <span className="header-logoText-orange">BUY</span>
              <span className="header-logoText-blue">.RUN</span>
            </div>
          </div>
          {/* logo end */}
          <div className="header-search mx-5 w-100">
            <div className="d-inline-flex mb-3">
              {/* <NavLink className="header-topLink-social me-4" to="/about">
                <i class="fa-brands fa-facebook-f"></i>
              </NavLink> */}
              <NavLink
                className="header-topLink-social me-4"
                target="_blank"
                to="https://www.linkedin.com/company/buyrun/"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </NavLink>
              <NavLink
                className="header-topLink-social me-4"
                target="_blank"
                to="https://twitter.com/buyrunofficial"
              >
                <i class="fa-brands fa-x-twitter"></i>
              </NavLink>
              <NavLink
                className="header-topLink-social me-4"
                target="_blank"
                to="https://www.youtube.com/@buyrunofficial"
              >
                <i class="fa-brands fa-youtube"></i>
              </NavLink>
              <NavLink
                className="header-topLink-social me-4"
                target="_blank"
                to="https://instagram.com/buyrunofficial"
              >
                <i class="fa-brands fa-instagram"></i>
              </NavLink>
            </div>
            {/* social media links */}
            <HeaderSearchBar onSearch={handleSearch} />
          </div>
          {/* search end */}

          <nav className="d-inline-flex header-user">
            <div className="header-userItem header-userItem-panel d-inline-flex flex-column align-items-center w-100 mr-3">
              <span class="header-userItem-img">
                <i class="fa-duotone fa-user"></i>
              </span>
              <HeaderUserPanel />
            </div>
            {/*
            <Link
              to="/users/favorites"
              className="header-userItem header-userItem-favorites d-inline-flex flex-column align-items-center w-100 mr-3"
            >
              <span class="header-userItem-img">
                <i class="fa-solid fa-heart"></i>
              </span>
              {t("header.favorites")}
            </Link>
            <Link
              to="/cart"
              className="header-userItem header-userItem-cart d-inline-flex flex-column align-items-center w-100"
            >
              <span class="header-userItem-img">
                <i class="header-userItem-cartCount">{productsLength}</i>
                <i class="fa-solid fa-shopping-bag"></i>
              </span>
              {t("header.cart")}
            </Link>
            */}
          </nav>
          {/* user panel end */}
        </div>
        {/* header end */}
      </div>
    </header>
  );
};

export default Header;
