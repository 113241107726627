import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        activeLink: '/',
        links: [
            { path: '/hesabim', label: 'Hesabım' },
            { path: '/yardim', label: 'Hesap & Yardım' },
            { path: '/profil', label: 'Kullanıcı Profili' },
            { path: '/invent-api-entegre', label: 'API Entegrasyon' }
        ]
    },
    reducers: {
        setActiveLink: (state, action) => {
            state.activeLink = action.payload;
        }
    }
});

export const { setActiveLink } = navigationSlice.actions;
export default navigationSlice.reducer;
