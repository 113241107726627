//import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import styles from "../Login/login.module.css";
import "./resetPassword.css";
import API from "../../utils/api";

import Swal from "sweetalert2";
import PasswordMeter from "../PasswordMeter/PasswordMeter";
import useDidMountEffect from "../../utils/useDidMountEffect";
import LanguageSwitcher from "../LanguageSwitcher";
import { remoteFile } from "../../utils/funcs";
import SeoLayer from "../SeoLayer/SeoLayer";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();

  const clickHandleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const navigate = useNavigate();
  const { token } = useParams();

  const formRef = useRef();

  const passwordRef = useRef();
  const passwordAgainRef = useRef();

  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isValidated, setIsValidated] = useState(null);
  const [changeToken, setChangeToken] = useState(false);

  async function handleFormSubmit() {
    if (formRef.current.checkValidity() === false) {
      formRef.current.classList.add("was-validated");
      return false;
    }

    if (
      passwordRef.current.value.length === 0 ||
      passwordAgainRef.current.value.length === 0
    ) {
      Swal.fire({
        title: t("forgot_password.update_error"),
        text: t("forgot_password.cannot_empty"),
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
      return false;
    }

    if (passwordRef.current.value !== passwordAgainRef.current.value) {
      Swal.fire({
        title: t("forgot_password.update_error"),
        text: t("forgot_password.must_be_identical"),
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
      return false;
    }

    setIsLoading(true);

    try {
      const response = await API.post(
        "/users/changePassword",
        {
          token: changeToken,
          password: passwordRef.current.value,
        },
        {
          timeout: 30000,
        }
      );
      Swal.fire({
        title: t("forgot_password.change_successful"),
        text: t("forgot_password.change_successful_info"),
        icon: "success",
        confirmButtonText: t("swal.button.confirm"),
      });
      setIsChanged(response.data.isChanged);
      //navigate("/");
    } catch (error) {
      Swal.fire({
        title: "Şifre güncelleme başarısız",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: t("swal.button.confirm"),
      });
      setIsChanged(false);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        if (!token.startsWith("ey")) {
          throw new Error("Geçersiz token");
        }

        const response = await API.post(
          "/users/validResetPasswordToken",
          { token: token },
          { timeout: 30000 }
        );

        if (response.data.isValidated) {
          setIsValidated(true);
          setChangeToken(response.data.token);
        } else {
          setIsValidated(false);
        }
      } catch (error) {
        setIsValidated(false);
        console.log(error.message);
      }
    };

    checkTokenValidity();
  }, []);

  function changePasswordForm() {
    return (
      <form
        id="changePasswordForm"
        name="changePasswordForm"
        ref={formRef}
        noValidate
        autoComplete="off"
      >
        {isChanged === true && (
          <>
            <p className="alert alert-success">
              {/* Şifreniz başarılı bir şekilde değiştirildi. Yeni şifrenizi
              kullanarak giriş yapabilirsiniz. */}
              {t("forgot_password.change_successful_info")}
            </p>
            <Link className={styles.link} to="/auth/sign-in">
              <span style={{ color: "rgb(21, 124, 253)" }}>
                {t("sign_in")}
                <i class="fa-duotone fa-arrow-right-to-arc ms-1"></i>
              </span>
            </Link>
          </>
        )}

        {isChanged === false && (
          <>
            <div className="col-lg-12 mb-3">
              <input
                ref={passwordRef}
                className="form-control"
                type="password"
                name="password"
                placeholder={t("password_placeholder")}
                disabled={isLoading}
                required={true}
                onKeyUp={(event) => setPassword(event.target.value)}
              />
              <div className="invalid-feedback">Geçersiz şifre</div>
            </div>

            <div className="col-lg-12 mb-3">
              <input
                ref={passwordAgainRef}
                className="form-control"
                type="password"
                name="password_again"
                placeholder={t("password_placeholder")}
                disabled={isLoading}
                required={true}
              />
              <div className="invalid-feedback">Geçersiz şifre</div>
            </div>

            <PasswordMeter value={password} style={{ marginTop: 35 }} />

            <button
              className="btn btn-primary w-100"
              type="button"
              variant="primary"
              onClick={handleFormSubmit}
              disabled={isLoading}
            >
              {!isLoading ? (
                <span>
                  <i className="fa-duotone fa-check me-1"></i>Şifremi güncelle
                </span>
              ) : (
                "Şifre güncelleniyor..."
              )}
            </button>
          </>
        )}
      </form>
    );
  }

  return (
    <div className={styles.main}>
      <SeoLayer
        title={t("seo_layer.forgot_password.title")}
        description={t("seo_layer.forgot_password.description")}
        endpoint="auth/reset-password"
      />
      <svg width="0" height="0">
        <defs>
          <clipPath id="clip-path-left" clipPathUnits="objectBoundingBox">
            <polygon points="0 0, 1 0.38, 1 0.84, 0.65 1, 0 1" />
          </clipPath>
          <clipPath id="clip-path-right" clipPathUnits="objectBoundingBox">
            <polygon points="0 0.39, 1 0, 1 1, 0.32 1, 0 0.85" />
          </clipPath>
        </defs>
      </svg>

      <div className={styles.customPartLeft1a}></div>
      <div className={styles.customPartLeft1b}>
        <div className={styles.customPartLeft1bIn}></div>
      </div>

      <div className={styles.customPartRight1a}></div>
      <div className={styles.customPartRight1b}>
        <div className={styles.customPartRight1bIn}></div>
      </div>

      {/*  <h3 style={{ marginTop: "20px", fontWeight: "bold" }}>
              <span style={{ color: "rgb(234, 88, 12)" }}>BUY</span>
              <span style={{ color: "#0d6efd" }}>.RUN</span>
            </h3> */}

      <div className={styles.card}>
        <div className={styles.cardInner}>
          <h4 className="">Şifre değiştirme</h4>

          <div className="d-flex flex-column justify-content-center align-items-center mb-4">
            <img
              src={remoteFile("/statics/assets/img/buyrunLogo.svg")}
              alt="Logo"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                zIndex: "10",
              }}
            />
            <div className="header-logoText" title="buyrun, buy.run">
              <span className="header-logoText-orange">BUY</span>
              <span className="header-logoText-blue">.RUN</span>
            </div>
          </div>

          <div className={styles.languageOption}>
            <LanguageSwitcher />
          </div>

          {isValidated === null && (
            <div className="text-center">
              <div className="progress" role="progressbar">
                <div className="progress-bar progress-bar-striped progress-bar-animated w-100"></div>
              </div>
              <p>
                {/* Doğrulanıyor */} {t("reset_password.verifying")} ...
              </p>
            </div>
          )}

          {isValidated !== null &&
            (isValidated === true ? (
              changePasswordForm()
            ) : (
              <div className="alert alert-danger">
                {/* Geçersiz şifre yenileme linki */}
                {t("reset_password.invalid_reset_link")}
              </div>
            ))}

          {isValidated === false && (
            <div className="d-flex border-top justify-content-between mt-3 pt-3">
              <Link className={styles.link} to="/auth/sign-in">
                <span style={{ color: "rgb(21, 124, 253)" }}>
                  {t("sign_in")}
                  <i class="fa-duotone fa-arrow-right-to-arc ms-1"></i>
                </span>
              </Link>
              <Link className={styles.link} to="/auth/sign-up">
                <span style={{ color: "rgb(234, 88, 12)" }}>
                  <i class="fa-duotone fa-user-plus me-1"></i>
                  {t("register_button")}
                </span>
              </Link>
              <Link className={styles.link} to="/auth/forgot-password">
                <span style={{ color: "rgb(234, 88, 12)" }}>
                  <i class="fa-duotone fa-lock me-1"></i>
                  {t("forgot_password")}
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
