import React, { useEffect, useRef, useState } from "react";
import API from "../../utils/api";

import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import AsyncButton from "../CustomInputs/AsyncButton";

import Swal from "sweetalert2";

import "./about.css";
import SeoLayer from "../SeoLayer/SeoLayer";

export default function About(props) {
  const { t } = useTranslation();

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  /*   useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await API.post("/supplier/products", {
          page: page,
        });
        setProducts(response.data.products);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [page]); */

  return (
    <div
      className="container d-flex justify-content-center mt-3"
      style={{ minHeight: 500 }}
    >
      <SeoLayer
        title={t("seo_layer.about.title")}
        description={t("seo_layer.about.description")}
        endpoint="about"
      />
      <div className="w-50 text-center">
        <h1 className="mt-5 mb-5">{t("about.t1")}</h1>
        <p>{t("about.p1")}</p>
        <h5 className="mt-5">{t("about.t2")}</h5>
        <p>{t("about.p2")}</p>
        <h5 className="mt-5">{t("about.t3")}</h5>
        <p>{t("about.p3")}</p>
        <h5 className="mt-5">{t("about.t4")}</h5>
        <p>{t("about.p4")}</p>
        <h5 className="mt-5">{t("about.t5")}</h5>
        <p>{t("about.p5")}</p>
        <p className="mb-5">{t("about.p6")}</p>
      </div>
    </div>
  );
}
