import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
/* import axios from "axios"; */
import dummyData from "../../../components/Categories/dummyData.json";
import productData from "../../../components/Categories/productData.json";

const initialState = {
  products: [],
  filteredProducts: [],
  noProductsFound: false,
  searchTerm: "",
  filters: {
    searchTerm: "",
    minPrice: null,
    maxPrice: null,
    minStars: null,
    dateRange: null,
    sortOrder: null,
  },
};

/* export const fetchProducts = createAsyncThunk(
  "search/fetchProducts",
  async () => {
    const response = await axios.get("https://fakestoreapi.com/products"); 
    return response.data;
  }
); */

export const fetchProducts = createAsyncThunk(
  "search/fetchProducts",
  async () => {
    return productData;
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetFilters(state) {
      state.filteredProducts = state.products;
      state.noProductsFound = false;
      state.searchTerm = "";
      state.filters = {
        searchTerm: "",
        minPrice: null,
        maxPrice: null,
        minStars: null,
        dateRange: null,
        sortOrder: null,
        category: null,
      };
    },
    filterProductsBySearchTerm(state, action) {
      const searchTerm = action.payload.trim().toLowerCase();
      state.searchTerm = searchTerm;
      state.filteredProducts = state.products.filter((product) => {
        const productName = product.name ? product.name.toLowerCase() : "";
        const productDescription = product.description
          ? product.description.toLowerCase()
          : "";
        return (
          productName.includes(searchTerm) ||
          productDescription.includes(searchTerm)
        );
      });
      state.noProductsFound = state.filteredProducts.length === 0;
    },
    filterProductsByPriceRange(state, action) {
      const { minPrice, maxPrice } = action.payload;
      state.filteredProducts = state.products.filter(
        (product) => product.price >= minPrice && product.price <= maxPrice
      );
      state.noProductsFound = state.filteredProducts.length === 0;
    },
    filterProductsByStars(state, action) {
      const minStars = action.payload;
      state.filteredProducts = state.products.filter(
        (product) => product.score >= minStars
      );
      state.noProductsFound = state.filteredProducts.length === 0;
    },
    filterProductsByDateRange(state, action) {
      const { startDate, endDate } = action.payload;
      state.filteredProducts = state.products.filter((product) => {
        const productDate = new Date(product.publishing_date);
        return productDate >= startDate && productDate <= endDate;
      });
      state.noProductsFound = state.filteredProducts.length === 0;
    },
    filterProductsBySpecificPriceRange(state, action) {
      const { minPriceRange, maxPriceRange } = action.payload;
      state.filteredProducts = state.products.filter(
        (product) =>
          product.price >= minPriceRange && product.price <= maxPriceRange
      );
      state.noProductsFound = state.filteredProducts.length === 0;
    },
    sortProductsByPriceAsc(state) {
      state.filteredProducts.sort((a, b) => a.price - b.price);
    },
    sortProductsByPriceDesc(state) {
      state.filteredProducts.sort((a, b) => b.price - a.price);
    },
    filterByCategory(state, action) {
      const category = action.payload;
      state.filteredProducts = state.products.filter(
        (product) => product.category === category
      );
      state.filters.category = category;
      state.noProductsFound = state.filteredProducts.length === 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.filteredProducts = action.payload;
        state.noProductsFound = state.products.length === 0;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        console.error("Failed to fetch products:", action.error);
      });
  },
});

export const {
  resetFilters,
  filterProductsBySearchTerm,
  filterProductsByPriceRange,
  filterProductsByStars,
  filterProductsByDateRange,
  filterProductsBySpecificPriceRange,
  sortProductsByPriceAsc,
  sortProductsByPriceDesc,
  filterByCategory,
} = searchSlice.actions;

export default searchSlice.reducer;
