import React, { useState, useEffect, useRef, useReducer } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./productCard.css";

import useDidMountEffect from "../../utils/useDidMountEffect";

import IncrementalButton from "../CustomInputs/IncrementalButton";
import AddToCartButton from "../CustomInputs/AddToCartButton";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Slider from "react-slick";
import Currency from "../Currency";

export default function ProductCard(props) {
  const { t } = useTranslation();

  const quantityRef = useRef();
  const addToCartRef = useRef();

  const productPrice = 400;
  const [price, setPrice] = useState(productPrice);

  const cartProduct = useSelector((state) => state.cart.products).find(
    (e) => e.id === 1
  );

  function BuyrunSlickPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={"buyrun-" + className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function BuyrunSlickNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={"buyrun-" + className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    customPaging: function (i) {
      return <img className="img-fluid" src="/img/buyrunAmblem100x100.png" />;
    },
    className: "buyrun-slider",
    lazyLoad: true,
    dots: true,
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,
    nextArrow: <BuyrunSlickPrevArrow />,
    prevArrow: <BuyrunSlickNextArrow />,
  };

  return (
    <div className="container">
      <div className="product row">
        <div className="col-lg-6">
          <div className="product-image-wrapper">
            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <img src="/img/buyrunAmblem100x100.png" />
                </div>
                <div>
                  <img src="/img/buyrunAmblem100x100.png" />
                </div>
                <div>
                  <img src="/img/buyrunAmblem100x100.png" />
                </div>
                <div>
                  <img src="/img/buyrunAmblem100x100.png" />
                </div>
                <div>
                  <img src="/img/buyrunAmblem100x100.png" />
                </div>
                <div>
                  <img src="/img/buyrunAmblem100x100.png" />
                </div>
                <div>
                  <img src="/img/buyrunAmblem100x100.png" />
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <h1 className="product-title mb-4">
            Mazot pompası (Yüksek basınç pompası) 1.6 Tdi China Motor Parça No:
            5641165516516an, 424541665446aj, 456456456546tt, 56445456546546h
            (polo 2015 - 2020)
          </h1>

          <div className="row product-pricing mb-4">
            <div className="col-lg-4">
              <div className="product-meta">
                <div className="d-flex flex-column product-price-wrapper">
                  <span className="product-price-value p-0 mb-1">
                    <Currency class="w-100" value={productPrice} />
                  </span>
                  {quantityRef.current?.value > 1 && (
                    <small>
                      {productPrice.toFixed(2)} * {quantityRef.current.value} = <Currency value={price} />
                    </small>
                  )}
                  <span className="product-price-info">
                    Fiyatlara <b>KDV</b> dahildir.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-1"></div>

            <div className="col-lg-7">
              {/* <div className="d-flex flex-column product-invoice">
                <span className="product-invoice-title mb-2">
                  <b>Kurumsal Fatura</b>
                </span>
                <span className="product-invoice-text">
                  <i className="buyrun-icon buyrun-icon-check"></i>
                  Kurumsal Faturaya Uygun
                  <a className="product-invoice-link" href="#">
                    {">"} İncele
                  </a>
                </span>
                  </div> */}
              <div className="d-flex h-100 align-items-center justify-content-between">
                <span className=""><img src="/assets/img/American Express.svg" width={48} /></span>
                <span className=""><img src="/assets/img/Diners Club.svg" width={48} /></span>
                <span className=""><img src="/assets/img/Discover.svg" width={48} /></span>
                <span className=""><img src="/assets/img/JCB.png" width={48} /></span>
                <span className=""><img src="/assets/img/Mastercard.svg" width={48} /></span>
                <span className=""><img src="/assets/img/Visa.svg" width={48} /></span>
              </div>
            </div>
          </div>
          {/* product pricing end */}

          <div className="row product-action mb-4">
            <div className="col-lg-4">
              <IncrementalButton
                productId={1}
                ref={quantityRef}
                min={1}
                defaultValue={cartProduct ? cartProduct.quantity : 1}
                inCart={cartProduct !== undefined}
                onChange={(e) => {
                  setPrice(productPrice * e.value);
                }}
              />
            </div>
            {/* <div className="col-lg-3">liste</div> */}

            <div className="col-lg-1"></div>

            <div className="col-lg-7">
              <AddToCartButton
                ref={addToCartRef}
                inCart={cartProduct !== undefined}
                setData={() => ({
                  id: 1,
                  seller: {
                    title: "buy.run",
                  },
                  deliveryDate: "10 - 11 Mayıs",
                  img: "/img/buyrunAmblem100x100.png",
                  title:
                    "Mazot pompası (Yüksek basınç pompası) 1.6 Tdi China Motor Parça No: 5641165516516an, 424541665446aj, 456456456546tt, 56445456546546h (polo 2015 - 2020)",
                  quantity: quantityRef.current.value,
                  price: 400,
                })}
                onDone={(inCart) => {
                  if (inCart === false) {
                    quantityRef.current.defaultValue = 1;
                  }
                }}
                className="btn btn-primary w-100"
              />
            </div>
          </div>
          {/* product action end */}

          <div className="row product-info mb-4">
            <div className="col-lg-4 mb-4">
              <div>{t("product.delivery.estimated")}</div>
              <div>
                <b>10 - 15 Eylül</b>
              </div>
            </div>

            <div className="col-lg-1"></div>

            <div className="col-lg-7 mb-4">
              <div>{t("product.delivery.location")}</div>
              <div>
                <b>Üçevler - BURSA 16110</b>
                <Link className="ms-1">{t("button.update")}</Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div>{t("product.seller")}</div>
              <div>
                <b>BuyRun - buy.run</b>
              </div>
            </div>

            <div className="col-lg-1"></div>

            <div className="col-lg-7">
              <div>{t("product.sender")}</div>
              <div>
                <b>BuyRun - buy.run</b>
              </div>
            </div>
          </div>
          {/* product info end */}

          <div className="row product-about">
            <h2 className="product-about-title mb-4">{t("product.about")}</h2>
            <div className="col-12 mb-4">
              <i class="buyrun-icon buyrun-icon-check"></i> Garantili, aynı gün
              kargo
            </div>
            <div className="col-12 mb-4">
              <i class="buyrun-icon buyrun-icon-check"></i> Volkswagen polo
              motor yakıt pompası
            </div>
            <div className="col-12 mb-4">
              <i class="buyrun-icon buyrun-icon-check"></i> Volkswagen, Audi,
              Skoda, Seat marka araçların tüm modelleri için toptan & perakende
              yedek parça satışı. Doğru parça, doğru fiyat. Türkiye'de tek,
              Dünya'da tek!
            </div>
          </div>
          {/* product about end */}
        </div>
        {/* content are (right side) end */}
      </div>
      {/* product # end */}

      <div className="product-tabs mt-5 mb-5">
        <Tabs
          defaultActiveKey="productTab-description"
          className="product-tabs-nav p-0 m-0"
        >
          <Tab
            eventKey="productTab-description"
            title={t("product.tab.description.default")}
          >
            <h3 className="d-flex align-items-center">
              <i className="buyrun-icon buyrun-icon-m buyrun-icon-productDescription"></i>
              Mazot pompası (Yüksek basınç pompası) 1.6 Tdi China Motor Parça
              No: 5641165516516an, 424541665446aj, 456456456546tt,
              56445456546546h (polo 2015 - 2020)
            </h3>
            <table className="table table-striped">
              <tr>
                <td>Marka: Lorem Ipsum</td>
              </tr>
              <tr>
                <td>Model: Lorem Ipsum</td>
              </tr>
              <tr>
                <td>Üretim Yılı: Lorem Ipsum</td>
              </tr>
              <tr>
                <td>Seri No: Lorem Ipsum</td>
              </tr>
              <tr>
                <td>Ürün Hammaddesi: Lorem Ipsum</td>
              </tr>
              <tr>
                <td>GTIP (Gümrük kodu): Lorem Ipsum</td>
              </tr>
              <tr>
                <td>
                  Ürün Durumu: 1-GENUINE - Orjinal / 2-OVERHAULED - (Yenilendi)
                </td>
              </tr>
            </table>

            <h4 className="d-flex align-items-center mt-5">
              <i className="buyrun-icon buyrun-icon-m buyrun-icon-productInfo"></i>
              {t("product.tab.description.additionalInfo")}
            </h4>
            <p>
              {t("product.tab.description.releasedDate")}: <b>23 Temmuz 2023</b>
            </p>

            <h4 className="d-flex align-items-center mt-5">
              <i className="buyrun-icon buyrun-icon-m buyrun-icon-productSupport"></i>{" "}
              {t("product.tab.description.warrantySupport")}
            </h4>
            <p>
              Buyrun İade Politikası: <b>Buy.Run 30 Gün İade garantisi</b>,
              Satın almış olduğunuz birçok ürün, ürünü size teslim edilmesini
              takip eden 30 gün içersiinde iade edebilirsiniz.{" "}
              <b>Buy.Run 30 Gün İade garantisi</b>, yasal cayma hakkınızı hiçbir
              şekilde etkilemez. İstisnalar ve koşullar hakkında daha fazla
              bilgiyi <Link>buradan</Link> alabilirsiniz.
            </p>

            <h4 className="d-flex align-items-center mt-5">
              <i className="buyrun-icon buyrun-icon-m buyrun-icon-productFeedback"></i>{" "}
              {t("product.tab.description.feedback")}
            </h4>
            <p>
              <Link>Bize daha düşük fiyat göstermek ister misiniz?</Link>
            </p>
          </Tab>
          <Tab
            eventKey="productTab-campaigns"
            title={t("product.tab.campaigns")}
          >
            Kampanyalar hazırlanıyor
          </Tab>
          <Tab eventKey="productTab-reviews" title={t("product.tab.reviews")}>
            Değerlendirmeler hazırlanıyor
          </Tab>
          <Tab eventKey="productTab-qa" title={t("product.tab.qa")}>
            Soru ve Cevap hazırlanıyor
          </Tab>
          <Tab
            eventKey="productTab-policy"
            title={t("product.tab.cancellationRefundConoditions")}
          >
            İptal ve iade Koşulları hazırlanıyor
          </Tab>
        </Tabs>
      </div>
      {/* product # end */}
    </div>
  );
}
