// paymentMethods.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentMethods: [],
};

const paymentMethods = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    add: (state, action) => {
      for (const i in state.paymentMethods) {
        if (action.payload.isDefault !== false) {
          state.paymentMethods[i].isDefault = false;
        }
      }

      if (state.paymentMethods.length === 0 && action.payload.isDefault === false){
        action.payload.isDefault = true;
      }

      state.paymentMethods.push(action.payload);
    },
    remove: (state, action) => {
      const productIndex = state.paymentMethods.findIndex(
        (e) => e.id === action.payload.id
      );
      if (productIndex !== -1) {
        state.paymentMethods.splice(productIndex, 1);
      }
    },
    setDefault: (state, payload) => {
      for (const i in state.paymentMethods) {
        state.paymentMethods[i].isDefault = false;
      }

      const paymentMethodIndex = state.paymentMethods.findIndex((e) => e.id === payload.id);
      state.paymentMethods[paymentMethodIndex].isDefault = true;
    },
  },
});

export const { add, remove, setDefault } = paymentMethods.actions;

export default paymentMethods.reducer;
