// src/pages/NotFound.js

import React from "react";
import { Link } from "react-router-dom";
import "./notFound.css"; // Create a CSS file for styling
import { useTranslation } from "react-i18next";
import { remoteFile } from "../../utils/funcs";
import SeoLayer from "../SeoLayer/SeoLayer";


function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="not-found-container">
      <SeoLayer
        title={t("seo_layer.not_found.title")}
        description={t("seo_layer.not_found.description")}
        endpoint="not-found"
      />
      <div className="not-found-content">
        <img src={remoteFile("/statics/assets/img/buyrunLogo.svg")} alt="" />
        <h1 className="not-found-title">404</h1>
        <p className="not-found-text">{t("not_found.text")}</p>
        <Link to="/" className="not-found-button">
          {t("not_found.button_text")}
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
