//import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import styles from "./login.module.css";

import { useDispatch, useSelector } from "react-redux";
import {
  signInStart,
  signInSuccess,
  signInFailure,
} from "../../context/feature/User/userSlice";

import OAuth from "../OAuth/OAuth";

import API from "../../utils/api";
import LanguageSwitcher from "../LanguageSwitcher";
import { LoginAgreement, remoteFile } from "../../utils/funcs";
import SeoLayer from "../SeoLayer/SeoLayer";

const LoginPage = () => {
  //Kullanıcı bilgilerini tutuyoruz
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const clickHandleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  //Form Verilerini useState ile tutuyoruz
  const [formData, setFormData] = useState({ password: "", email: "" });
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { loading } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [err, setError] = useState("");

  /*   const DISABLE_DURATION = 60 * 1000;
  useDidMountEffect(() => {
    const disabledTime = localStorage.getItem("loginDisabledTime");
    if (disabledTime) {
      const timeLeft =
        new Date(disabledTime).getTime() +
        DISABLE_DURATION -
        new Date().getTime();
      if (timeLeft > 0) {
        setIsDisabled(true);
        setTimeout(() => setIsDisabled(false), timeLeft);
      }
    }
  }, [DISABLE_DURATION]); */

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // Email için regex tanımlandı -- Standart Kurallar
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  // Password için regex tanımlandı --
  //en az bir sayı --- en az bir büyük harf -- en az bir küçük harf -- en az 6 karakter
  const passwordRegex = /^.{6,}$/;

  const validatePassword = (password) => {
    return true; //passwordRegex.test(password);
  };
  //Form verilerini güncelliyoruz
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleErrorResponse = (error) => {
    if (error.response && error.response.data) {
      switch (error.response.status) {
        case 400:
          setError("İstek hatası. Lütfen girdilerinizi kontrol edin.");
          break;
        case 401:
          // 401 durumu için spesifik bir hata mesajı
          setError("E-posta veya şifre yanlış. Lütfen tekrar deneyin.");
          break;
        case 500:
          setError("Sunucu hatası. Lütfen daha sonra tekrar deneyin.");
          break;
        default:
          setError("Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin.");
      }
    } else {
      setError(
        "Bir hata oluştu. Lütfen bağlantınızı kontrol edin ve tekrar deneyin."
      );
    }
  };

  //Form'un submit edildigini belirliyoruz
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Her denemeden önce sıfırla;
    setInvalidEmail(false);
    setInvalidPassword(false);
    setIsDisabled(true);

    if (!validateEmail(formData.email)) {
      console.warn("Geçersiz email adresi");
      setInvalidEmail(true);
      setIsDisabled(false);
      return;
    }

    if (!validatePassword(formData.password)) {
      console.warn("Geçersiz şifre");
      setInvalidPassword(true);
      setIsDisabled(false);
      return;
    }

    try {
      dispatch(signInStart());
      const response = await API.post("/users/login", formData);
      console.log("cookie", response.cookie);
      dispatch(signInSuccess(response?.data?.result || response?.data));
      console.log("Yanıt:", response);

      /*   if (document.cookie.split(';').some((item) => item.trim().startsWith('access_token='))) {
          console.log('Auth token cookie mevcut');
        } else {
          console.log('Auth token cookie mevcut değil');
        }
   */

      //localStorage.setItem("loginDisabledTime", new Date().toISOString());
      //setTimeout(() => setIsDisabled(false), 8000);
      //setIsDisabled(false);
      navigate("/supplier");
    } catch (error) {
      //setIsDisabled(true);
      //setInvalidEmail(true);
      setInvalidPassword(true);
      handleErrorResponse(error);
      dispatch(signInFailure(error));
      /* console.warn(error); */
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className={styles.main}>
      <SeoLayer
        title={t("seo_layer.sign_in.title")}
        description={t("seo_layer.sign_in.description")}
        endpoint="auth/sign-in"
      />
      <svg width="0" height="0">
        <defs>
          <clipPath id="clip-path-left" clipPathUnits="objectBoundingBox">
            <polygon points="0 0, 1 0.38, 1 0.84, 0.65 1, 0 1" />
          </clipPath>
          <clipPath id="clip-path-right" clipPathUnits="objectBoundingBox">
            <polygon points="0 0.39, 1 0, 1 1, 0.32 1, 0 0.85" />
          </clipPath>
        </defs>
      </svg>

      <div className={styles.customPartLeft1a}></div>
      <div className={styles.customPartLeft1b}>
        <div className={styles.customPartLeft1bIn}></div>
      </div>

      <div className={styles.customPartRight1a}></div>
      <div className={styles.customPartRight1b}>
        <div className={styles.customPartRight1bIn}></div>
      </div>

      {/*  <h3 style={{ marginTop: "20px", fontWeight: "bold" }}>
              <span style={{ color: "rgb(234, 88, 12)" }}>BUY</span>
              <span style={{ color: "#0d6efd" }}>.RUN</span>
            </h3> */}

      <div className={styles.card}>
        <div className={styles.cardInner}>
          <div className="d-flex flex-column justify-content-center align-items-center mb-4">
            <img
              src={remoteFile("/statics/assets/img/buyrunLogo.svg")}
              alt="Logo"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                zIndex: "10",
              }}
            />
            <div class="header-logoText" title="buyrun, buy.run">
              <span class="header-logoText-orange">BUY</span>
              <span class="header-logoText-blue">.RUN</span>
            </div>
          </div>
          <div className={styles.languageOption}>
            <LanguageSwitcher />
          </div>

          {loading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">⌛</span>
              </div>
            </div>
          )}

          <form id="form" onSubmit={handleSubmit}>
            <input
              className={"form-control " + styles["form-control"]}
              type="email"
              name="email"
              placeholder={t("email_placeholder")}
              value={formData.email}
              onChange={handleInputChange}
            />
            {invalidEmail && (
              <div
                className={`${styles.invalidFeedback} ${styles.invalidFeedbackActive}`}
              >
                <p>{t("invalid_email")}</p>
              </div>
            )}

            <div className="input-group mt-3">
              <input
                className={"form-control " + styles["form-control"]}
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={t("password_placeholder")}
                value={formData.password}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="btn btn-dark"
                onClick={togglePasswordVisibility}
                style={{ border: "none" }}
              >
                <i
                  className={`bi ${
                    !showPassword ? "bi-eye-slash-fill" : "bi-eye-fill"
                  }`}
                ></i>
              </button>
            </div>

            {invalidPassword && (
              <div
                className={`${styles.invalidFeedback} ${styles.invalidFeedbackActive}`}
              >
                <p>{t("invalid_password") || t("invalid_email")}</p>
              </div>
            )}

            <button
              className={`${styles.btnRegister} ${styles.button} btn-register`}
              type="submit"
              disabled={isDisabled}
            >
              {t("sign_in")}
              <i class="fa-duotone fa-arrow-right-to-arc ms-1"></i>
            </button>
            {isDisabled && (
              <div>
                <p
                  style={{
                    color: "rgb(21, 124, 253)",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  İşleminiz yapılıyor...
                </p>
              </div>
            )}
          </form>

          <div className={styles.socialButtons}>
            <OAuth />
            {err && (
              <p>
                <span
                  style={{
                    color: "rgb(255, 80, 0)",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {err}
                </span>
              </p>
            )}

            <LoginAgreement />

            <div className="d-flex border-top justify-content-between mt-3 pt-3">
              <Link className={styles.link} to="/auth/sign-up">
                <span style={{ color: "rgb(21, 124, 253)" }}>
                  <i class="fa-duotone fa-user-plus me-1"></i>
                  {t("register_button")}
                </span>
              </Link>
              <Link className={styles.link} to="/auth/forgot-password">
                <span style={{ color: "rgb(234, 88, 12)" }}>
                  <i class="fa-duotone fa-lock me-1"></i>
                  {t("forgot_password")}
                </span>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default LoginPage;
