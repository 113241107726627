import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./cart.css";

import useDidMountEffect from "../../utils/useDidMountEffect";

import CartProduct from "../CartProduct";
import AsyncRadioList from "../AsyncRadioList";
import CartOrderSummary from "./CartOrderSummary";
import AddressModal from "../Address/AddressModal";
import CreditCardModal from "../CreditCardModal/CreditCardModal";

export default function Cart(props) {
  const { t } = useTranslation();

  const cartProducts = useSelector((state) => state.cart.products);
  const addressList = useSelector((state) => state.address.address);
  const paymentMethodsList = useSelector(
    (state) => state.paymentMethods.paymentMethods
  );

  const deliveryAddressRef = useRef();
  const invoiceAddressRef = useRef();
  const paymentMethodRef = useRef();

  function getAddressName(e) {
    if (e.id === undefined) return false;
    return `${e.neighborhood}, ${e.address}, ${e.zip} ${e.district}/${e.city}/${e.country}`;
  }

  function getPaymentMethodName(e) {
    if (e.id === undefined) return false;
    const cartArr = e.cardNumber.split(" ");
    let cardNumber = [];
    for (const i in cartArr) {
      if (i == 0 || i == cartArr.length - 1) {
        cardNumber.push(cartArr[i]);
      } else {
        const numberPlacer = "*".repeat(cartArr[i].length);
        cardNumber.push(numberPlacer);
      }
    }
    return (
      <div className="py-2">
        <div className="mb-2">
          <b>{e.cardType}</b>
        </div>
        <div className="d-flex">
          <span className="d-inline-flex align-items-center">
            {e.cardType && (
              <img
                className="me-2"
                src={"/assets/img/" + e.cardType + ".svg"}
                width={48}
              />
            )}
            {cardNumber.join("-")} ile biten kartınız
          </span>
          <span className="ms-5">{e.cardHolder}</span>
          <span className="ms-5">{e.cardMonth + "/" + e.cardYear}</span>
        </div>
      </div>
    );
  }

  function listCartProducts() {
    const products = cartProducts.map((data) => {
      return <CartProduct inCart={true} data={data} />;
    });
    return products.length > 0 ? (
      products
    ) : (
      <p className="alert alert-secondary">{t("cart.empty")}</p>
    );
  }

  useDidMountEffect(() => {
    const orderSummaryContainerEl = document.querySelector(
      ".order-summaryContainer"
    );
    const orderSummarySideEl = document.querySelector("#orderSummarySide");
    const orderSummarySideElBounding =
      orderSummarySideEl.getBoundingClientRect();

    const sectionTitlesEls = document.querySelectorAll(".cart-section-title");
    const sectionTitlesHandler = [];

    for (let sectionTitle of sectionTitlesEls) {
      sectionTitlesHandler.push({
        element: sectionTitle,
        bounding: sectionTitle.getBoundingClientRect(),
      });
    }

    const scrollEvent = (e) => {
      const windowScrollY = window.scrollY;
      orderSummaryContainerEl.classList.toggle(
        "fixed-element",
        orderSummarySideElBounding.top < windowScrollY
      );
      orderSummaryContainerEl.style.width =
        orderSummarySideElBounding.width + "px";

      for (let key in sectionTitlesHandler) {
        const nextItem = sectionTitlesHandler[parseInt(key) + 1];
        const releaseCondition =
          nextItem !== undefined
            ? windowScrollY - sectionTitlesHandler[key].bounding.top <
              nextItem.bounding.top
            : true;
        sectionTitlesHandler[key].element.classList.toggle(
          "fixed-element",
          sectionTitlesHandler[key].bounding.top < windowScrollY &&
            releaseCondition
        );
      }
    };

    const allowedEvents = ["scroll", "resize"];

    for (let eventName of allowedEvents) {
      window.addEventListener(eventName, scrollEvent);
    }
    return () => {
      for (let eventName of allowedEvents) {
        window.addEventListener(eventName, scrollEvent);
      }
    };
  }, []);

  return (
    <div className="container">
      <div className="cart-section cart-info row">
        <h1 className="cart-section-title cart-section-blue mb-5">
          <div className="cart-section-titleWrapper">
            <span className="cart-section-titleInner cart-section-titleLeft">
              <i class="fa-solid fa-route me-2"></i> Teslimat
            </span>
            <span className="cart-section-titleInner cart-section-titleRight">
              Ödeme <b>1</b>
            </span>
          </div>
        </h1>

        <div className="col-lg-8">
          <AsyncRadioList
            ref={deliveryAddressRef}
            titleIcon={<i className="fa-solid fa-home me-2 text-primary"></i>}
            title={t("cart.section.delivery.addressTitle")}
            name="cartDeliveryAddress"
            data={addressList}
            itemLabel={getAddressName}
            checkedId={
              addressList.length > 0 && addressList.find((e) => e.isDefault).id
            }
          >
            <AddressModal />
          </AsyncRadioList>

          <AsyncRadioList
            ref={invoiceAddressRef}
            titleIcon={<i className="fa-solid fa-building me-2 text-primary"></i>}
            title={t("cart.section.invoice.addressTitle")}
            name="cartInvoiceAddress"
            data={addressList}
            itemLabel={getAddressName}
            checkedId={
              addressList.length > 0 &&
              addressList.find((e) => e.isDefaultInvoice).id
            }
          >
            <AddressModal />
          </AsyncRadioList>

          <AsyncRadioList
            ref={paymentMethodRef}
            titleIcon={<i className="fa-solid fa-wallet me-2 text-primary"></i>}
            title={t("cart.section.payment.paymentMethodText")}
            name="cartPaymentMethod"
            data={paymentMethodsList}
            itemLabel={getPaymentMethodName}
            checkedId={
              paymentMethodsList.length > 0 &&
              paymentMethodsList.find((e) => e.isDefault).id
            }
          >
            <CreditCardModal />
          </AsyncRadioList>
        </div>

        <div id="orderSummarySide" className="col-lg-4">
          <CartOrderSummary />
        </div>
      </div>
      {/* cart-section # end */}

      <div className="cart-section cart-products row mt-4">
        <h1 className="cart-section-title cart-section-orange mb-5">
          <div className="cart-section-titleWrapper">
            <span className="cart-section-titleInner cart-section-titleLeft">
              {/* t("cart.title.list", { list_no: 1 }) */}
              <i class="fa-solid fa-shopping-bag me-2"></i> Sepet
            </span>
            <span className="cart-section-titleInner cart-section-titleRight">
              Ürünler <b>{cartProducts.length}</b>
            </span>
          </div>
        </h1>

        <div className="cart-sectionWrapper mb-5">
          <h2 className="cart-section-titleSub">
            <i class="fa-solid fa-box-open me-2 text-primary"></i>
            {t("cart.section.products.reviewTitle")}
          </h2>

          <div className="col-lg-8">{listCartProducts()}</div>
        </div>
        {/* product list # end */}
      </div>
      {/* cart-section # end */}
    </div>
  );
}
