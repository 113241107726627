// addressSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: [],
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    add: (state, action) => {
      for (const i in state.address) {
        if (action.payload.isDefault !== false) {
          state.address[i].isDefault = false;
        }
        if (action.payload.isDefaultInvoice !== false) {
          state.address[i].isDefaultInvoice = false;
        }
      }

      if (state.address.length === 0 && action.payload.isDefault === false){
        action.payload.isDefault = true;
      }

      if (state.address.length === 0 && action.payload.isDefaultInvoice === false){
        action.payload.isDefaultInvoice = true;
      }

      state.address.push(action.payload);
    },
    remove: (state, action) => {
      const productIndex = state.address.findIndex(
        (e) => e.id === action.payload.id
      );
      if (productIndex !== -1) {
        state.address.splice(productIndex, 1);
      }
    },
    setDefault: (state, payload) => {
      for (const i in state.address) {
        state.address[i].isDefault = false;
        state.address[i].isDefaultInvoice = false;
      }

      const addressIndex = state.address.findIndex((e) => e.id === payload.id);

      switch (payload.type) {
        case "invoice":
          state.address[addressIndex].isDefaultInvoice = true;
          break;
        default:
          state.address[addressIndex].isDefault = true;
          break;
      }
    },
  },
});

export const { add, remove, setDefault } = addressSlice.actions;

export default addressSlice.reducer;
