import axios from "axios";

const API = axios.create({
  baseURL: 'https://buy.run/api/v1',
  timeout: 30000,
  headers: {
    'Buyrun-Request-Origin': 'buyrun-frontend',
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

export default API;