import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

export default function PrivateRoute() {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (!currentUser) {
      console.error("%cPrivateRoute:Oturum açmadınız", "color:red;font-size:14px;");
      navigate("/");
    }
  }, [currentUser, navigate]);
  
  return <Outlet />;
}
