// cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialSummary = {
  productsTotalCount: 0,
  productsTotal: 0,
  deliveryTotal: 0,
  withoutTaxTotal: 0,
  taxTotal: 0,
  total: 0,
};

const initialState = {
  products: [],
  summary: initialSummary
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add: (state, action) => {
      state.products.push(action.payload);
    },
    remove: (state, action) => {
      const productIndex = state.products.findIndex((e) => e.id === action.payload.id);
      if (productIndex !== -1) {
        state.products.splice(productIndex, 1);
      }
    },
    quantity: (state, action) => {
      const productIndex = state.products.findIndex((e) => e.id === action.payload.id);
      state.products[productIndex].quantity = action.payload.quantity;
    },
    calculate : (state, action) => {
      state.summary.productsTotalCount = 0;
      state.summary.productsTotal = 0;
      state.summary.deliveryTotal = 0;
      state.summary.withoutTaxTotal = 0;
      state.summary.taxTotal = 0;
      state.summary.total = 0;

      for (let product of state.products) {
        state.summary.productsTotalCount = state.products.length;
        state.summary.productsTotal += product.quantity * product.price;
        state.summary.deliveryTotal = 400.0;
        state.summary.withoutTaxTotal = state.summary.productsTotal;
        state.summary.taxTotal = state.summary.productsTotal * 0.18;
        state.summary.total =
          state.summary.productsTotal +
          state.summary.deliveryTotal +
          state.summary.taxTotal;
      }
    }
  }
});

export const { add, remove, quantity, calculate } = cartSlice.actions;

export default cartSlice.reducer;
