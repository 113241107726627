import React, { useRef, useState } from "react";
import AsyncModal from "../AsyncModal/AsyncModal";

import "./refund.css";
import useDidMountEffect from "../../utils/useDidMountEffect";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Refund(props) {
  const { t } = useTranslation();

  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);

  const handleProcess = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (formRef.current.checkValidity() === false) {
      //formRef.current.classList.add('was-validated');
      return false;
    }

    const formData = new FormData(formRef.current);

    for (let input of formData) {
      console.log("input", input);
    }

    const response = await new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log("formData", formData);
        resolve(true);
      }, 1000);
    });

    setLoading(false);
  };

  useDidMountEffect(() => {}, []);

  return (
    <SideBar>
      <form
        ref={formRef}
        id="userProfileForm"
        className="row was-validated"
        noValidate
        onSubmit={handleProcess}
      >
        <h3 className="my-4">Geri Ödeme</h3>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">Ad Soyad</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="holder[fullname]"
            placeholder="Ali Işık Gümüş"
            pattern="(.+)\s+(.+\s+)?(.+)"
            required={true}
          />
          <div class="invalid-feedback">Please type your Fullname</div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">Banka Adı</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="bank[name]"
            placeholder="Bank Adı"
            pattern="(.+)\s+(.+\s+)?(.+)"
            required={true}
          />
          <div class="invalid-feedback">Please type your Bank Name</div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">Banka Adresi</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="ban[address]"
            placeholder="Bank Adresi"
            pattern="(.+)\s+(.+\s+)?(.+)"
            required={true}
          />
          <div class="invalid-feedback">Please type your Address</div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">IBAN</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="holder[iban]"
            placeholder="Bank Adresi"
            pattern="([A-Z]{2})(.+){18}"
            required={true}
          />
          <div class="invalid-feedback">Please type your IBAN. IBAN starts with 2 letters before and it contains with numbers that is 18 characters.</div>
        </div>

        <h3 className="mt-5">Swift Bilgisi</h3>

        <div className="col-lg-12 mb-3">
          <label className="mb-1">SWIFT</label>
          <input
            className="buyrun-form-control form-control"
            type="text"
            name="swift[iban]"
            placeholder="Bank SWIFT"
            pattern="(.+)\s+(.+\s+)?(.+)"
          />
          <div class="invalid-feedback">Please type your SWIFT</div>
        </div>

        <div className="col-lg-12 mb-3">
          <label className="d-flex align-items-center form-check-label">
            <input
              className="form-check-input me-2"
              type="checkbox"
              name="swift[confirmation]"
              value="none"
            />
            <p class="p-0 m-0">
              Swift sistemi üzerinden alacağım geri ödemeler için bilgilerin doğru olduğunu teyit ediyorum. <br />
              <small class="text-secondary">Devam ederseniz <Link to="#">Buy.Run Gizlilik ve Üyelik Sözleşmesi</Link>'ni kabul etmiş olursunuz.</small>
            </p>
          </label>
        </div>

        <div className="col-lg-12">
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              <i className="fa-solid fa-check me-2"></i>Kaydet
            </button>
          </div>
        </div>
      </form>
    </SideBar>
  );
}
