/* import "../../src/index.css"; */

import PropTypes from "prop-types";
export default function AuthLayout({ children }) {
  return (
    <>
      {children}
    </>
  );
}


AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
}