import React, { useState } from "react";
import { getProductDetails } from "../../api.js";

const ProductDetailsApiTest = () => {
  const [partNumber, setPartNumber] = useState("");
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProductDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const details = await getProductDetails(partNumber);
      setProductDetails(details);
    } catch (err) {
      setError("Error fetching product details");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Product Details</h1>
      <input
        type="text"
        value={partNumber}
        onChange={(e) => setPartNumber(e.target.value)}
        placeholder="Enter part number"
      />
      <button onClick={fetchProductDetails}>Fetch Details</button>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {productDetails && (
        <div>
          <h2>{productDetails.partNumber}</h2>
          <p>{productDetails.description}</p>
        </div>
      )}
    </div>
  );
};

export default ProductDetailsApiTest;
